import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Label } from 'ng2-charts';

import { ReportService } from 'src/app/components/project/components/report/report.service';
import { DataReport } from 'src/app/models/DataReport';
import { ReportDevice } from 'src/app/models/ReportDevice';
import { ChartService } from 'src/app/shared/services/chart.service';
import { DateService } from 'src/app/shared/services/date.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.scss'],
})

export class ReportDataComponent implements OnInit {
  fetchingReport = false;
  reportDetails = null;
  reportDevice: ReportDevice;
  reportDevices: ReportDevice[] = [];
  reports: DataReport[] = [];
  deviceReady = false;

  multiBarOption = ChartService.multibarChartOptions;
  barOption = ChartService.barChartOptions;
  labels: Label[] = [];
  pmData;
  noiseData;
  uvData;
  rainData;
  luxData;
  radiationData;
  pressureData;
  tempData;
  humidityData;
  windSpeedData;

  columnDetails = [
    {
      key: 'temperature',
      value: 'Temperature(°C)',
      type: 'string',
    },
    {
      key: 'pressure',
      value: 'Pressure(hPa)',
      type: 'string',
    },
    {
      key: 'humidity',
      value: 'Humidity(%rh)',
      type: 'string',
    },
    {
      key: 'noise',
      value: 'Noise(dB(A))',
      type: 'string',
    },
    {
      key: 'radiant',
      value: 'Radiant(W/m²)',
      type: 'string',
    },
    {
      key: 'uv',
      value: 'UV (level)',
      type: 'string',
    },
    {
      key: 'rain',
      value: 'Rain(mm)',
      type: 'string',
    },
    {
      key: 'lux',
      value: 'Illumination(lx)',
      type: 'string',
    },
    {
      key: 'windSpeed',
      value: 'Wind speed(m/s)',
      type: 'string',
    },
    {
      key: 'pM1',
      value: 'PM 1(µg/m³)',
      type: 'string',
    },
    {
      key: 'pM10',
      value: 'PM 10(mg/m³)',
      type: 'string',
    },
    {
      key: 'pM2_5',
      value: 'PM 2.5(µg/m³)',
      type: 'string',
    },
    {
      key: 'time',
      value: 'Time',
      type: 'shortDate',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.fetchReportDevices(this.route.snapshot.paramMap.get('id'));
  }

  setDevice(device) {
    this.reportDevice = device;
    this.showReport();
  }

  setFilter(value) {
    this.reportDetails = value;
    this.showReport();
  }

  resetDevice() {
    this.deviceReady = false;
    this.reportDevice = null;
    this.reports = [];
  }

  showReport() {
    this.reports = [];
    const projectId = this.route.snapshot.paramMap.get('id');

    if (this.reportDevice ==  null) {
      this.toastService.showToast('info', 'Information!', 'Please select a device.');
    }

    if (this.reportDetails ==  null) {
      this.toastService.showToast('info', 'Information!', 'Please select filter from above.');
    }

    if (this.reportDevice != null && this.reportDetails != null) {
      const fromDate = new DateService().dateWithFormat(this.reportDetails.fromDate, 'dd-MM-yyyy');
      const toDate = new DateService().dateWithFormat(this.reportDetails.toDate, 'dd-MM-yyyy');
      this.fetchReport(projectId, this.reportDevice.stationId, fromDate, toDate);
    }
  }

  private fetchReport(projectId: string, lampId: string, fromDate: string, toDate: string) {
    this.fetchingReport = true;
    this.reportService.getDataReport(projectId, lampId, fromDate, toDate).subscribe(
      (reports) => {
        this.fetchingReport = false;
        this.reports = this.refactorReportsData(reports);
        this.generateGraphData(this.reports);
        this.toastService.showEmptyResponse('No data', reports.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch report.');
      },
    );
  }

  private fetchReportDevices(projectId: string) {
    this.reportService.getDataReportDevices(projectId).subscribe(
      (devices) => {
        this.reportDevices = devices;
        this.deviceReady = true;
        this.toastService.showEmptyResponse('No devices', devices.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch devices.');
      },
    );
  }

  private generateGraphData(reportData) {
    this.labels = reportData.map((report: DataReport) => report.time);

    this.tempData = [
      {data: reportData.map((report: DataReport) => report.temperature), label: 'Temperature (°C)', ...ChartService.blueChart},
    ];

    this.pressureData = [
      {data: reportData.map((report: DataReport) => report.pressure), label: 'Pressure (hPa)', ...ChartService.blueStaleChart},
    ];

    this.humidityData = [
      {data: reportData.map((report: DataReport) => report.humidity), label: 'Humidity (%rh)', ...ChartService.oliveChart},
    ];

    this.noiseData = [
      {data: reportData.map((report: DataReport) => report.noise), label: 'Noise (dB(A))', ...ChartService.greenChart},
    ];

    this.uvData = [
      {data: reportData.map((report: DataReport) => report.uv), label: 'UV (level)', ...ChartService.orangeChart},
    ];

    this.rainData = [
      {data: reportData.map((report: DataReport) => report.rain), label: 'Rain (mm)', ...ChartService.redChart},
    ];

    this.luxData = [
      {data: reportData.map((report: DataReport) => report.lux), label: 'Illumination (lx)', ...ChartService.blueStaleChart},
    ];

    this.radiationData = [
      {data: reportData.map((report: DataReport) => report.radiant), label: 'Radiation (W/m²)', ...ChartService.blueChart},
    ];

    this.windSpeedData = [
      {data: reportData.map((report: DataReport) => report.windSpeed), label: 'Wind Speed (m/s)', ...ChartService.oliveChart},
    ];

    this.pmData = [
      {data: reportData.map((report: DataReport) => report.pM1), label: 'PM 1.0 (µg/m³)', ...ChartService.blueChart},
      {data: reportData.map((report: DataReport) => report.pM10), label: 'PM 10 (mg/m³)', yAxisID: 'y-axis-0', ...ChartService.redChart},
      {data:
        reportData.map((report: DataReport) => report.pM2_5),
        label: 'PM 2.5 (µg/m³)',
        yAxisID: 'y-axis-1', ...ChartService.greenChart},
    ];
  }

  private refactorReportsData(reports: any[]): DataReport[] {
    return reports.map((report) =>  {
      report.power = report.power * 100;
      report.energy = report.energy / 1000;
      return report;
   });
  }
}
