<app-page-title icon="fas fa-exclamation" title="Terms and conditions"></app-page-title>

<div class="container">
  <div class="row error-container">
    <div class="col-md-12">
      <ul *ngFor="let term  of terms; let i=index" class="term">
        <li>
          <h3>{{i+1}}. {{term.heading}}</h3>
          <h5 class="text-justify">{{term.details}}</h5>
        </li>
      </ul>
    </div>

    <div class="col-md-12">
      <app-button-panel>
        <div buttons>
          <app-back-button></app-back-button>
        </div>
      </app-button-panel>
    </div>
  </div>
</div>
