<app-card heading="Filter reports">
  <div card-body>
    <form [formGroup]="report" (ngSubmit)="setReport(report.value)" class="form-body">
      <mat-form-field appearance="outline">
        <mat-label>Page number</mat-label>
        <input matInput placeholder="Page number" type="number" formControlName="page">
        <mat-hint>Page number</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Items per page</mat-label>
        <input matInput placeholder="Items per page" type="number" formControlName="count">
        <mat-hint>Items per page</mat-hint>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label>From</mat-label>
        <input matInput [matDatepicker]="fromDate" formControlName="fromDate">
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker #fromDate color="primary"></mat-datepicker>
        <mat-error *ngIf="hasError('fromDate', 'required')">From date is required</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label>To</mat-label>
        <input matInput [matDatepicker]="toDate" formControlName="toDate">
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate color="primary"></mat-datepicker>
        <mat-error *ngIf="hasError('toDate', 'required')">To date is required</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit" [disabled]="!report.valid">Apply</button>
    </form>
  </div>
</app-card>
