<app-project-header icon="fas fa-video" heading="CCTV" [project]="project"></app-project-header>

<div class="row">
    <div class="column" style="width: 100px;">

    </div>
    <div class="column" >
        <iframe width="900" height="700" src="http://54.79.193.241/video/video.html" frameborder="5" allowFullScreen="true"></iframe>
    </div>  
    <div class="column">
      <div class="map-container">
        <app-gmap [markers]="markers" [mainMarker]="mainMarker" [zoom]=4></app-gmap>
      </div>
    </div>
</div>