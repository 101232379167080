<app-project-header icon="fas fa-comments" heading="Notes" [project]="project"></app-project-header>
<app-option-panel heading="Options">
  <app-button-small cssClass="fas fa-home" type="primary" [routerLink]="['/project/home', project?.id]" toolTip="Home" options></app-button-small>
  <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="refreshItem()" toolTip="Refresh notes" options></app-button-small>
  <app-button-small *ngIf="notesService.showAddNote()" cssClass="fas fa-plus" type="success" (outputClick)="addNote()" toolTip="Add new note" options></app-button-small>
  <app-back-button [round]="true" options></app-back-button>
</app-option-panel>

<div class="container">
  <div class="row">
    <div *ngFor="let note of notes" class="col-xs-12">
      <app-note [note]="note" (deleteFilter)="deleteNote($event)"></app-note>
    </div>
    <app-no-result class="no-result" [list]="notes"></app-no-result>
  </div>

  <app-button-panel>
    <div buttons>
      <button *ngIf="notesService.showAddNote()" mat-raised-button color="success" (click)="addNote()" aria-label="Add new note" options>
        Add new note
      </button>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>
