import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Server } from 'src/app/models/Server';
import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})

export class ServerService {
  constructor(private httpService: HttpHelperService, private userService: UserService) {
  }

  showAddServer() {
    return this.userService.canAddServer();
  }

  showDeleteServer() {
    return this.userService.canDeleteServer();
  }

  getAllServers(): Observable<any> {
    return this.httpService.getServers()
    .pipe(
      catchError((err) => {
        LogService.handleError('getAllServers', []);
        return throwError(err);
      }),
    );
  }

  getServer(id: string): Observable<any> {
    return this.httpService.getServer(id)
    .pipe(
      catchError((err) => {
        LogService.handleError('getServer', []);
        return throwError(err);
      }),
    );
  }

  addServer(server: Server): Observable<any> {
    return this.httpService.addServer(server)
    .pipe(
      catchError((err) => {
        LogService.handleError('addServer', err);
        return throwError(err);
      }),
    );
  }

  deleteServer(id: string): Observable<any> {
    return this.httpService.deleteServer(id)
    .pipe(
      catchError((err) => {
        LogService.handleError('deleteServer', []);
        return throwError(err);
      }),
    );
  }
}
