import { Component, Inject } from '@angular/core';

import { AuthService } from 'src/app/auth.service';
import { APP_CONFIG } from 'src/app/configs/app.config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent {
  appConfig: any;

  constructor( public auth: AuthService, @Inject(APP_CONFIG) appConfig: any) {
    this.appConfig = appConfig;
  }
}
