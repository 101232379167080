<div class="temperature-container">
  <div class="temperature-heading">
    <h5>{{name}} | Updated at {{dateTime}}
      <span class="close-container">
        <div class="close" (click)="onCloseClick()">X</div>
      </span>
    </h5>
  </div>

  <div class="not-found" *ngIf="!sensor">No details found</div>

  <app-temperature-widgets *ngIf="sensor" [latestValue]="sensor"></app-temperature-widgets>
  <app-temperature-graphs *ngIf="sensorData.length > 0" [data]="sensorData"></app-temperature-graphs>
</div>
