import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';

@Component({
  selector: 'app-camera-list',
  templateUrl: './camera-list.component.html',
})

export class CameraListComponent implements OnInit {
  projectId;
  project: Project = null;
  connectedList;
  modelList;
  modelTimeStamp;
  connectedTimeStamp;

  constructor(
    private projectService: ProjectService,
    private monitoringService: MonitoringService,
    private route: ActivatedRoute,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(this.projectId);
    this.refreshItem();
  }

  refreshItem() {
    this.connectedList = null;
    this.modelList = null;
    this.refreshConnected();
    this.refreshModels();
  }

  refreshModels() {
    this.modelList = null;
    this.modelTimeStamp = new Date();
    this.fetchModels(this.projectId);
  }

  refreshConnected() {
    this.connectedList = null;
    this.connectedTimeStamp = new Date();
    this.fetchConnected(this.projectId);
  }

  private getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  private fetchConnected(projectId: string) {
    this.monitoringService.getCameraConnected(projectId).subscribe(
      (list) => {
        this.connectedList = list;
        this.toastService.showEmptyResponse('No cameras', list.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch connected cameras.');
      },
    );
  }

  private fetchModels(projectId: string) {
    this.monitoringService.getCameras(projectId).subscribe(
      (models) => {
        this.modelList = models;
        this.toastService.showEmptyResponse('No cameras', models.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch camera models.');
      },
    );
  }
}
