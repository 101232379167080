import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Note } from 'src/app/models/Note';
import { NotesService } from '../../notes.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})

export class NoteComponent {
  @Output() public deleteFilter: EventEmitter<any> = new EventEmitter();
  @Input() note: Note;

  constructor(public notesService: NotesService, private toastService: ToastService, public dialog: MatDialog) {
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '500px',
        data:
        {
          heading: 'Delete note?',
          message: 'Are you sure you want to delete?',
          showWarning: true,
        },
      });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteNote();
      }
    });
  }

  private deleteNote() {
    this.notesService.deleteNote(this.note.id).subscribe((note) => {
      this.toastService.showToast('success', 'Success!', 'Deleted note');
      this.deleteFilter.emit();
    },
    (error) => {
      this.toastService.showToast('error', 'Error!', 'Failed to delete note.');
    });
  }
}
