// eslint-disable-next-line no-shadow
export enum UserPermissions {
  readServer = 'read:server',
  createServer = 'create:server',
  deleteServer = 'delete:server',
  readNote = 'read:note',
  createNote = 'create:note',
  deleteNote = 'delete:note',
  readProject = 'read:project',
  createProject = 'create:project',
  syncProject = 'sync:project',
  readLighting = 'read:project:lighting',
  readLightingModel = 'read:project:lighting:model',
  readEnvironment = 'read:project:environment',
  readEnvironmentDataSensor = 'read:project:environment:datasensor',
  readEnvironmentSensor = 'read:project:environment:sensor',
  readVas= 'read:project:alarm',
  readClearSonics = 'read:project:clearsonics',
  readTTN = 'read:project:ttn',
  readMap = 'read:project:map',
  readAlarm = 'read:project:alarm',
  readVdot ='read:vdot',
  readGccc ='read:gccc',
  readCos ='read:cos',
  readSadfit ='read:sadfit',
  readCot = 'read:cot',
  readCom = 'read:com',
  readCol = 'read:col',
  readGdc = 'read:gdc',
  readChb = 'read:chb'
}
// read:project:vas
