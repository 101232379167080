import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-sub-section',
  templateUrl: './sub-section.component.html',
  styleUrls: ['./sub-section.component.scss'],
})

export class SubSectionComponent {
  @Output() public outputRefresh: EventEmitter<any> = new EventEmitter();

  @Input() heading: string;
  @Input() length: string;
  @Input() timestamp: Date;

  constructor(private toastService: ToastService) {
  }

  refresh() {
    this.toastService.showToast('info', 'Refresh!', `Timestamp: ${this.timestamp}`);
    this.outputRefresh.emit();
  }
}
