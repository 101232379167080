import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AlarmInfoComponent } from 'src/app/components/project/components/alarm/components/alarm-info/alarm-info.component';
import { Alarm } from 'src/app/models/Alarm';

@Component({
  selector: 'app-alarm-list',
  templateUrl: './alarm-list.component.html',
  styleUrls: ['./alarm-list.component.scss'],
})

export class AlarmListComponent implements OnInit {
  @Input() alarmList;

  columnDetails = [];

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    this.columnDetails = [
      {
        key: 'alarmLevel',
        value: 'Level',
        type: 'alarmLevel',
      },
      {
        key: 'time',
        value: 'Date and Time',
        type: 'string',
      },
      {
        key: 'site',
        value: 'Site',
        type: 'string',
      },
      {
        key: 'alarmType',
        value: 'Type',
        type: 'string',
      },
      {
        key: 'message',
        value: 'Message',
        type: 'string',
      },
    ];
  }

  onRowClicked(alarm: Alarm) {
    const dialogRef = this.dialog.open(
      AlarmInfoComponent,
      {
        width: '100%',
        height: '50rem',
        data:
        {
          alarm,
        },
      });
  }
}
