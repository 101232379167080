<header>
    <nav>
        <button mat-raised-button [matMenuTriggerFor]="menu" class="menuButton">Menu</button>

        <div class="mainBanner">
            <a href="https://tstmax.com" target="_blank"><img src="assets/images/logo.png" class="pic"  alt="profile picture"></a>
        </div>
        <div class="navWrapper">
            <div class="navContent">
                <button mat-raised-button *ngFor="let item of menuItems" routerLink="{{item.link}}">
                    <i class="{{item.icon}}"></i> {{item.name | uppercase}}
                </button>

                <button mat-raised-button [matMenuTriggerFor]="subMenu"><i class="fas fa-chevron-down"></i> Other</button>
                <mat-menu #subMenu="matMenu" yPosition="below">
                  <button mat-menu-item *ngFor="let item of subMenuItems" routerLink="{{item.link}}">
                    <i class="{{item.icon}}"></i> {{item.name | uppercase}}
                  </button>
                </mat-menu>
            </div>

            <mat-menu #menu="matMenu" class="navMenu">
                <button mat-menu-item *ngFor="let item of menuItems" routerLink="{{item.link}}">
                    <i class="{{item.icon}}"></i> {{item.name | uppercase}}
                </button>
                <button mat-menu-item *ngFor="let item of subMenuItems" routerLink="{{item.link}}">
                  <i class="{{item.icon}}"></i> {{item.name | uppercase}}
                </button>
                <button mat-menu-item (click)="auth.login()" *ngIf="!auth.loggedIn">Log In</button>
                <button mat-menu-item (click)="auth.logout()" *ngIf="auth.loggedIn">Log Out</button>
            </mat-menu>
        </div>

        <div class="userWrapper">
          <div *ngIf="auth.loggedIn"><img [src]="auth.user?.picture" class="pic"  alt="user profile picture"></div>
          <div *ngIf="auth.loggedIn" class="username">
            <span id="logged-in-username">{{auth.user?.name}}</span>
          </div>
          <button mat-raised-button (click)="auth.login()" *ngIf="!auth.loggedIn">Log In</button>
          <button mat-raised-button (click)="auth.logout()" *ngIf="auth.loggedIn" id="logout-button">Log Out</button>
        </div>
    </nav>
</header>
