import { NgModule } from '@angular/core';

import { ProjectModule } from 'src/app/components/project/project.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { ReportControlComponent } from './components/report-control/report-control.component';
import { ReportDataComponent } from './components/report-data/report-data.component';
import { ReportDeviceComponent } from './components/report-device/report-device.component';
import { ReportDevicesSelectComponent } from './components/report-devices-select/report-devices-select.component';
import { ReportLampComponent } from './components/report-lamp/report-lamp.component';
import { ReportMeterComponent } from './components/report-meter/report-meter.component';
import { ReportComponent } from './report.component';

@NgModule({
  imports: [
    SharedModule,
    ProjectModule,
  ],
  declarations: [
    ReportComponent,
    ReportControlComponent,
    ReportDeviceComponent,
    ReportLampComponent,
    ReportDevicesSelectComponent,
    ReportMeterComponent,
    ReportDataComponent,
  ],
})

export class ReportModule {
}
