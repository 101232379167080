import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/Project';
import { ProjectService } from 'src/app/components/project/project.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { IMapMarker } from 'src/app/interfaces/IMapMarker';

@Component({
  selector: 'app-vas',
  templateUrl: './vas.component.html',
  styleUrls: ['./vas.component.scss']
})
export class VasComponent implements OnInit {
  projectId;
  project: Project = null;
  showHide : boolean;
  markers: IMapMarker[] = new Array();
  mainMarker= {} as IMapMarker;
  marker2 = {} as IMapMarker;
  marker3 = {} as IMapMarker;
  marker4 = {} as IMapMarker;
  marker5 = {} as IMapMarker;
  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private toastService: ToastService) {
    }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(this.projectId);
    this.setMarkers();
    console.log(this.markers);
  }

  private getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
        if (this.project.name === 'City Of Logan - Vehicle Activated Sign' || 'Coates Hire Queensland' ) {
            this.showHide = true;
        } else {
           this.showHide = false;
        }
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  private setMarkers(){
   this.mainMarker.name ='Sign 1';
   this.mainMarker.icon = 'assets/images/marker/iSign.png';
   this.mainMarker.latitude= -27.664390;
   this.mainMarker.longitude= 153.042699;
   this.mainMarker.type='iSign';
   this.mainMarker.item=  { name : 'sign 1',latitude: -27.664390,longitude: 153.042699,type:'iSign'};
   this.markers[0] = this.mainMarker;

   this.marker2.name ='Sign 2';
   this.marker2.icon = 'assets/images/marker/iSign.png';
   this.marker2.latitude= -27.664790;
   this.marker2.longitude=153.041695;
   this.marker2.type='iSign';
   this.marker2.item = { name : 'sign 2',latitude: -27.664790,longitude: 153.041695,type:'iSign'};

   this.markers[1] = this.marker2;

   this.marker3.name ='Sign 3';
   this.marker3.icon = 'assets/images/marker/iSign.png';
   this.marker3.latitude= -27.663812;
   this.marker3.longitude=153.042863;
   this.marker3.type='iSign';
   this.marker3.item = { name : 'sign 3',latitude: -27.663812,longitude: 153.042863,type:'iSign'};

   this.markers[2] = this.marker3;

   this.marker4.name ='Sign 4';
   this.marker4.icon = 'assets/images/marker/iSign.png';
   this.marker4.latitude= -27.664541;
   this.marker4.longitude=153.039624;
   this.marker4.type='iSign';
   this.marker4.item = { name : 'sign 4',latitude: -27.664541,longitude: 153.039624,type:'iSign'};

   this.markers[3] = this.marker4;

   this.marker5.name ='Sign 5';
   this.marker5.icon = 'assets/images/marker/iSign.png';
   this.marker5.latitude= -27.663153;
   this.marker5.longitude=153.042973;
   this.marker5.type='iSign';
   this.marker5.item = { name : 'sign 5',latitude: -27.663153,longitude: 153.042973,type:'iSign'};

   this.markers[4] = this.marker5;

  }

}
