<app-project-header icon="fas fa-plug" heading="Energy" [project]="project"></app-project-header>


<div class="row">
    <div class="column" style="width: 100px;">

    </div>
    <div class="column" >
      <div [ngSwitch]="project.name">
        <div *ngSwitchCase="'VicRoads'">
        </div>
        <div *ngSwitchCase="'Anti Theft Install'">
            <iframe width="1800" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiNmFjN2VlOTktOTc2Ny00ZGYyLTkzNDgtZDU5YWY1MmI3YmFiIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe>  
        </div>
        <div *ngSwitchCase="'Vic Roads HPS Sites'">
            <iframe width="2000" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiNzk5NWRiNjctODVhNS00NTkwLTg1YTYtYzZiZjBkMmI0NjYzIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe>
        </div>
        <div *ngSwitchCase="'4'">Four</div>
        <div *ngSwitchCase="'5'">Five</div>
        <div *ngSwitchDefault>This is Default</div>
      </div>  
    </div>  
    <div class="column">
      
    </div>
</div>