import { NgModule } from '@angular/core';

import { ProjectModule } from 'src/app/components/project/project.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { AlarmComponent } from 'src/app/components/project/components/alarm/alarm.component';
import { AlarmInfoComponent } from 'src/app/components/project/components/alarm/components/alarm-info/alarm-info.component';
import { AlarmListComponent } from 'src/app/components/project/components/alarm/components/alarm-list/alarm-list.component';
import { AlarmToolsComponent } from 'src/app/components/project/components/alarm/components/alarm-tools/alarm-tools.component';
import { AlarmReportComponent } from './components/alarm-report/alarm-report.component';
import { AlarmControlComponent } from './components/alarm-control/alarm-control.component';
import { AlarmCounterComponent } from './components/alarm-counter/alarm-counter.component';
import { ImpactAlarmListComponent } from './components/impact-alarm-list/impact-alarm-list.component';
import { ImpactAlarmInfoComponent } from './components/impact-alarm-info/impact-alarm-info.component';

@NgModule({
  imports: [
    SharedModule,
    ProjectModule,
  ],
  declarations: [
    AlarmComponent,
    AlarmListComponent,
    AlarmToolsComponent,
    AlarmInfoComponent,
    AlarmReportComponent,
    AlarmControlComponent,
    AlarmCounterComponent,
    ImpactAlarmListComponent,
    ImpactAlarmInfoComponent,
  ],
})

export class AlarmModule {
}
