<div class="sensor-container">
  <div class="sensor-heading">
    <h5>{{sensor.serialNumber}} | {{sensor.deviceTypeId}}
      <span class="close-container">
        <div class="close" (click)="onCloseClick()">X</div>
      </span>
    </h5>
  </div>

  <app-parking-sensor *ngIf="sensor.sensorType === 'parking'" [sensor]="sensor" class="sensor-details"></app-parking-sensor>
  <app-manhole-sensor *ngIf="sensor.sensorType === 'manhole'" [sensor]="sensor" class="sensor-details"></app-manhole-sensor>
  <app-bin-sensor *ngIf="sensor.sensorType === 'bin'" [sensor]="sensor" class="sensor-details"></app-bin-sensor>
  <app-gps-sensor *ngIf="sensor.sensorType === 'gps'" [sensor]="sensor" class="sensor-details"></app-gps-sensor>
  <app-tilt-sensor *ngIf="sensor.sensorType === 'tilt'" [sensor]="sensor" class="sensor-details"></app-tilt-sensor>
  <app-impact-sensor *ngIf="sensor.deviceTypeId === '10'" [sensor]="sensor" class="sensor-details"></app-impact-sensor>
</div>
