import { Component, Input, OnInit } from '@angular/core';

import { TemperatureInfo } from 'src/app/models/TemperatureInfo';

@Component({
  selector: 'app-temperature-widgets',
  templateUrl: './temperature-widgets.component.html',
  styleUrls: ['./temperature-widgets.component.scss'],
})

export class TemperatureWidgetsComponent implements OnInit {
  @Input() latestValue: any;

  values: TemperatureInfo[] = [];

  constructor() {
  }

  ngOnInit() {
    this.addToView(this.latestValue.temperature, '°C', 'assets/images/sensors/temp.png', 'latest-value-temp', false);
    this.addToView(this.latestValue.humidity, '%rh', 'assets/images/sensors/humidity.png', 'latest-value-hum', true);
    this.addToView(this.latestValue.pressure, 'hPa', 'assets/images/sensors/pressure.png', 'latest-value-pressure', true);
    this.addToView(this.latestValue.rain, 'mm', 'assets/images/sensors/rain.png', 'latest-value-hum', true);
    this.addToView(this.latestValue.noise, 'dB', 'assets/images/sensors/sound.png', 'latest-value-hum', true);
    this.addToView(this.latestValue.pM2_5, 'µg/m³', 'assets/images/sensors/pollution_2.5.png', 'latest-value-pm25', true);
    this.addToView(this.latestValue.pM10, 'µg/m³', 'assets/images/sensors/pollution_10.png', 'latest-value-pm10', true);
    this.addToView(this.latestValue.pM1, 'µg/m', 'assets/images/sensors/pollution_10.png', 'latest-value-pm10', true);
    this.addToView(this.latestValue.uv, 'mJ/cm2', 'assets/images/sensors/temp.png', 'latest-value-temp', true);
    this.addToView(this.latestValue.lux, 'lx', 'assets/images/sensors/temp.png', 'latest-value-temp', true);
    this.setWind();
  }

  private setWind() {
    let show = false;
    let unit = '';
    if (this.latestValue.windSpeed > 0) {
      show = true;
      unit = 'm/s';
    }

    if (this.latestValue.windDirection) {
      show = true;
      unit += ' ' + this.latestValue.windDirection;
    }

    if (show) {
      this.values.push(new TemperatureInfo(this.latestValue.windSpeed, unit , 'assets/images/sensors/wind.png', 'latest-value-hum'));
    }
  }

  private addToView(value: number, unit: string, icon: string, cssClass: string, showPositive: boolean) {
    let addToArray = false;

    if (showPositive && value > 0) {
      addToArray = true;
    }

    if (!showPositive) {
      addToArray = true;
    }

    if (addToArray) {
    this.values.push(new TemperatureInfo(value, unit, icon, cssClass));
    }
  }
}
