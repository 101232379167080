import { Component, OnInit } from '@angular/core';

import { Server } from 'src/app/models/Server';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ServerService } from './server.service';

@Component({
  selector: 'app-server',
  templateUrl: './server.component.html',
})

export class ServerPageComponent implements OnInit {
  serverList: Server[] = null;

  constructor(public serverService: ServerService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.getServers();
  }

  getServers() {
    this.serverService.getAllServers().subscribe(
      (serverList) => {
        this.serverList = serverList;
        this.toastService.showEmptyResponse('No servers', serverList.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to get all servers.');
      },
    );
  }
}
