import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DataSensor } from 'src/app/models/DataSensor';
import { TemperatureInfoComponent } from '../temperature-info/temperature-info.component';

@Component({
  selector: 'app-data-sensor',
  templateUrl: './data-sensor.component.html',
  styleUrls: ['./data-sensor.component.scss'],
})

export class DataSensorComponent implements OnInit {
  @Input() dataSensor: DataSensor;
  alarmMessage;
  showAlarmError = false;
  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.dataSensor.alarms > 0) {
      this.showAlarmError = true;
    }

    if (this.dataSensor.alarms > 1) {
      this.alarmMessage = `Alarm: There are ${this.dataSensor.alarms} alarms`;
    }

    if (this.dataSensor.alarms < 2) {
      this.alarmMessage = `Alarm: There is ${this.dataSensor.alarms} alarm`;
    }

    if (this.dataSensor.alarms < 1) {
      this.alarmMessage = 'Alarm: There are no alarms';
    }
  }

  onSensorClicked(sensor) {
    const dialogRef = this.dialog.open(
      TemperatureInfoComponent,
      {
        width: '100rem',
        data:
        {
          sensor,
          sensorData: sensor,
          name: sensor.name,
        },
      });
  }
}
