import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataSensor } from 'src/app/models/DataSensor';

@Component({
  selector: 'app-data-sensors',
  templateUrl: './data-sensors.component.html',
  styleUrls: ['./data-sensors.component.scss'],
})

export class DataSensorsComponent {
  @Output() public outputFilter: EventEmitter<any> = new EventEmitter();

  @Input() heading: string;
  @Input() timestamp: Date;
  @Input() data: DataSensor[];

  constructor() {
  }

  refresh() {
    this.outputFilter.emit();
  }
}
