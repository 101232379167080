import { NgModule } from '@angular/core';

import { ProjectModule } from 'src/app/components/project/project.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { BinSensorComponent } from 'src/app/components/project/components/monitoring/components/bin-sensor/bin-sensor.component';
import { GpsSensorComponent } from 'src/app/components/project/components/monitoring/components/gps-sensor/gps-sensor.component';
import {
  ManholeSensorComponent,
 } from 'src/app/components/project/components/monitoring/components/manhole-sensor/manhole-sensor.component';
import {
  ParkingSensorComponent,
} from 'src/app/components/project/components/monitoring/components/parking-sensor/parking-sensor.component';
import { SensorInfoComponent } from 'src/app/components/project/components/monitoring/components/sensor-info/sensor-info.component';
import { SensorListComponent } from 'src/app/components/project/components/monitoring/components/sensor-list/sensor-list.component';
import { SensorComponent } from 'src/app/components/project/components/monitoring/components/sensor/sensor.component';
import { TiltSensorComponent } from 'src/app/components/project/components/monitoring/components/tilt-sensor/tilt-sensor.component';
import { MonitoringComponent } from 'src/app/components/project/components/monitoring/monitoring.component';
import { CameraComponent } from './components/camera/camera.component';
import { CamerasComponent } from './components/cameras/cameras.component';
import { CamerasConnectedComponent } from './components/cameras-connected/cameras-connected.component';
import { CameraConnectedComponent } from './components/camera-connected/camera-connected.component';
import { CameraListComponent } from './components/camera-list/camera-list.component';
import { ImpactSensorComponent } from 'src/app/components/project/components/monitoring/components/impact-sensor/impact-sensor.component';

@NgModule({
  imports: [
    SharedModule,
    ProjectModule,
  ],
  declarations: [
    MonitoringComponent,
    SensorListComponent,
    SensorComponent,
    SensorInfoComponent,
    ParkingSensorComponent,
    ManholeSensorComponent,
    BinSensorComponent,
    GpsSensorComponent,
    TiltSensorComponent,
    CameraComponent,
    CamerasComponent,
    CameraConnectedComponent,
    CamerasConnectedComponent,
    CameraListComponent,
    ImpactSensorComponent,
  ],
})

export class MonitoringModule {
}
