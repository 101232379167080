import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CarouselViewerComponent } from '../carousel-viewer/carousel-viewer.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})

export class CarouselComponent implements OnChanges {
  @Input() slides = [];
  slideIndex;
  slideLength;
  slideImage;

  constructor(private dialog: MatDialog) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.slideIndex = 0;
    this.slideLength = this.slides.length - 1;
    if(this.slideLength < 0) {
      this.slideLength = 0;
      this.setDefaultImage();
    }
    this.setImage(this.slideIndex);
  }

  onImageClicked() {
    if(this.slideLength !== 0) {
      const dialogRef = this.dialog.open(
        CarouselViewerComponent,
        {
          width: '100%',
          height: '85%',
          data:
          {
            slides: this.slides,
            slideIndex: this.slideIndex,
            next: this.next,
            previous: this.previous,
            dotClick: this.dotClick,
            setImage: this.setImage,
          },
        });
    }
  }

  next() {
    if (this.slideIndex === this.slideLength) {
      this.slideIndex = 0;
    } else {
      this.slideIndex++;
    }

    this.setImage(this.slideIndex);
  }

  previous() {
    if (this.slideIndex === 0) {
      this.slideIndex = this.slideLength;
    } else {
      this.slideIndex--;
    }
    this.setImage(this.slideIndex);
  }

  dotClick(index) {
    this.slideIndex = index;
    this.setImage(index);
  }

  private setDefaultImage() {
    this.slides.push({
      path: 'assets/images/full-logo.png',
      text: 'No image'
    });
  }

  private setImage(index: number) {
    this.slideImage = this.slides[index];
  }
}
