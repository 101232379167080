import { Injectable } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';

@Injectable({
  providedIn: 'root',
})

export class ToastService {
  private _toaster: Toaster;

  private toastConfig = {
    position: 'top-right',
    duration: 5000,
    preventDuplicates: true,
  };

  private toastSuccessConfig = {
    ...this.toastConfig,
    type: 'success',
  };

  private toastWarningConfig = {
    ...this.toastConfig,
    type: 'warning',
  };

  private toastInfoConfig = {
    ...this.toastConfig,
    type: 'info',
  };

  private toastErrorConfig = {
    ...this.toastConfig,
    type: 'danger',
  };

  private toastDefaultConfig = {
    ...this.toastConfig,
    type: 'primary',
  };

  constructor(toaster: Toaster) {
    this._toaster = toaster;
  }

  showToast(type: string, heading: string, message: string) {
    let config = { };
    switch (type) {
      case 'success': {
        config = { ...this.toastSuccessConfig, caption: heading };
        break;
      }
      case 'warning': {
        config = { ...this.toastWarningConfig, caption: heading };
        break;
      }
      case 'info': {
        config = { ...this.toastInfoConfig, caption: heading };
        break;
      }
      case 'error': {
        config = { ...this.toastErrorConfig, caption: heading };
        break;
      }

      default: {
        config = { ...this.toastDefaultConfig, caption: heading };
        break;
      }
    }
    this._toaster.open(message, config);
  }

  showEmptyResponse(message: string, count: number) {
    if (count === 0) {
      this.showToast('info', '0 results', message);
    }
  }
}
