import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GroupInfo } from 'src/app/models/GroupInfo';
import { Project } from 'src/app/models/Project';
import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})

export class ProjectService {
  currentProject: Project;
  project: Observable<any>;
  allProjects: Observable<any>;

  constructor(private httpService: HttpHelperService, private userService: UserService) {
  }

  showAddProject() {
    return this.userService.canAddProject();
  }

  showSyncProject() {
    return this.userService.canSyncProject();
  }

  newProject(project): Observable<any> {
    return this.httpService.addProject(project)
    .pipe(
      catchError((err) => {
        LogService.handleError('addProject', []);
        return throwError(err);
      }),
    );
  }

  getAllProjects(): Observable<any> {
    if (!this.allProjects) {
      this.allProjects = this.httpService.getProjects()
      .pipe(
        catchError((err) => {
          LogService.handleError('getAllProjects', []);
          return throwError(err);
        }),
      );
    }
    return this.allProjects;
  }

  getProject(id: string): Observable<any> {
    if (!this.project || this.currentProject.id !== id) {
      this.project = this.httpService.getProject(id)
      .pipe(
        map((data) => this.currentProject = data as Project),
        catchError((err) => {
          LogService.handleError('getProject', []);
          return throwError(err);
        }),
      );
    }
    return this.project;
  }

  syncProjects(): Observable<any> {
    this.allProjects = this.httpService.syncProjects()
    .pipe(
      map((data) => this.currentProject = data as Project),
      catchError((err) => {
        LogService.handleError('syncProjects', []);
        return throwError(err);
      }),
    );
    return this.allProjects;
  }

  syncProject(id: string): Observable<any> {
    this.project = this.httpService.syncProject(id)
    .pipe(
      map((data) => this.currentProject = data as Project),
      catchError((err) => {
        LogService.handleError('syncProject', []);
        return throwError(err);
      }),
    );
    return this.project;
  }

  getLightingSensorGraphs(projectId: string): Observable<any> {
    return this.httpService.getLightingSensorGraphs(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLightingSensorGraphs', []);
        return throwError(err);
      }),
    );
  }

  getEnergyPerMonth(projectId: string): Observable<any> {
    return this.httpService.getEnergyPerMonth(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getEnergyPerMonth', []);
        return throwError(err);
      }),
    );
  }

  getEnergyPerYear(projectId: string): Observable<any> {
    return this.httpService.getEnergyPerYear(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getEnergyPerYear', []);
        return throwError(err);
      }),
    );
  }

  getLampGroups(projectId: string): Observable<any> {
    return this.httpService.getGroupLampItems(projectId)
    .pipe(
      map((data) => data as GroupInfo[]),
      catchError((err) => {
        LogService.handleError('getLampGroups', []);
        return throwError(err);
      }),
    );
  }

  getBreakerGroups(projectId: string): Observable<any> {
    return this.httpService.getGroupBreakerItems(projectId)
    .pipe(
      map((data) => data as GroupInfo[]),
      catchError((err) => {
        LogService.handleError('getBreakerGroups', []);
        return throwError(err);
      }),
    );
  }

  getEnergyLow(projectId: string): Observable<any> {
    return this.httpService.getEnergyLow(projectId)
    .pipe(
      map((data) => data as GroupInfo[]),
      catchError((err) => {
        LogService.handleError('getEnergyLow', []);
        return throwError(err);
      }),
    );
  }

  getEnergyHigh(projectId: string): Observable<any> {
    return this.httpService.getEnergyHigh(projectId)
    .pipe(
      map((data) => data as GroupInfo[]),
      catchError((err) => {
        LogService.handleError('getEnergyHigh', []);
        return throwError(err);
      }),
    );
  }
}
