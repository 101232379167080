import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';

import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  @Output() public outputRowClicked: EventEmitter<any> = new EventEmitter();
  @Input() items;
  @Input() pageSize = 50;
  @Input() columnDetails: string[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [];
  public dataSource: MatTableDataSource<any>;
  public currentPage = 0;
  public totalSize = 0;
  public pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setInitialData();
  }

  ngOnInit(): void {
    this.setInitialData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onRowClicked(row) {
    this.outputRowClicked.emit(row);
  }

  renderSpecial(type, value, label, link) {
    let stringValue: string;

    switch (type) {
      case 'string': {
        stringValue = value;
        break;
      }
      case 'boolean': {
        stringValue = this.renderBoolean(value);
        break;
      }
      case 'shortDate': {
        stringValue = this.getShortDate(value);
        break;
      }
      case 'dateWithTime': {
        stringValue = this.getDateWithTime(value);
        break;
      }
      case 'alarmLevel': {
        stringValue = this.getLevelClass(value);
        break;
      }
      case 'button': {
        stringValue = this.renderButton(value, label, link);
        break;
      }
      default: {
        stringValue = value;
        break;
      }
    }

    return this.sanitizer.bypassSecurityTrustHtml(
      stringValue
    );
  }

  private setInitialData() {
    this.displayedColumns = this.columnDetails.map((c) => c['key']);
    this.dataSource = new MatTableDataSource<any>(this.items);
    this.totalSize = this.items.count;
  }

  private renderButton(value, label, link) {
    const fullUrl = `${link}/${value}`;
    return `<a class="button mat-raised-button mat-success" href="${fullUrl}">${label}</a>`;
  }

  private renderBoolean(value) {
    if (value === true) {
      return '<i class="fas fa-check"></i>';
    } else {
      return '<i class="fas fa-times"></i>';
    }
  }

  private getShortDate(unixDate) {
    return new DateService().shortDateFromUnixDate(unixDate);
  }

  private getDateWithTime(unixDate) {
    return new DateService().dateWithTime(unixDate);
  }

  private getLevelClass(level: string) {
    switch (level) {
      case 'Low': {
        return '<div class="alarm-indicator level-low">&nbsp;</div>';
      }
      case 'Medium': {
        return '<div class="alarm-indicator level-medium">&nbsp;</div>';
      }
      case 'High': {
        return '<div class="alarm-indicator level-high">&nbsp;</div>';
      }
      case '1': {
        return '<div class="alarm-indicator level-high">&nbsp;</div>';
      }
      case '2': {
        return '<div class="alarm-indicator level-high">&nbsp;</div>';
      }
      case '0': {
        return '<div class="alarm-indicator level-low">&nbsp;</div>';
      }
      default: {
        return '<div class="alarm-indicator level-high">&nbsp;</div>';
      }
   }
  }
}
