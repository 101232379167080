import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

import { ChartService } from 'src/app/shared/services/chart.service';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-temperature-graph',
  templateUrl: './temperature-graph.component.html',
  styleUrls: ['./temperature-graph.component.scss'],
})

export class TemperatureGraphComponent implements OnInit {
  @Input() value: any;
  @Input() heading: string;
  @Input() subheading: string;

  chart = null;

  constructor(private dateService: DateService) {
  }

  ngOnInit() {
    this.generateGraph();
  }

  generateGraph() {
    const labels: Label[] = this.value.map((v) => this.dateService.shortDateWithTime(v.time));
    const data: ChartDataSets[] = [{
      data: this.value.map((v) => Number(v.key).toFixed(2)),
      label: this.subheading,
      ...ChartService.greenLineChart,
    }];

    this.chart = {
      data,
      labels,
      ...ChartService.lineChartOptions,
    };

  }
}
