class UserMeta {
  constructor(public allProjects: boolean,
    public projects: string[]) {
  }
}

export class User {
  constructor(public email: string,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              public email_verified: boolean,
              public name: string,
              public nickname: string,
              public picture: string,
              public meta: UserMeta,
              public permissions: string[]) {
  }

  public hasPermission(permission: string) {
    return this.permissions.includes(permission);
  }

  public accessAll(): boolean {
    return this.meta.allProjects;
  }

  public projectList(): string[] {
    return this.meta.projects;
  }

  public canAccessProject(projectId: string): boolean {
    return this.accessAll() || this.projectList().includes(projectId);
  }
}
