import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';

import { ProjectAddComponent } from 'src/app/components/project/components/project-add/project-add.component';
import { ProjectInfoComponent } from 'src/app/components/project/components/project-details/components/project-info/project-info.component';
import { ProjectHeaderComponent } from 'src/app/components/project/components/project-header/project-header.component';
import { ProjectMenuComponent } from 'src/app/components/project/components/project-menu/project-menu.component';
import { ProjectGroupComponent } from './components/project-details/components/project-group/project-group.component';
import { RecentAlarmsComponent } from './components/project-details/components/recent-alarms/recent-alarms.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { ProjectMapComponent } from './components/project-map/project-map.component';
import { ProjectComponent } from './project.component';
import { CurrentStatComponent } from './components/project-details/components/current-stat/current-stat.component';
import { VasComponent } from './components/vas/vas.component';
import { LightsComponent } from './components/lights/lights.component';
import { EnergyComponent } from './components/energy/energy.component';
import { TTMSComponent } from './components/ttms/ttms.component';
import { IMonitorComponent } from './components/imonitor/imonitor.component';
import { CCTVComponent } from './components/cctv/cctv.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ProjectComponent,
    ProjectDetailsComponent,
    ProjectInfoComponent,
    ProjectHeaderComponent,
    ProjectMenuComponent,
    ProjectAddComponent,
    RecentAlarmsComponent,
    ProjectMapComponent,
    ProjectGroupComponent,
    CurrentStatComponent,
    VasComponent,
    LightsComponent,
    EnergyComponent,
    TTMSComponent,
    IMonitorComponent,
    CCTVComponent,
  ],
  exports: [
    ProjectHeaderComponent,
  ],
})

export class ProjectModule {
}
