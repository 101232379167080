import { Component, Input, OnInit } from '@angular/core';

import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-parking-sensor',
  templateUrl: './parking-sensor.component.html',
  styleUrls: ['./parking-sensor.component.scss'],
})

export class ParkingSensorComponent implements OnInit {
  @Input() sensor: any;
  image = '';

  dataSource = [];
  displayedColumns: string[] = ['time', 'available', 'occupied', 'expired'];
  constructor(private monitoringService: MonitoringService, private dateService: DateService) {
  }

  ngOnInit() {
    this.setImage(this.sensor);
    this.setData(this.sensor.details.data);
  }

  setImage(sensor) {
    this.image = this.monitoringService.getImageType(sensor);
  }

  setData(sensorData) {
    const expiryTimePeriod = 2 * 60 * 60 * 1000 - 3000; // 2 hours in ms minus 3 seconds

    sensorData.forEach((parkingData, index) => {
      const time = new Date(parkingData.time).setMilliseconds(0).valueOf();
      let occupied = parkingData.payload.occupied;
      const available = !occupied;
      let expired = false;

      if (occupied && index < sensorData.length - 1) {
        for (
          let traversalIndex = index + 1;
          traversalIndex < sensorData.length;
          ++traversalIndex
        ) {
          if (sensorData[traversalIndex].payload.occupied) {
            const traversalIndexTime = new Date(sensorData[traversalIndex].time).setMilliseconds(0).valueOf();
            if (time - traversalIndexTime >= expiryTimePeriod) {
              occupied = false;
              expired = true;
              break;
            }
          }
        }
      }

      let status = '';
      if (available) {
        status = 'available';
      }
      if (occupied) {
        status = 'occupied';
      }
      if (expired) {
        status = 'expired';
      }

      if (this.dataSource.length > 0 && this.dataSource[this.dataSource.length - 1].status === status) {
        this.dataSource.pop();
      }

      this.dataSource.push({
        time: this.dateService.fullDateWithTime(parkingData.time),
        status,
        available,
        occupied,
        expired,
      });
    });
  }
}
