
<app-loading [check]="items" color="accent"></app-loading>

<mat-paginator #paginator [pageSize]="pageSize" [showFirstLastButtons]="true" [length]="totalSize" [pageSizeOptions]="[5, 10, 25, 50, 100]">
</mat-paginator>

<mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  <ng-container [matColumnDef]="column['key']" *ngFor="let column of columnDetails">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column['value']}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div [innerHTML]="renderSpecial(column['type'], element[column['key']], column['label'], column['link'])"></div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" ></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></mat-row>
</mat-table>
