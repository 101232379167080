import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.html',
  styleUrls: ['confirmation-dialog.scss'],
})
export class ConfirmationDialogComponent {
  showWarning = false;
  dialog: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dialog = dialogRef;
      if (data.showWarning) {
        this.showWarning = true;
      }
    }

  onNoClick(): void {
    this.dialog.close(false);
  }

  onYesClick(): void {
    this.dialog.close(true);
  }
}
