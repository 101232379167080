import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChartsModule } from 'ng2-charts';
import { BeautifulChartsModule } from 'ngx-beautiful-charts';
import { MaterialModule } from './modules/material.module';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';

import { BackButtonComponent } from './components/back-button/back-button';
import { BarChartComponent } from './components/bar-chart/bat-chart.component';
import { ButtonPanelComponent } from './components/button-panel/button-panel.component';
import { CardComponent } from './components/card/card.component';
import { CarouselViewerComponent } from './components/carousel-viewer/carousel-viewer.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CookieComponent } from './components/cookie/cookie.component';
import { CountComponent } from './components/count/count.component';
import { ErrorPanelComponent } from './components/error-panel/error-panel.component';
import { ExpandComponent } from './components/expand/expand.component';
import { FooterComponent } from './components/footer/footer.component';
import { GMapInfoComponent } from './components/gmap-info/gmap-info.component';
import { GMapComponent } from './components/gmap/gmap.component';
import { HeaderComponent } from './components/header/header.component';
import { KeyValueComponent } from './components/key-value/key-value.component';
import { LinkComponent } from './components/link/link.component';
import { LoadingComponent } from './components/loading/loading.component';
import { OnOffComponent } from './components/on-off/on-off.component';
import { OptionPanelComponent } from './components/option-panel/option-panel.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { SignalComponent } from './components/signal/signal.component';
import { StatusComponent } from './components/status/status.component';
import { SubSectionComponent } from './components/sub-section/sub-section.component';
import { TableComponent } from './components/table/table.component';
import { ToggleTextComponent } from './components/toggle-text/toggle-text.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ButtonSmallComponent } from './components/button-small/button-small.component';
import { ListViewerComponent } from './components/list-viewer/list-viewer.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { SearchComponent } from './components/search/search.component';
import { NoResultComponent } from './components/no-result/no-result.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    ChartsModule,
    VgCoreModule,
    VgBufferingModule,
    VgControlsModule,
    VgOverlayPlayModule,
    ScrollingModule,
    BeautifulChartsModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    CardComponent,
    OptionPanelComponent,
    BackButtonComponent,
    ErrorPanelComponent,
    GMapComponent,
    GMapInfoComponent,
    SubSectionComponent,
    ToggleTextComponent,
    OnOffComponent,
    StatusComponent,
    SignalComponent,
    KeyValueComponent,
    CountComponent,
    ButtonPanelComponent,
    LinkComponent,
    PageTitleComponent,
    ExpandComponent,
    CookieComponent,
    BarChartComponent,
    CarouselComponent,
    CarouselViewerComponent,
    TableComponent,
    VideoPlayerComponent,
    ButtonSmallComponent,
    ListViewerComponent,
    ToggleComponent,
    SearchComponent,
    NoResultComponent,
  ],
  exports: [
    VgCoreModule,
    VgBufferingModule,
    VgControlsModule,
    VgOverlayPlayModule,
    ScrollingModule,
    ChartsModule,
    BeautifulChartsModule,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    CardComponent,
    OptionPanelComponent,
    BackButtonComponent,
    ErrorPanelComponent,
    GMapComponent,
    GMapInfoComponent,
    SubSectionComponent,
    ToggleTextComponent,
    OnOffComponent,
    StatusComponent,
    SignalComponent,
    KeyValueComponent,
    CountComponent,
    ButtonPanelComponent,
    LinkComponent,
    PageTitleComponent,
    ExpandComponent,
    CookieComponent,
    BarChartComponent,
    CarouselComponent,
    CarouselViewerComponent,
    TableComponent,
    VideoPlayerComponent,
    ButtonSmallComponent,
    ListViewerComponent,
    ToggleComponent,
    SearchComponent,
    NoResultComponent,
  ],
})

export class SharedModule {
}
