<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="title">{{title}}</mat-panel-title>
      <mat-panel-description class="description">{{description}}</mat-panel-description>
    </mat-expansion-panel-header>

    <p class="data">{{data}}</p>
  </mat-expansion-panel>
</mat-accordion>
