<h3>{{data.heading}}</h3>
<div>
  <p>{{data.message}}</p>
  <p [hidden]="!showWarning" class="warning">Note: this action cannot be reversed </p>

  <div class="action-panel">
    <button mat-raised-button color="accent" (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onYesClick()" cdkFocusInitial>Yes</button>
  </div>
</div>

