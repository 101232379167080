import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';

import { ServerAddComponent } from 'src/app/components/server/components/server-add/server-add.component';
import { ServerDetailsComponent } from 'src/app/components/server/components/server-details/server-details.component';
import { ServerPageComponent } from 'src/app/components/server/server.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ServerPageComponent,
    ServerAddComponent,
    ServerDetailsComponent,
  ],
  exports: [
  ],
})

export class ServerModule {
}
