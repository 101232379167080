<div class="cookie-container" *ngIf="!accepted">
  <h5>This website uses cookies</h5>
  <h5>
    We use cookies to personalise contents, to provide features and to analyse our traffic.
    We also share information about your use of our site with our analytics partners who may
    combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
  </h5>
  <div class="button-container">
    <button mat-raised-button (click)="dismiss()" color="accent">Ok</button>
    <button mat-raised-button [routerLink]="['/cookies-policy']" color="warning">Read more</button>
  </div>
</div>
