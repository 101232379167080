/* eslint-disable max-len */
import { ImpactAlarm } from './../../../../../../models/ImpactAlarm';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImpactAlarmInfoComponent } from 'src/app/components/project/components/alarm/components/impact-alarm-info/impact-alarm-info.component';


@Component({
  selector: 'app-impact-alarm-list',
  templateUrl: './impact-alarm-list.component.html',
  styleUrls: ['./impact-alarm-list.component.scss']
})
export class ImpactAlarmListComponent implements OnInit {@Input() alarmList;

  columnDetails = [];

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    this.columnDetails = [
      {
        key: 'sensorValue',
        value: 'Level',
        type: 'alarmLevel',
      },
      {
        key: 'createdAt',
        value: 'Date and Time',
        type: 'string',
      },
      {
        key: 'sensorName',
        value: 'Message',
        type: 'string',
      },
      {
        key: 'deviceFriendlyName',
        value: 'Device',
        type: 'string',
      },
      {
        key: 'deleted',
        value: 'Value',
        type: 'string',
      },
    ];
  }

  onRowClicked(impactAlarm: ImpactAlarm) {
    const dialogRef = this.dialog.open(
      ImpactAlarmInfoComponent,
      {
        width: '100%',
        height: '50rem',
        data:
        {
          impactAlarm,
        },
      });
  }
}
