import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LightingService } from 'src/app/components/project/components/lighting/lighting.service';
import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';
import { ProjectService } from 'src/app/components/project/project.service';
import { IMapMarker } from 'src/app/interfaces/IMapMarker';
import { Project } from 'src/app/models/Project';
import { MarkerService } from 'src/app/shared/services/marker.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.scss'],
})

export class ProjectMapComponent implements OnInit {
  project: Project;
  allMarkers: IMapMarker[] = new Array();
  mainMarker: IMapMarker;

  constructor(
    private projectService: ProjectService,
    private lightingService: LightingService,
    private monitoringService: MonitoringService,
    private markerService: MarkerService,
    private route: ActivatedRoute,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.refreshProject();
  }

  refreshProject() {
    const projectId = this.route.snapshot.paramMap.get('id');
    this.project = null;
    this.projectService.project = null;
    this.getProject(projectId);
    this.fetchStations(projectId);
    this.fetchLamps(projectId);
    this.fetchCameras(projectId);
    this.fetchAntiThefts(projectId);
  }

  private getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
        this.mainMarker = this.markerService.generateProjectMarker(this.project);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  private fetchStations(projectId: string) {
   this.lightingService.getStationMapItems(projectId).subscribe(
      (stations) => {
        this.toastService.showEmptyResponse('No Stations', stations.length);
        this.generateMarkers(stations);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }

  private fetchLamps(projectId: string) {
    this.lightingService.getLampMapItems(projectId).subscribe(
      (stations) => {
        this.toastService.showEmptyResponse('No Lamps', stations.length);
        this.generateMarkers(stations);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }

  private fetchCameras(projectId: string) {
    this.monitoringService.getCameraMapItems(projectId).subscribe(
      (stations) => {
        this.toastService.showEmptyResponse('No Cameras', stations.length);
        this.generateMarkers(stations);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }

  private fetchAntiThefts(projectId: string) {
    this.lightingService.getAntiTheftMapItems(projectId).subscribe(
      (stations) => {
        this.toastService.showEmptyResponse('No AntiThefts', stations.length);
        this.generateMarkers(stations);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }

  private generateMarkers(devices) {
    const markers = this.markerService.generateDeviceMarker(devices);
    this.allMarkers = this.allMarkers.concat(markers);
  }
}
