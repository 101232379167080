import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})

export class ToggleComponent {
  @Output() public outputFilter: EventEmitter<any> = new EventEmitter();

  @Input() value: boolean;
  @Input() text: string;

  constructor() {
  }

  filter($event) {
    this.outputFilter.emit($event.checked);
  }
}
