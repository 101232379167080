import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { UserPermissions } from 'src/app/UserPermissions';

@Injectable({
  providedIn: 'root',
})

export class UserService {

  constructor(private auth: AuthService) {
  }

  canAddNote() {
    return this.auth?.user.hasPermission(UserPermissions.createNote);
  }

  canDeleteNote() {
    return this.auth?.user.hasPermission(UserPermissions.deleteNote);
  }

  canAddProject() {
    return this.auth?.user.hasPermission(UserPermissions.createProject);
  }

  canSyncProject() {
    return this.auth?.user.hasPermission(UserPermissions.syncProject);
  }

  canAddServer() {
    return this.auth?.user.hasPermission(UserPermissions.createServer);
  }

  canDeleteServer() {
    return this.auth?.user.hasPermission(UserPermissions.deleteServer);
  }

  isAdmin() {
    return this.auth?.user.accessAll();
  }

  canReadVdot(){
    return this.auth?.user.hasPermission(UserPermissions.readVdot);
  }

  canReadGccc(){
    return this.auth?.user.hasPermission(UserPermissions.readGccc);
  }

  canReadCos(){
    return this.auth?.user.hasPermission(UserPermissions.readCos);
  }

  canReadSadfit(){
    return this.auth?.user.hasPermission(UserPermissions.readSadfit);
  }

  canReadCot(){
    return this.auth?.user.hasPermission(UserPermissions.readCot);
  }

  canReadCom(){
    return this.auth?.user.hasPermission(UserPermissions.readCom);
  }

  canReadCol(){
    return this.auth?.user.hasPermission(UserPermissions.readCol);
  }

  canReadGdc(){
    return this.auth?.user.hasPermission(UserPermissions.readGdc);
  }

  canReadChb(){
    return this.auth?.user.hasPermission(UserPermissions.readChb);
  }
}
