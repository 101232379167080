import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-temperatures',
  templateUrl: './temperatures.component.html',
  styleUrls: ['./temperatures.component.scss'],
})

export class TemperaturesComponent {
  @Output() public outputFilter: EventEmitter<any> = new EventEmitter();

  @Input() heading: string;
  @Input() timestamp: Date;
  @Input() data: any[];

  constructor() {
  }

  refresh() {
    this.outputFilter.emit();
  }
}
