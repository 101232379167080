<app-page-title icon="fas fa-ban" title="Unauthorized."></app-page-title>

<div class="container">
  <div class="row error-container">
    <div class="col-md-4">
      <h1 class="error-sign">
        <i class="fas fa-ban"></i>
      </h1>
    </div>
    <div class="col-md-8">
      <p class="title">Contact administrator. <small class="sub-title">You don't have the sufficient permission.</small></p>

      <div class="row">
        <div class="col-md-6">
          <app-card heading="Contact us">
            <div card-body>
              <div class="contact-container">
                <a href="tel:+61420000000" class="contact"><i class="fas fa-phone"></i> Phone </a>
                <a href="mailto:development@trafficltd.com.au" class="contact"><i class="fas fa-envelope"></i> E-mail </a>
              </div>

            </div>
          </app-card>
        </div>

        <div class="col-md-6">
          <app-card heading="Other useful links">
            <div card-body>
              <app-link name="Go home" url="/"></app-link>
            </div>
          </app-card>
        </div>
      </div>

      <app-button-panel>
        <div buttons>
          <app-back-button></app-back-button>
        </div>
      </app-button-panel>
    </div>
  </div>
</div>
