<div class="filter" card-body>
  <div class="message found" *ngIf="count > 0">
    {{count}} results found <span *ngIf="userFilterString.length > 0"> for "{{userFilterString}}"</span>
  </div>
  <div class="message no" *ngIf="count === 0">
    No results found for "{{userFilterString}}"
  </div>
  <mat-form-field>
    <mat-label>{{heading}}</mat-label>
    <input matInput (keyup)="search($event)" [placeholder]="heading" #input>
  </mat-form-field>
</div>
