<app-page-title icon="fas fa-plus" title="Add new project"></app-page-title>

<div class="container">
  <form class="new-project" [formGroup]="project" (ngSubmit)="createNew(project.value)">
    <div class="row">
      <div class="col-md-6">
        <h2>Project details</h2>

        <mat-form-field appearance="outline">
          <mat-label>Project name</mat-label>
          <input matInput placeholder="Project name" type="text" formControlName="projectName">
          <mat-hint>This will be the name of the project</mat-hint>
          <mat-error *ngIf="hasError('projectName', 'required')">Project name is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput placeholder="City" type="text" formControlName="city">
          <mat-hint>The project location</mat-hint>
          <mat-error *ngIf="hasError('city', 'required')">City is required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <h2> Project co-ordinates </h2>

        <mat-form-field appearance="outline">
          <mat-label>Latitude</mat-label>
          <input matInput placeholder="Latitude" type="number" formControlName="latitude">
          <mat-hint>Latitude</mat-hint>
          <mat-error *ngIf="hasError('latitude', 'required')">Latitude is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Longitude</mat-label>
          <input matInput placeholder="Longitude" type="number" formControlName="longitude">
          <mat-hint>Longitude</mat-hint>
          <mat-error *ngIf="hasError('longitude', 'longitude')">Longitude is required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <app-button-panel>
      <div buttons>
        <app-back-button></app-back-button>

        <button mat-raised-button color="primary" type="submit" [disabled]="!project.valid">Create</button>
      </div>
    </app-button-panel>
  </form>
</div>
