import { Component, Input, Output, EventEmitter } from '@angular/core';

import { CameraConnected } from 'src/app/models/CameraConnected';

@Component({
  selector: 'app-cameras-connected',
  templateUrl: './cameras-connected.component.html',
})

export class CamerasConnectedComponent {
  @Output() public outputFilter: EventEmitter<any> = new EventEmitter();

  @Input() heading: string;
  @Input() timestamp: Date;
  @Input() data: CameraConnected[];

  constructor() {
  }

  refresh() {
    this.outputFilter.emit();
  }
}
