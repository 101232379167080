import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-carousel-viewer',
  templateUrl: './carousel-viewer.component.html',
  styleUrls: ['./carousel-viewer.component.scss'],
})

export class CarouselViewerComponent {
  slides;
  slideIndex;
  slideLength;
  slideImage;
  next;
  previous;
  dotClick;
  setImage;

  constructor(
    public dialogRef: MatDialogRef<CarouselViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.slides = data.slides;
      this.slideIndex = data.slideIndex;
      this.next = data.next;
      this.previous = data.previous;
      this.dotClick = data.dotClick;
      this.setImage = data.setImage;
      this.slideLength = this.slides.length - 1;
      this.setImage(this.slideIndex);
    }

  onOkClick(): void {
    this.dialogRef.close(false);
  }
}
