<app-project-header icon="fas fa-traffic-light" heading="TTMS" [project]="project"></app-project-header>


<div class="row">
    <div class="column" style="width: 100px;">

    </div>
    <div class="column" >
      <div [ngSwitch]="project.name">
        <div *ngSwitchCase="'VicRoads'">
        </div>
        <div *ngSwitchCase="'Anti Theft Install'">
        </div>
        <div *ngSwitchCase="'Vic Roads HPS Sites'">
            
        </div>
        <div *ngSwitchCase="'City of Salisbury'">
        </div>
        <div *ngSwitchCase="'Traffic Monitoring'">
            <iframe width="2000" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiNGE2OGFmYjgtNjE0YS00NzBjLWI4MTctMDY4ZjQ2NTFlY2RjIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe> 
        </div>
        <div *ngSwitchDefault>This is Default</div>
      </div>  
    </div>  
    <div class="column">
      
    </div>
</div>