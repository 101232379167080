import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-map-tools',
  templateUrl: './map-tools.component.html',
  styleUrls: ['./map-tools.component.scss'],
})

export class MapToolsComponent {
  @Output() public outputMarkerFilter: EventEmitter<any> = new EventEmitter();
  @Output() public outputSensorFilter: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  filterMarker(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.outputMarkerFilter.emit(filterValue);
  }

  filterSensor(type) {
    this.outputSensorFilter.emit(type);
  }
}
