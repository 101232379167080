<form class="new-note" [formGroup]="note" (ngSubmit)="createNew()">
  <h2>Add new note</h2>

  <mat-form-field appearance="outline">
    <mat-label>Message</mat-label>
    <input matInput placeholder="Message" type="text" formControlName="message">
    <mat-hint>Message</mat-hint>
    <mat-error *ngIf="hasError('message', 'required')">Message is required</mat-error>
  </mat-form-field>

  <app-toggle *ngIf="notesService.isAdmin()" text="Post as private" [value]="note.isAdmin" (outputFilter)="setIsAdmin($event)"></app-toggle>
</form>
<app-button-panel>
  <div buttons>
    <button mat-raised-button color="primary" [disabled]="!note.valid" (click)="createNew()">Create</button>
    <button mat-raised-button (click)="dismiss()">Cancel</button>
  </div>
</app-button-panel>
