<app-page-title icon="fas fa-lightbulb" title="Cameras" ></app-page-title>

<app-option-panel heading="Options">
  <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="refreshItem()" toolTip="Refresh items" options></app-button-small>
  <app-back-button [round]="true" options></app-back-button>
</app-option-panel>

<div class="container">
  <app-loading [check]="connectedList" color="primary"></app-loading>

  <div *ngIf="connectedList">
    <app-cameras-connected heading="Cameras connected" [data]="connectedList" [timestamp]="connectedTimeStamp" (outputFilter)="refreshConnected()"></app-cameras-connected>
  </div>

    <div *ngIf="modelList">
    <app-cameras heading="Available camera models" [data]="modelList" [timestamp]="modelTimeStamp" (outputFilter)="refreshModels()"></app-cameras>
  </div>

  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>
