import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';

import { ContactComponent } from 'src/app/components/contact/contact.component';
import { Error401Component } from 'src/app/components/error401/error401.component';
import { Error404Component } from 'src/app/components/error404/error404.component';
import { FAQComponent } from 'src/app/components/faq/faq.component';
import { HomeImagesComponent } from 'src/app/components/home/components/home-images/home-images.component';
import { HomeComponent } from 'src/app/components/home/home.component';
import { PrivacyComponent } from 'src/app/components/privacy/privacy.component';
import { TermsComponent } from 'src/app/components/terms/terms.component';
import { CookiePolicyComponent } from 'src/app/components/cookie-policy/cookie-policy.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    HomeComponent,
    HomeImagesComponent,
    ContactComponent,
    FAQComponent,
    TermsComponent,
    PrivacyComponent,
    CookiePolicyComponent,
    Error404Component,
    Error401Component,
  ],
})

export class StandAloneModule {
}
