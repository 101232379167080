export class DataSensor {
  constructor(public name: string,
              public alarms: number,
              public uv: number,
              public noise: number,
              public windDirection: string,
              public windSpeed: number,
              public lux: number,
              public rain: number,
              public pressure: number,
              public humidity: number,
              public temperature: number,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              public pM2_5: number,
              public pM1: number,
              public pM10: number,
              public time: string) {
  }
}
