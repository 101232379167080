import { Injectable } from '@angular/core';

import { ModelError } from 'src/app/models/ModelError';

@Injectable({
  providedIn: 'root',
})

export class ErrorService {
  constructor() {
  }

  static parseError<T>(error: any): ModelError[] {
    const errorList: ModelError[] = new Array();

    if (error.status === 500) {
      errorList.push(new ModelError(error.name, error.error ));
    } else {
      const backEndErrors = error.error.errors;

      Object.keys(backEndErrors).forEach((key) => {
        backEndErrors[key].forEach((err) => {
          errorList.push(new ModelError(key, err ));
        });
      });
    }
    return errorList;
  }
}
