import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastNotificationsModule } from 'ngx-toast-notifications';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_CONFIG, AppConfig } from './configs/app.config';

import { AlarmModule } from './components/project/components/alarm/alarm.module';
import { ClearsonicsModule } from './components/project/components/clearsonics/clearsonics.module';
import { EnvironmentModule } from './components/project/components/environment/environment.module';
import { MapModule } from './components/project/components/map/map.module';
import { MonitoringModule } from './components/project/components/monitoring/monitoring.module';
import { ReportModule } from './components/project/components/report/report.module';
import { ProjectModule } from './components/project/project.module';
import { ServerModule } from './components/server/server.module';
import { StandAloneModule } from './shared/modules/standalone.module';
import { SharedModule } from './shared/shared.module';
import { NotesModule } from './components/project/components/notes/notes.module';
import { ClientComponent } from './components/client/client.component';
import { ClientProjectComponent } from './components/client/components/client-project/client-project.component';

@NgModule({
  declarations: [
    AppComponent,
    ClientComponent,
    ClientProjectComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule ,
    ServerModule,
    BrowserAnimationsModule,
    ToastNotificationsModule,
    SharedModule,
    ProjectModule,
    StandAloneModule,
    MapModule,
    MonitoringModule,
    EnvironmentModule,
    AlarmModule,
    NotesModule,
    ClearsonicsModule,
    ReportModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBBqm6CQFfREj3MXAOay-O1CyAL6aicdns',
    }),
  ],
  providers: [
    { provide: APP_CONFIG, useValue: AppConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
