export class CameraConnected {
  constructor(public name: string,
              public address: string,
              public port: number,
              public type: number,
              public channel: string,
              public account: string,
              public remark: string,
              public time: string,
              public latitude: number,
              public longitude: number,
              public status: number,
              public alarms: number,
              public keyAlarm: boolean,
              public channelNumber: number,
              public sdkPort: number,
              public rtspPort: number) {
  }
}
