import { Component, Input, OnInit } from '@angular/core';

import { LightingService } from 'src/app/components/project/components/lighting/lighting.service';
import { IMapMarker } from 'src/app/interfaces/IMapMarker';
import { Project } from 'src/app/models/Project';
import { MarkerService } from 'src/app/shared/services/marker.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
})

export class ProjectInfoComponent implements OnInit {
  @Input() project: Project;
  marker: IMapMarker;
  stationMarkers: IMapMarker[];

  // eslint-disable-next-line max-len
  constructor(private markerService: MarkerService, private lightingService: LightingService, private monitoringService: MonitoringService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.marker = this.markerService.generateMarker(this.project.name, this.project.latitude, this.project.longitude, this.project);
    if (this.project.isFonda) {
      this.fetchStations(this.project.id);
    }
    if(this.project.name === 'iMonitor'){
      this.fetchImonitors();
    }
  }

  private fetchStations(projectId: string) {
    return this.lightingService.getStations(projectId).subscribe(
      (stations) => {
        this.stationMarkers = this.markerService.generateStationMarker(stations);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }

  private fetchImonitors(){
    return this.monitoringService.getTelstraMonitoringSensors().subscribe(
      (iMonitor) => {
         this.stationMarkers = this.markerService.generateMonitoringMarker(iMonitor);
      },
      (error) =>{
        this.toastService.showToast('error', 'Error!', 'Failed to fetch iMonitor.');
      },
    );
  }
}
