import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

import { LocalStorage } from 'ngx-store';
import { AuthService } from './auth.service';
import { isBrowserValid } from './shared/helpers/utils.helper';

declare const require;
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Modernizr;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  @LocalStorage() language = 'en';
  isOnline: boolean;

  constructor(
    public auth: AuthService,
    private http: HttpClient,
    private title: Title,
    private meta: Meta,
    private snackBar: MatSnackBar,
    private router: Router) {
    this.isOnline = navigator.onLine;
  }

  ngOnInit() {
    this.title.setTitle('TSTMax');

    this.onEvents();
    this.checkBrowser();
  }

  onEvents() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        switch (event.urlAfterRedirects) {
          case '/':
            this.title.setTitle('TSTMax');
            this.meta.updateTag({
              name: 'description',
              content: 'TSTMax',
            });
            break;
          default:
            this.title.setTitle('TSTMax');
            this.meta.updateTag({
              name: 'description',
              content: 'TSTMax',
            });
            break;
        }
      }
    });
  }

  checkBrowser() {
    if (isBrowserValid()) {
      this.checkBrowserFeatures();
    } else {
      this.snackBar.open('Unsupported Browser', 'OK');
    }
  }

  checkBrowserFeatures() {
    let supported = true;
    for (const feature in Modernizr) {
      if (Modernizr.hasOwnProperty(feature) &&
        typeof Modernizr[feature] === 'boolean' && Modernizr[feature] === false) {
        supported = false;
        break;
      }
    }

    if (!supported) {
      this.snackBar.open('Unsupported Browser', 'OK');
    }

    return supported;
  }
}
