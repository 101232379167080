import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';
import { EnvironmentService } from './environment.service';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
})

export class EnvironmentComponent implements OnInit {
  projectId;
  project: Project;
  environmentDetails = null;
  dataSensors = null;
  envTimeStamp;
  dataSensorTimeStamp;

  constructor(
    private environmentService: EnvironmentService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(this.projectId);
    if(this.project.isFonda){
    this.refreshItem();
   }
  }

  getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  refreshItem() {
    this.refreshSensors();
    this.refreshDataSensor();
  }

  refreshDataSensor() {
    this.dataSensors = null;
    this.dataSensorTimeStamp = new Date();
    this.fetchDataSensors(this.projectId);
  }

  refreshSensors() {
    this.environmentDetails = null;
    this.envTimeStamp = new Date();
    this.fetchSensors(this.projectId);
  }

  private fetchSensors(projectId: string) {
    this.environmentService.getEnvironmentSensors(projectId).subscribe(
      (temperatureSensors) => {
        this.environmentDetails = temperatureSensors;
        this.toastService.showEmptyResponse('No environment sensors', temperatureSensors.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to update environment sensors.');
      },
    );
  }

  private fetchDataSensors(projectId: string) {
    this.environmentService.getDataSensors(projectId).subscribe(
      (dataSensors) => {
        this.dataSensors = dataSensors;
        this.toastService.showEmptyResponse('No data sensors', dataSensors.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to update data sensors.');
      },
    );
  }
}
