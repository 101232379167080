import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-back-button',
    template: `<button mat-raised-button (click)="goBack()" [color]="color" *ngIf="!round">Back</button>
    <app-button-small cssClass="fas fa-arrow-left" type="warning" (click)="goBack()" *ngIf="round" toolTip="Go back" options>
    </app-button-small>
    `,
})
export class BackButtonComponent {
    @Input()color = 'warning';
    @Input()round = false;

  constructor(public location: Location) { }

  goBack() {
    this.location.back();
  }
}
