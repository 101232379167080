import { Component, EventEmitter, Output, OnInit } from '@angular/core';

import { AlarmService } from 'src/app/components/project/components/alarm/alarm.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-alarm-tools',
  templateUrl: './alarm-tools.component.html',
  styleUrls: ['./alarm-tools.component.scss'],
})

export class AlarmToolsComponent implements OnInit{
  @Output() public outputFilter: EventEmitter<any> = new EventEmitter();
  @Output() public historyFilter: EventEmitter<any> = new EventEmitter();
  @Output() public pageFilter: EventEmitter<any> = new EventEmitter();

  projectId;

  constructor(public alarmService: AlarmService, private route: ActivatedRoute,) {
  }
  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('id');
  }

  firstPage() {
    this.alarmService.currentPage = 1;
    this.pageFilter.emit(null);
  }

  previousPage() {
    if (this.alarmService.currentPage !== 1) {
      this.alarmService.currentPage = this.alarmService.currentPage-=1;
      this.pageFilter.emit(null);
    }
  }

  nextPage() {
    if (this.alarmService.currentPage !== this.alarmService.getTotalPage()) {
      this.alarmService.currentPage = this.alarmService.currentPage+=1;
      this.pageFilter.emit(null);
    }
  }

  lastPage() {
    this.alarmService.currentPage = this.alarmService.getTotalPage();
    this.pageFilter.emit(null);
  }

  filterAlarm(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.outputFilter.emit(filterValue);
  }

  filterLevel(level) {
    this.outputFilter.emit(level);
  }

  setHistory(value) {
    this.alarmService.currentPage = 1;
    this.alarmService.showHistory = value;
    this.historyFilter.emit(value);
  }

  setItems(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (+value > 0) {
      this.alarmService.itemPerPage = +value;
      this.pageFilter.emit(value);
    } else {
    }
  }

  setPage(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (+value > 0) {
      this.alarmService.currentPage = +value;
      this.pageFilter.emit(value);
    }
  }
}
