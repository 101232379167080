<footer>
  <div class="row footer-container">

    <div class="col-md-4 text-left love-text">
      <span>Made with <i class="fas fa-heart"></i> by Traffic Technologies</span>
    </div>
    <div class="col-md-4 text-center love-text">
      <span> © {{date}} Traffic Technologies</span>
    </div>

    <div class="col-md-4">
      <div class="footer-links">
        <a [href]="appConfig.routes.contact" class="link">
          <div class="icon"><i class="fas fa-phone"></i></div>Contact us
        </a>
        <a [href]="appConfig.routes.faq" class="link">
          <div class="icon"><i class="fas fa-question"></i></div>FAQs
        </a>
        <a [href]="appConfig.routes.terms" class="link">
          <div class="icon"><i class="fas fa-exclamation"></i></div>T&Cs
        </a>
        <a [href]="appConfig.routes.privacy" class="link">
          <div class="icon"><i class="fas fa-key"></i></div>Privacy policy
        </a>
      </div>

    </div>
  </div>
</footer>
