import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Server } from 'src/app/models/Server';
import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})

export class ClientService {
  constructor(private httpService: HttpHelperService, private userService: UserService) {
  }

  getAllClientProjects(clientName: string): Observable<any> {
    return this.httpService.getClientProjects(clientName)
    .pipe(
      catchError((err) => {
        LogService.handleError('getAllClientProjects', []);
        return throwError(err);
      }),
    );
  }
}