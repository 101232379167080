<div class="carousel-container" *ngIf="slideImage">
  <div class="details-container">
    <h6 class="timestamp">Uploaded at: {{slideImage.time}}
      <span class="close" (click)="onOkClick()">X</span>
    </h6>
  </div>
  <div class="image-container">
    <button class="arrow" (click)="previous()">
      <i class="fas fa-chevron-left"></i>
    </button>

    <div class="carousel-image">
      <img [src]="slideImage.path" [alt]="slideImage.text" class="slide"/>
    </div>

    <button class="arrow right" (click)="next()">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>

  <div class="image-details">
    <div *ngFor="let image of slides; let i = index">
      <span class="dot" (click)="dotClick(i)" [ngClass]="{'dot-selected': slideIndex === i}"></span>
    </div>
  </div>
</div>
