import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/Project';
import { ProjectService } from 'src/app/components/project/project.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { IMapMarker } from 'src/app/interfaces/IMapMarker';
import { LightingService } from '../lighting/lighting.service';
import { MarkerService } from 'src/app/shared/services/marker.service';

@Component({
  selector: 'app-lights',
  templateUrl: './lights.component.html',
  styleUrls: ['./lights.component.scss']
})
export class LightsComponent implements OnInit {
  projectId;
  project: Project = null;
  markers: IMapMarker[] = new Array();
  mainMarker: IMapMarker;
  allMarkers: IMapMarker[] = new Array();
  userFilter = '';

  constructor(
    private projectService: ProjectService,
    private lightingService: LightingService,
    private route: ActivatedRoute,
    private markerService: MarkerService,
    private toastService: ToastService) {
    }

  ngOnInit(): void {
    this.fetchProject();
  }

  private fetchProject() {
    this.projectService.getProject(this.route.snapshot.paramMap.get('id')).subscribe(
      (project) => {
        this.project = project;
        if(this.project.isFonda){
        //this.fetchStations(this.project.id);
        //this.fetchLampConnected(this.project.id);
        //this.fetchLamps(this.project.id);
        console.log(this.project.serverName);
        console.log(this.project.name);
        this.fetchLampDbConnected(this.project.serverName,this.project.id);
        this.fetchDbStations(this.project.serverName,this.project.name);
         }
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  private fetchDbStations(serverName:string, projectName: string) {
    this.lightingService.getDbStations(serverName,projectName).subscribe(
      (stations) => {
        this.toastService.showEmptyResponse('No stations', stations.length);
        this.generateStationMarkers(stations);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }

  private fetchStations(projectId: string) {
    this.lightingService.getStations(projectId).subscribe(
      (stations) => {
        this.toastService.showEmptyResponse('No stations', stations.length);
        this.generateStationMarkers(stations);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }



  private fetchLamps(projectId: string) {
    this.lightingService.getLamps(projectId).subscribe(
      (lamps) => {
        this.toastService.showEmptyResponse('No lamps', lamps.length);
        this.generateLightMarkers(lamps);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch lamps.');
      },
    );
  }

  private fetchLampConnected(projectId: string){
    this.lightingService.getLampsConnected(projectId).subscribe(
     (lamp) =>{
      this.generateLightMarkers(lamp);
     },
     (error) => {
      this.toastService.showToast('error', 'Error!', 'Failed to fetch connected lamps.');
     },
    );
  }

  private fetchLampDbConnected(serverName:string,projectId: string){
    this.lightingService.getLampsDbConnected(serverName,projectId).subscribe(
     (lamp) =>{
      this.generateLightMarkers(lamp);
     },
     (error) => {
      this.toastService.showToast('error', 'Error!', 'Failed to fetch connected lamps.');
     },
    );
  }

  private generateStationMarkers(stations) {
    stations.map((station) => {
      station.sensorType = 'Station';
      return station;
    });
    const lightingMarkers =  this.markerService.generateStationstMarker(stations);
    lightingMarkers.map((marker) => {
      marker.link = `/project/lighting/${this.project.id}/station/${marker.item.id}`;
      return marker;
    });

    this.mainMarker = lightingMarkers[0];
    this.allMarkers = this.allMarkers.concat(lightingMarkers);
    this.refreshMarkers();
  }

  private generateLightMarkers(lamp) {
    lamp.map((lamp) => {
      lamp.sensorType = 'Station';
      return lamp;
    });
    const lightingMarkers =  this.markerService.generateLightMarker(lamp);
    lightingMarkers.map((marker) => {
      marker.link = `/project/lighting/${this.project.id}/station/${marker.item.id}`;
      return marker;
    });

    this.mainMarker = lightingMarkers[0];
    this.allMarkers = this.allMarkers.concat(lightingMarkers);
    this.refreshMarkers();
  }

  private refreshMarkers() {
    this.markers = Array.from(this.allMarkers); //.filter((marker) =>
      //marker.name.toLowerCase().includes(this.userFilter) || marker.type.toLowerCase().includes(this.userFilter));
  }

}
