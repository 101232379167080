import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-on-off',
  templateUrl: './on-off.component.html',
  styleUrls: ['./on-off.component.scss'],
})

export class OnOffComponent {
  @Input() value: boolean;
  @Input() isColumn = true;
  @Input() text: string;

  constructor() {
  }
}
