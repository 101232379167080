import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-option-panel',
  templateUrl: './option-panel.component.html',
  styleUrls: ['./option-panel.component.scss'],
})

export class OptionPanelComponent {
  @Input() heading: string;

  collapsed = false;
  cssClass = 'far fa-window-close';
  toolTip = 'Collapse';

  constructor() {
  }

  collapse() {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      this.cssClass = 'far fa-caret-square-down';
      this.toolTip = 'Open';
    } else {
      this.cssClass = 'far fa-window-close';
      this.toolTip = 'Collapse';
    }
  }
}
