
<app-card [heading]="project.name + ' | ' + project.city + ' | ' +project.type" [subHeading]="'Server: ' + project.serverName">
  <div card-body>
    <div class="map-container">
      <app-gmap [mainMarker]="marker" [markers]="stationMarkers" showInfo="false"></app-gmap>
    </div>
    <div class="button-container" *ngIf="project.isFonda">
      <a mat-raised-button color="success" [routerLink]="['/project/lighting', project.id, 'map']">View</a>
    </div>
  </div>
</app-card>
