<app-page-title icon="fas fa-file" title="Projects"></app-page-title>

<div class="container">
  <app-option-panel heading="Project options">
    <app-button-small *ngIf="projectService.showAddProject()" cssClass="fas fa-plus" type="success" [routerLink]="['/project/new']" toolTip="Add new project" options></app-button-small>
    <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="refreshProjects()" toolTip="Refresh project" options></app-button-small>
    <app-button-small *ngIf="projectService.showSyncProject()" cssClass="fas fa-sync" type="warning" (outputClick)="syncProjects()" toolTip="Sync project" options></app-button-small>
  </app-option-panel>

  <div class="row">
    <app-loading [check]="projectList" color="primary"></app-loading>
    <app-no-result class="no-result" [list]="projectList"></app-no-result>

    <div *ngFor="let servers of groupedProjectList | keyvalue" class="container">

      <mat-toolbar color="primary" class="toolbar">
        <span> <i class="fas fa-server"></i> {{servers.key}}</span>
      </mat-toolbar>
      <div class="row">
    <app-no-result class="no-result" [list]="servers.value"></app-no-result>

        <div *ngFor="let project of servers.value" class="col-md-4">
          <app-card [heading]="project.name" [routerLink]="['/project/home', project?.id]">

            <div *ngIf="!!project.isFonda" card-body>
              <div class="details">
                <div class="detail green" matTooltip="Stations"><i class="fas fa-hdd"></i> {{project.fonda.stationsCount}}</div>
                <div class="detail orange" matTooltip="Lamps"><i class="fas fa-lightbulb"></i> {{project.fonda.lampsCount}}</div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </div>

  </div>
</div>

