
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';

import { Alarm } from 'src/app/models/Alarm';
import { AlarmCategory } from 'src/app/models/AlarmCategory';
import { AlarmMeta } from 'src/app/models/AlarmMeta';
import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';

import { shareReplay } from 'rxjs/operators';
import { Device } from 'src/app/models/Device';

@Injectable({
  providedIn: 'root',
})

export class AlarmService {
  cache = {};
  public alarmCount = 0;
  public currentPage = 1;
  public showHistory = false;
  public itemPerPage = 100;

  private alarmCategories: AlarmCategory[] = null;

  constructor(private httpService: HttpHelperService) {
  }

  getTotalPage() {
    return Math.ceil(this.alarmCount/this.itemPerPage);
  }

  getAlarmsWithMeta(projectId: string): Observable<any> {
    return this.httpService.getAlarms(projectId, this.showHistory)
    .pipe(
      map((data) => {
        const meta = data as AlarmMeta;
        this.alarmCategories = meta.alarmCategories;
        this.alarmCount = meta.alarmCount;
        return meta;
      }),
      catchError((err) => {
        LogService.handleError('getAlarmsWithMeta', []);
        return throwError(err);
      }),
    );
  }

  getAlarmsWithPage(projectId: string): Observable<any> {
    return this.httpService.getAlarmsWithPage(projectId, this.currentPage, this.itemPerPage, this.showHistory)
    .pipe(
      map((data) => {
        const alarmList = data as Alarm[];
        return alarmList;
      }),
      catchError((err) => {
        LogService.handleError('getAlarmsWithPage', []);
        return throwError(err);
      }),
    );
  }

  getImpactAlarmsWithPage(): Observable<any>{
    return this.httpService.getImpactAlarmsWithPage()
    .pipe(
      catchError((err) => {
        LogService.handleError('getTelstraMonitoringSensorsHistory', []);
        return throwError(err);
      }),
    );
  }

  getAlarmReport(projectId: string, page: number, count: number, from: string, to: string): Observable<any> {
    return this.httpService.getAlarmReport(projectId, page, count, from, to)
    .pipe(
      catchError((err) => {
        LogService.handleError('getAlarmReport', []);
        return throwError(err);
      }),
    );
  }

  getPoles(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getPoles(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getPoles', []);
        return throwError(err);
      }),
    );
  }

  getAlarmCategory(categoryId: number) {
    return this.alarmCategories.find((category) => category.id === categoryId).name;
  }

  getDeviceFriendlyName(deviceId: string): string {
    /*let deviceFriendlyName;
    if (this.cache[deviceId]) {
      console.log('Returning cached value!');

     this.cache[deviceId].forEach((item) => {
       deviceFriendlyName = item[0].deviceFriendlyName;
        console.log(deviceFriendlyName);
      });


      return deviceFriendlyName;
    }

    console.log('Do the request again');
    this.cache[deviceId] = this.httpService.getImpactMonitorDevice(deviceId).pipe(
      shareReplay(1),
      catchError(err => {
          delete this.cache[deviceId];
          return '';
      })); */

     const deviceArray: {[key: string]: string} = {
      '046f15e6-078e-44db-a016-385f9b4d80db': 'iMonitor one',
      '2db3c70a-ab93-47ca-b96c-77264abe8575':  'iMonitor Two',
      'e09ae527-63bb-47db-9823-bf037b9478f1':  'iMonitor Three'
     };

    return deviceArray[deviceId];
  }
}
