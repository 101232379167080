<app-project-header icon="fas fa-camera" heading="Monitoring" [project]="project"></app-project-header>
<app-option-panel heading="Options">
  <app-button-small cssClass="fas fa-home" type="primary" [routerLink]="['/project/home', project?.id]" toolTip="Home" options></app-button-small>
  <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="refreshItem()" toolTip="Refresh sensors" options></app-button-small>
  <app-back-button [round]="true" options></app-back-button>
</app-option-panel>

<div class="container">
  <app-loading [check]="monitoringDetails" color="primary"></app-loading>

  <div class="row" *ngIf="monitoringDetails">
    <div class="count-container">
      <app-count name="Camera" [count]="cameraConnected.length" *ngIf="project.isFonda"></app-count>
      <app-count name="iImpact" [count]="monitoringDetails.impactSensors.length" *ngIf="!project.isFonda"></app-count>
      <app-count name="iPark" [count]="monitoringDetails.parkingSensors.length" *ngIf="project.isFonda"></app-count>
      <app-count name="iWaste" [count]="monitoringDetails.binSensors.length" *ngIf="project.isFonda"></app-count>
      <app-count name="iGeo" [count]="monitoringDetails.gpsSensors.length" *ngIf="project.isFonda"></app-count>
      <app-count name="iSign" [count]="monitoringDetails.tiltSensors.length" *ngIf="project.isFonda"></app-count>
      <app-count name="iCover" [count]="monitoringDetails.manholeSensors.length" *ngIf="project.isFonda"></app-count>
      <app-count name="Accel" [count]="monitoringDetails.accelSensors.length" *ngIf="project.isFonda"></app-count>
    </div>

    <app-sensor-list heading="iImpact" [data]="monitoringDetails.impactSensors" type="impact" class="col-md-12" *ngIf="!project.isFonda"></app-sensor-list>
    <app-sensor-list heading="iPark" [data]="monitoringDetails.parkingSensors" type="parking" class="col-md-12" *ngIf="project.isFonda"></app-sensor-list>
    <app-sensor-list heading="iWaste" [data]="monitoringDetails.binSensors" type="bin" class="col-md-12" *ngIf="project.isFonda"></app-sensor-list>
    <app-sensor-list heading="iGeo" [data]="monitoringDetails.gpsSensors" type="gps" class="col-md-12" *ngIf="project.isFonda"></app-sensor-list>
    <app-sensor-list heading="iSign" [data]="monitoringDetails.tiltSensors" type="tilt" class="col-md-12" *ngIf="project.isFonda"></app-sensor-list>
    <app-sensor-list heading="iCover" [data]="monitoringDetails.manholeSensors" type="manhole" class="col-md-12" *ngIf="project.isFonda"></app-sensor-list>
    <app-sensor-list heading="Accel" [data]="monitoringDetails.accelSensors" type="accel" class="col-md-12" *ngIf="project.isFonda"></app-sensor-list>
  </div>

  <div *ngIf="cameraConnected && project.isFonda" class="col-md-12">
    <app-cameras-connected heading="Camera" [data]="cameraConnected" [timestamp]="cameraTimeStamp" (outputFilter)="refreshCameras()"></app-cameras-connected>
  </div>

  <app-button-panel *ngIf="project.isFonda">
    <div buttons>
      <a mat-raised-button color="basic" [routerLink]="['/project/monitoring', project?.id, 'cameras']">View all cameras</a>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>

