<app-project-header icon="fas fa-bell" heading="Alarm" [project]="project"></app-project-header>

<div class="container">
  <app-loading [check]="project" color="primary"></app-loading>

  <app-option-panel heading="Options">
    <app-button-small cssClass="fas fa-home" type="primary" [routerLink]="['/project/home', project?.id]" toolTip="Home" options></app-button-small>
    <app-back-button [round]="true" options></app-back-button>
  </app-option-panel>

  <div class="row" *ngIf="project && project.name ==='iMonitor'">
    <app-alarm-tools
      class="alarms-list"
      (outputFilter)="filterAlarms($event)"
      (historyFilter)="getAlarmsAndDetails($event)"
      (pageFilter)="getAlarm($event)">
    </app-alarm-tools>
    <app-impact-alarm-list [alarmList]="alarmList" class="alarms-list"></app-impact-alarm-list>
  </div>
  <div class="row" *ngIf="project && project.name !=='iMonitor'">
    <app-alarm-tools
      class="alarms-list"
      (outputFilter)="filterAlarms($event)"
      (historyFilter)="getAlarmsAndDetails($event)"
      (pageFilter)="getAlarm($event)">
    </app-alarm-tools>
    <app-alarm-list [alarmList]="alarmList" class="alarms-list"></app-alarm-list>
  </div>
  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>
