import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';
import { NotesService } from './notes.service';
import { Note } from 'src/app/models/Note';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NoteAddComponent } from './components/note-add/note-add.component';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})

export class NotesComponent implements OnInit {
  projectId;
  notes: Note[] = [];
  project: Project = null;

  constructor(
    public notesService: NotesService,
    private projectService: ProjectService,
    private bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(this.projectId);
    this.refreshItem();
  }

  refreshItem() {
    this.getNotes(this.projectId);
  }

  deleteNote() {
    this.refreshItem();
  }

  addNote() {
    this.bottomSheet.open(NoteAddComponent);
  }

  private getNotes(projectId: string) {
    this.notesService.getNotes(projectId).subscribe(
      (notes) => {
        this.notes = notes;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch notes.');
      },
    );
  }

  private getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }
}
