
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppConfig } from './configs/app.config';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContactComponent } from './components/contact/contact.component';
import { Error401Component } from './components/error401/error401.component';
import { Error404Component } from './components/error404/error404.component';
import { FAQComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { AlarmComponent } from './components/project/components/alarm/alarm.component';
import { VasComponent } from './components/project/components/vas/vas.component';
import { TTMSComponent } from './components/project/components/ttms/ttms.component';
import { CCTVComponent } from './components/project/components/cctv/cctv.component';
import { IMonitorComponent } from './components/project/components/imonitor/imonitor.component';
import { ClearsonicsComponent } from './components/project/components/clearsonics/clearsonics.component';
import { EnvironmentComponent } from './components/project/components/environment/environment.component';
import { CameraListComponent } from './components/project/components/monitoring/components/camera-list/camera-list.component';
import { EnergyComponent } from './components/project/components/energy/energy.component';
import { LightsComponent } from './components/project/components/lights/lights.component';
import { MapComponent } from './components/project/components/map/map.component';
import { MonitoringComponent } from './components/project/components/monitoring/monitoring.component';
import { ProjectAddComponent } from './components/project/components/project-add/project-add.component';
import { ProjectDetailsComponent } from './components/project/components/project-details/project-details.component';
import { ProjectMapComponent } from './components/project/components/project-map/project-map.component';
import { ReportDataComponent } from './components/project/components/report/components/report-data/report-data.component';
import { ReportLampComponent } from './components/project/components/report/components/report-lamp/report-lamp.component';
import { ReportMeterComponent } from './components/project/components/report/components/report-meter/report-meter.component';
import { ReportComponent } from './components/project/components/report/report.component';
import { ClientComponent } from './components/client/client.component';
import { ClientProjectComponent } from './components/client/components/client-project/client-project.component';
import { ProjectComponent } from './components/project/project.component';
import { ServerAddComponent } from './components/server/components/server-add/server-add.component';
import { ServerDetailsComponent } from './components/server/components/server-details/server-details.component';
import { ServerPageComponent } from './components/server/server.component';
import { TermsComponent } from './components/terms/terms.component';
import { GuardService } from './guard.service';
import { InterceptorService } from './interceptor.service';
import { UserPermissions } from './UserPermissions';
import { AlarmReportComponent } from './components/project/components/alarm/components/alarm-report/alarm-report.component';
import { NotesComponent } from './components/project/components/notes/notes.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: AppConfig.routes.contact, component: ContactComponent, pathMatch: 'full' },
  { path: AppConfig.routes.faq, component: FAQComponent, pathMatch: 'full' },
  { path: AppConfig.routes.terms, component: TermsComponent, pathMatch: 'full' },
  { path: AppConfig.routes.privacy, component: PrivacyComponent, pathMatch: 'full' },
  { path: AppConfig.routes.cookie, component: CookiePolicyComponent, pathMatch: 'full' },
  { path: AppConfig.routes.error404, component: Error404Component, pathMatch: 'full' },
  { path: AppConfig.routes.error401, component: Error401Component, pathMatch: 'full' },

  { path: AppConfig.routes.clients, component: ClientComponent, pathMatch: 'full',
    canActivate: [GuardService], data: { permission: UserPermissions.readProject } },
  { path: AppConfig.routes.projects, component: ProjectComponent, pathMatch: 'full',
    canActivate: [GuardService], data: { permission: UserPermissions.readProject } },
  { path: AppConfig.routes.projectNew,  component: ProjectAddComponent, pathMatch: 'full',
    canActivate: [GuardService], data: { permission: UserPermissions.createProject } },
  { path: AppConfig.routes.projectHome, component: ProjectDetailsComponent,
    canActivate: [GuardService], data: { component : 'home', permission: UserPermissions.readProject } },
  { path: AppConfig.routes.projectLightingMap, component: ProjectMapComponent,
    canActivate: [GuardService], data: { component : 'lighting', permission: UserPermissions.readLighting } },
  { path: AppConfig.routes.projectEnergy, component: EnergyComponent,
    canActivate: [GuardService], data: { component : 'energy', permission: UserPermissions.readLighting } },
  { path: AppConfig.routes.projectLights, component: LightsComponent,
    canActivate: [GuardService], data: { component : 'lights', permission: UserPermissions.readLighting } },
  { path: AppConfig.routes.projectEnvironment, component: EnvironmentComponent,
    canActivate: [GuardService], data: { component : 'environment', permission: UserPermissions.readLighting } },
  { path: AppConfig.routes.projectMonitoring, component: MonitoringComponent,
    canActivate: [GuardService], data: { component : 'monitoring', permission: UserPermissions.readEnvironment } },
  { path: AppConfig.routes.monitoringCameraList, component: CameraListComponent, pathMatch: 'full',
    canActivate: [GuardService], data: { permission: UserPermissions.readEnvironment } },
  { path: AppConfig.routes.projectVas, component: VasComponent,
    canActivate: [GuardService], data: { component : 'vas', permission: UserPermissions.readVas } },
  { path: AppConfig.routes.projectTtms, component: TTMSComponent,
    canActivate: [GuardService], data: { component : 'ttms', permission: UserPermissions.readVas } }, 
  { path: AppConfig.routes.projectCctv, component: CCTVComponent,
      canActivate: [GuardService], data: { component : 'cctv', permission: UserPermissions.readVas } },  
  { path: AppConfig.routes.projectImonitor, component: IMonitorComponent,
      canActivate: [GuardService], data: { component : 'imonitor', permission: UserPermissions.readVas } }, 
  { path: AppConfig.routes.projectClearsonics, component: ClearsonicsComponent,
    canActivate: [GuardService], data: { component : 'clearsonics', permission: UserPermissions.readClearSonics } },
  { path: AppConfig.routes.projectMap, component: MapComponent,
    canActivate: [GuardService], data: { component : 'map', permission: UserPermissions.readMap } },
  { path: AppConfig.routes.projectAlarm, component: AlarmComponent,
    canActivate: [GuardService], data: { component : 'alarm', permission: UserPermissions.readAlarm } },
  { path: AppConfig.routes.projectAlarmReport, component: AlarmReportComponent,
    canActivate: [GuardService], data: { component : 'alarm', permission: UserPermissions.readAlarm } },
  { path: AppConfig.routes.projectReport, component: ReportComponent,
    canActivate: [GuardService], data: { component : 'report', permission: UserPermissions.readLighting } },
  { path: AppConfig.routes.projectLampReport, component: ReportLampComponent,
    canActivate: [GuardService], data: { component : 'report', permission: UserPermissions.readLighting } },
  { path: AppConfig.routes.projectMeterReport, component: ReportMeterComponent,
    canActivate: [GuardService], data: { component : 'report', permission: UserPermissions.readLighting } },
  { path: AppConfig.routes.projectDataReport, component: ReportDataComponent,
    canActivate: [GuardService], data: { component : 'report', permission: UserPermissions.readLighting } },
  { path: AppConfig.routes.projectNotes, component: NotesComponent,
    canActivate: [GuardService], data: { component : 'notes', permission: UserPermissions.readNote } },
  { path: AppConfig.routes.servers, component: ServerPageComponent, pathMatch: 'full',
    canActivate: [GuardService], data: { permission: UserPermissions.readServer } },
  { path: AppConfig.routes.serversNew,  component: ServerAddComponent, pathMatch: 'full',
    canActivate: [GuardService], data: { permission: UserPermissions.createServer } },
  { path: AppConfig.routes.serversView, component: ServerDetailsComponent, pathMatch: 'full',
    canActivate: [GuardService], data: { permission: UserPermissions.readServer } },
  { path: AppConfig.routes.clientProject, component: ClientProjectComponent, pathMatch: 'full',
    canActivate: [GuardService], data: { permission: UserPermissions.readServer } },  

  { path: '**', redirectTo: '/' + AppConfig.routes.error404 },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    GuardService,
  ],
})

export class AppRoutingModule { }
