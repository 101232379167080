import { Component, Input, OnChanges } from '@angular/core';

import { IMapMarker } from 'src/app/interfaces/IMapMarker';

@Component({
  selector: 'app-gmap',
  templateUrl: 'gmap.component.html',
  styleUrls: ['gmap.component.scss'],
})

export class GMapComponent implements OnChanges {
  @Input() markers: IMapMarker[];
  @Input() mainMarker: IMapMarker;
  @Input() zoom = 2;
  @Input() showInfo = true;

  markersToDisplay: IMapMarker[] = [];
  latitude = 37.81922624;
  longitude = -215.0324912;
  previousInfoWindow = null;

 myStyles =[
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
              { visibility: 'off' }
        ]
    }
];


  //view port restrictions
  countryRestriction = {
    latLngBounds: {
      east: -185.81,
      north: -21.36,
      south: -47.35,
      west: 36.28
    },
    strictBounds: false
  };

  constructor() {
  }

  ngOnChanges(changes) {
    if (this.mainMarker) {
      this.latitude = this.mainMarker.latitude;
      this.longitude = this.mainMarker.longitude;
    }
    this.initMarkers();
  }

  markerClicked(marker, infoWindow) {
    this.setMarker(infoWindow);
  }

  mapClick($event){
    this.closeWindow();
  }

  private initMarkers() {
    if (this.mainMarker) {
      this.markersToDisplay.push(this.mainMarker);
    }
    if(this.markers && this.markers.length >0) {
      this.markers.forEach(marker =>this.markersToDisplay.push(marker));
    }
  }

  private closeWindow() {
    if (this.previousInfoWindow != null ) {
      this.previousInfoWindow.close();
    }
  }

  private setMarker(infoWindow){
    if (this.previousInfoWindow) {
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;
  }
}
