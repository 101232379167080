import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';

@Injectable({
  providedIn: 'root',
})

export class MapService {
  constructor(private httpService: HttpHelperService) {
  }

  getAllSensors(projectId: string): Observable<any> {
    return this.httpService.getAllSensors(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getAllSensors', []);
        return throwError(err);
      }),
    );
  }
}
