<app-project-header icon="fas fa-lightbulb" heading="Lights" [project]="project"></app-project-header>

<div class="row">
    <div class="column" style="width: 100px;">

    </div>
    <div class="column" >
      <div [ngSwitch]="project.name">
        <div *ngSwitchCase="'VicRoads'">
           <iframe  width="900" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiZTg2YzJmMjYtODM5ZS00MzNjLTg1NTgtZDg1MjI4ZjdkOTQxIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe>
        </div>
        <div *ngSwitchCase="'Anti Theft Install'">
          <iframe width="900" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiZTk0ZmVlYjQtOWYzZi00NDBmLWE3ODMtMmZlNGI3NTQ0MWY0IiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe>
        </div>
        <div *ngSwitchCase="'City of Salisbury'">
          <iframe width="900" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiYjVmZTllNzYtY2VjNS00Nzg1LWI5NDctOTRmM2U1YmUzZWYwIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe>
        </div>
        <div *ngSwitchCase="'Gateway South - DPTI Road Lighting'">
          <iframe width="900" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiY2QzMWFiOGUtMjJiZi00NjJkLWExNDItODE1MDA0Njg3YzRjIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe>
        </div>
        <div *ngSwitchCase="'Surfers Paradise'">
          <iframe width="900" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiMDc0MDlhMWYtNTk4OS00ODAwLThiY2ItNDQ0MWQ0NzAyNzIwIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9&pageName=ReportSection9c99a854066d984d1b68" frameborder="5" allowFullScreen="true"></iframe>
        </div>
        <div *ngSwitchDefault>This is Default</div>
      </div>  
    </div>  
    <div class="column">
      <div class="map-container">
        <app-gmap [markers]="markers" [mainMarker]="mainMarker" [zoom]=4></app-gmap>
      </div>
    </div>
</div>

