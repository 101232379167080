<div class="row banner">
  <div class="col-md-4">
  </div>

  <div class="col-md-4">
    <div class="home-container">
      <app-card heading="Hi, Please login to continue." *ngIf="!auth.loggedIn">
        <div card-body>
          <button mat-raised-button id="home-login-button" color="primary" (click)="auth.login()">Log In</button>
          <hr>

          <app-link name="Contact us" url="appConfig.routes.contact"></app-link>
          <app-link name="FAQ" url="appConfig.routes.faq"></app-link>
          <app-link name="T&Cs" url="appConfig.routes.terms"></app-link>
          <app-link name="Privacy policy" url="appConfig.routes.privacy"></app-link>
        </div>
      </app-card>

      <app-card heading="Welcome {{auth.user.name}}!" *ngIf="auth.loggedIn">
        <div card-body>
          <!--app-link name="View all your projects" url="/projects/"></app-link-->
          <app-link name="View all your projects" url="/clients/"></app-link>
          <app-link name="Admin" url="/projects/"></app-link> 
                                                         
          <app-link name="Contact us" [url]="appConfig.routes.contact"></app-link>
          <app-link name="FAQ" [url]="appConfig.routes.faq"></app-link>
          <app-link name="T&Cs" [url]="appConfig.routes.terms"></app-link>
          <app-link name="Privacy policy" [url]="appConfig.routes.privacy"></app-link>
        </div>
      </app-card>
    </div>
  </div>

  <div class="col-md-4">
    <div class="home-container">
      <app-home-images></app-home-images>
    </div>
  </div>
</div>
