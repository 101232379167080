<app-project-header icon="fas fa-tachometer-alt" heading="VAS" [project]="project"></app-project-header>

   <!--div class="row" *ngIf="showHide;else other_content">
    <div class="column" style="width: 20px;"></div-->

    <div [ngSwitch]="project.name">
      <div class="row" *ngSwitchCase="'City Of Logan - Vehicle Activated Sign'">
        <div class="column" style="width: 20px;"></div>

        <div class="column">
             <iframe  width="900" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiOTAwZjg0ODgtOWFjMi00MWNlLWJlNTMtNmUzYzNkNjRkN2RjIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe>
        </div>
       <div class="column">
        <div class="map-container">
            <app-gmap [markers]="markers" [mainMarker]="mainMarker" [zoom]=4></app-gmap>
        </div>
      </div>
 </div>
 <div class="row" *ngSwitchCase="'Coates Hire Queensland'">
  <div class="column" style="width: 20px;"></div>

  <div class="column">
      <iframe width="900" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiNDk4MTU4ZmItM2I5OS00ZWRhLTlhOTMtMDQyZGM0MTk0NDAwIiwidCI6ImRlY2Q4MzI2LWNhZmQtNGQ4MC1iYTQ2LTdkOTRiN2EwODA1ZiJ9" frameborder="5" allowFullScreen="true"></iframe>
    </div>
  <div class="column">
    <div class="map-container">
       <app-gmap [markers]="markers1" [mainMarker]="mainMarker1" [zoom]=4></app-gmap>
    </div>
  </div>
</div>
  <div *ngSwitchCase="'5'">Five</div>
  <div *ngSwitchDefault>
    <div class="container">
      <div class="row error-container">
        <div class="col-md-4">
          <h1 class="error-sign">
            <i class="fas fa-ban"></i>
          </h1>
        </div>
        <div class="col-md-8">
          <p class="title">Contact administrator. <small class="sub-title">You don't have the sufficient permission.</small></p>
    
          <div class="row">
            <div class="col-md-6">
              <app-card heading="Contact us">
                <div card-body>
                  <div class="contact-container">
                    <a href="tel:+61394300222" class="contact"><i class="fas fa-phone"></i> Phone </a>
                    <a href="mailto:development@trafficltd.com.au" class="contact"><i class="fas fa-envelope"></i> E-mail </a>
                  </div>
    
                </div>
              </app-card>
            </div>
    
            <div class="col-md-6">
              <app-card heading="Other useful links">
                <div card-body>
                  <app-link name="Go home" url="/"></app-link>
                </div>
              </app-card>
            </div>
          </div>
    
          <app-button-panel>
            <div buttons>
              <app-back-button></app-back-button>
            </div>
          </app-button-panel>
        </div>
      </div>
    </div>
    </div>
</div>
<!--/div>
 <ng-template class="center" #other_content><div style="width: 20px;"></div> Please Contact TSTsupport ph: (03) 9430 0222 , email: TSTsupport@trafficltd.com.au to gain access to the Vehicle Activated Sign project</ng-template-->
