import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/Project';
import { ProjectService } from 'src/app/components/project/project.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-ttms',
  templateUrl: './ttms.component.html',
  styleUrls: ['./ttms.component.scss']
})
export class TTMSComponent implements OnInit {

  project: Project = null;
  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private toastService: ToastService
    ) { }

  ngOnInit(): void {
    this.fetchProject();
  }
  
  private fetchProject() {
    this.projectService.getProject(this.route.snapshot.paramMap.get('id')).subscribe(
      (project) => {
        this.project = project;
        if(this.project.isFonda){
        //this.fetchStations(this.project.id);
        //this.fetchLampConnected(this.project.id);
        //this.fetchLamps(this.project.id);
        console.log(this.project.serverName);
        console.log(this.project.name);
        
         }
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

}
