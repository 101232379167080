/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';

@Injectable({
  providedIn: 'root',
})

export class ChartService {
  private static colourGreen = 'green';
  private static colourOlive = 'olive';
  private static colourBlue = 'navy';
  private static colourBlueStale = 'slateblue';
  private static colourOrange = 'orange';
  private static colourRed = 'red';

  private static barChart: ChartType = 'bar';
  private static lineChart: ChartType = 'line';
  private static legendTrue = true;

  constructor() {
  }

  private static barOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  private static multiBarOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
        },
      ],
  },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  private static lineOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
          stacked: true,
      }],
    },
  };

  static greenChart = {
    backgroundColor: ChartService.colourGreen,
    hoverBackgroundColor: ChartService.colourOlive,
    hoverBorderColor: ChartService.colourGreen,
  };

  static oliveChart = {
    backgroundColor: ChartService.colourOlive,
    hoverBackgroundColor: ChartService.colourGreen,
    hoverBorderColor: ChartService.colourOlive,
  };

  static blueChart = {
    backgroundColor: ChartService.colourBlue,
    hoverBackgroundColor: ChartService.colourBlueStale,
    hoverBorderColor: ChartService.colourBlue,
  };

  static blueStaleChart = {
    backgroundColor: ChartService.colourBlueStale,
    hoverBackgroundColor: ChartService.colourBlue,
    hoverBorderColor: ChartService.colourBlueStale,
  };

  static redChart = {
    backgroundColor: ChartService.colourRed,
    hoverBackgroundColor: ChartService.colourOrange,
    hoverBorderColor: ChartService.colourRed,
  };

  static orangeChart = {
    backgroundColor: ChartService.colourOrange,
    hoverBackgroundColor: ChartService.colourRed,
    hoverBorderColor: ChartService.colourOrange,
  };

  static greenLineChart = {
    backgroundColor: ChartService.colourGreen,
    borderColor: ChartService.colourGreen,
    hoverBackgroundColor: ChartService.colourGreen,
    hoverBorderColor: ChartService.colourOlive,
    pointBackgroundColor: ChartService.colourGreen,
    pointBorderColor: ChartService.colourGreen,
    fill: false,
  };

  static barChartOptions = {
    options: ChartService.barOptions,
    legend: ChartService.legendTrue,
    type: ChartService.barChart,
  };

  static multibarChartOptions = {
    options: ChartService.multiBarOptions,
    legend: ChartService.legendTrue,
    type: ChartService.barChart,
  };

  static lineChartOptions = {
    options: ChartService.lineOptions,
    legend: ChartService.legendTrue,
    type: ChartService.lineChart,
  };
}
