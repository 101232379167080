import { Observable } from 'rxjs';
import { Device } from './../../models/Device';
import { Injectable } from '@angular/core';

import { IMapMarker } from 'src/app/interfaces/IMapMarker';
import { Pole } from 'src/app/models/Pole';
import { Project } from 'src/app/models/Project';
import { Station } from 'src/app/models/Station';
import { HttpHelperService } from 'src/app/shared/services/http.service';


@Injectable({
  providedIn: 'root',
})

export class MarkerService {

  device: Observable<Device[]>;
  private defaultIconUrl = 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2';
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public blueIcon = `${this.defaultIconUrl}|007BFF`;

  private greenIcon = `${this.defaultIconUrl}|28A745`;
  private defaultIcon = this.greenIcon;
  private lightingIcon = `${this.defaultIconUrl}|FFC107`;
  private environmentIcon = `${this.defaultIconUrl}|28A745`;
  private monitoringIcon = `${this.defaultIconUrl}|007BFF`;


  constructor(private httpService: HttpHelperService) {
  }

  generateMarker(name, latitude, longitude, item, icon = this.defaultIcon): IMapMarker {
    return { name, latitude, longitude, item, icon } as IMapMarker;
  }

  generateMarkerLighting(name, latitude, longitude, item, icon = this.lightingIcon): IMapMarker {
    return { name, latitude, longitude, item, icon } as IMapMarker;
  }

  generateProjectMarker(project: Project): IMapMarker {
    const icon = 'assets/images/marker/marker-project.png';
    return this.generateMarker(project.name, project.latitude, project.longitude, project, icon);
  }

  generateStationMarker(list, type='Lighting'): IMapMarker[] {
    return this.generateMarkerFromList(list, type)
      .map((marker) => {
        marker.icon = this.lightingIcon;
        return marker;
      });
  }


  generatePoleMarker(list: Pole[]): IMapMarker[] {
    return this.generateMarkerFromList(list, 'Lighting')
      .map((marker) => {
        marker.icon = this.lightingIcon;
        return marker;
      });
  }

  generateLightMarker(list: Pole[]): IMapMarker[] {
    return this.generateMarkerFromList(list, 'Lighting')
      .map((marker) => {
        marker.icon = 'assets/images/sensors/light.png';
        return marker;
      });
  }

  generateStationstMarker(list: Pole[]): IMapMarker[] {
    return this.generateMarkerFromList(list, 'Lighting')
      .map((marker) => {
        marker.icon = 'assets/images/sensors/Station.png';
        return marker;
      });
  }

  generateImonitorMarker(list: Device[]): IMapMarker[] {
    return this.generateMarkerFromList(list, 'Impact')
      .map((marker) => {
        marker.icon = this.monitoringIcon;
        return marker;
      });
  }

  generateImpactMarker(deviceId: string): IMapMarker[]{
    return this.generateMarkerForImpactAlarmL(deviceId);
  }

  generateEnvironmentMarker(list): IMapMarker[] {
    return this.generateMarkerFromList(list, 'Environment')
      .map((marker) => {
        marker.icon = this.environmentIcon;
        return marker;
      });
  }

  generateMonitoringMarker(list): IMapMarker[] {
    return this.generateMarkerFromList(list, 'Monitoring')
      .map((marker) => {
        marker.icon = this.monitoringIcon;
        return marker;
      });
  }

  generateDeviceMarker(list): IMapMarker[] {
    const markers: IMapMarker[] = new Array();
    list.forEach((item) => {
      markers.push(this.generateMarker(item.name, item.latitude, item.longitude, item, `assets/images/marker/${item.iconName}`));
    });
    return markers;
  }

  private  generateMarkerFromList(list, type) {
    const markers: IMapMarker[] = new Array();
    list.forEach((item) => {
      markers.push(this.generateMarker(item.name, item.latitude, item.longitude, item));
    });
    markers.map((marker) => {
      marker.type = type;
      return marker;
    });
    return markers;
  }


  private  generateMarkerForImpactAlarmL(deviceId: string) {
    const markers: IMapMarker[] = new Array();
     this.device = this.httpService.getImpactMonitorDevice(deviceId);
    this.device.forEach((item) => {
      markers.push(this.generateMarker('impactAlarm', item[0].latitude, item[0].longitude, item));
    });
    markers.map((marker) => {
      marker.type = 'ImpactAlarm';
      return marker;
    });
    return markers;
  }

}
