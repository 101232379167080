import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { Project } from 'src/app/models/Project';
import { ChartService } from 'src/app/shared/services/chart.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AlarmService } from '../alarm/alarm.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})

export class ProjectDetailsComponent implements OnInit {
  project: Project = null;
  lightingGraphDetails = null;
  alarmList = null;
  lampGroups = null;
  breakerGroups = null;

  energyLow;
  energyHigh;

  alarmCountPerMonth = null;
  alarmCountPerMonthData;
  alarmCountPerMonthLabel;

  alarmCountPerDay = null;
  alarmCountPerDayData;
  alarmCountPerDayLabel;

  alarmCountPerHour = null;
  alarmCountPerHourData;
  alarmCountPerHourLabel;

  energyUsagePerMonth = null;
  energyUsagePerMonthData;
  energyUsagePerMonthLabel;

  energyUsagePerYear = null;
  energyUsagePerYearData;
  energyUsagePerYearLabel;

  barOptions = ChartService.barChartOptions;

  constructor(
    public projectService: ProjectService,
    private alarmService: AlarmService,
    private route: ActivatedRoute,
    private toastService: ToastService) {
      this.alarmService.itemPerPage = 5;
  }

  ngOnInit() {
    const projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(projectId);
  }

  getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
        if (project.isFonda) {
          this.getLightingSensorGraphs(project.id);
          this.getEnergyPerMonth(project.id);
          this.getEnergyPerYear(project.id);
          this.getRecentAlarms(project.id);
          this.getLampGroups(project.id);
          this.getBreakerGroups(project.id);
          this.getEnergyLow(project.id);
          this.getEnergyHigh(project.id);
        }
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  getRecentAlarms(projectId: string) {
    this.alarmService.getAlarmsWithPage(projectId).subscribe(
      (alarms) => {
        this.alarmList = alarms;
        this.toastService.showEmptyResponse('No alarms', alarms.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch alarms');
      },
    );
  }

  getLightingSensorGraphs(projectId: string) {
    this.projectService.getLightingSensorGraphs(projectId).subscribe(
      (lightingGraphDetails) => {
        this.lightingGraphDetails = lightingGraphDetails;

        this.alarmCountPerMonth = lightingGraphDetails.month;
        this.alarmCountPerMonthLabel = this.alarmCountPerMonth.labels;
        this.alarmCountPerMonthData = [{
          data: this.alarmCountPerMonth.values,
          label: 'Alarms per month',
          ...ChartService.blueStaleChart,
        }];

        this.alarmCountPerDay = lightingGraphDetails.day;
        this.alarmCountPerDayLabel = this.alarmCountPerDay.labels;
        this.alarmCountPerDayData = [{
          data: this.alarmCountPerDay.values,
          label: 'Alarms per day',
          ...ChartService.blueChart,
        }];

        this.alarmCountPerHour = lightingGraphDetails.hour;
        this.alarmCountPerHourLabel = this.alarmCountPerHour.labels;
        this.alarmCountPerHourData = [{
          data: this.alarmCountPerHour.values,
          label: 'Alarms per hour',
          ...ChartService.redChart,
        }];
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to update lighting graph.');
      },
    );
  }

  getEnergyPerMonth(projectId: string) {
    this.projectService.getEnergyPerMonth(projectId).subscribe(
      (energyUsagePerMonth) => {
        this.energyUsagePerMonth = energyUsagePerMonth;
        this.energyUsagePerMonthLabel = energyUsagePerMonth.labels;
        this.energyUsagePerMonthData = [{
          data: this.energyUsagePerMonth.values,
          label: 'Energy usage per day (kW)',
          ...ChartService.greenChart,
        }];
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to update energy usage per month.');
      },
    );
  }

  getEnergyPerYear(projectId: string) {
    this.projectService.getEnergyPerYear(projectId).subscribe(
      (energyUsagePerYear) => {
        this.energyUsagePerYear = energyUsagePerYear;
        this.energyUsagePerYearLabel = energyUsagePerYear.labels;
        this.energyUsagePerYearData = [{
          data: this.energyUsagePerYear.values,
          label: 'Energy usage per month (kW)',
          ...ChartService.oliveChart,
        }];
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to update energy usage per year.');
      },
    );
  }

  getLampGroups(projectId: string) {
    this.projectService.getLampGroups(projectId).subscribe(
      (lamps) => {
        this.lampGroups = lamps;
        this.toastService.showEmptyResponse('No lamps', lamps.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch lamps');
      },
    );
  }

  getBreakerGroups(projectId: string) {
    this.projectService.getBreakerGroups(projectId).subscribe(
      (breaker) => {
        this.breakerGroups = breaker;
        this.toastService.showEmptyResponse('No breakers', breaker.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch breakers');
      },
    );
  }

  getEnergyLow(projectId: string) {
    this.projectService.getEnergyLow(projectId).subscribe(
      (low) => {
        this.energyLow = low;
        this.toastService.showEmptyResponse('No low energy consumption components', low.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch energy consumption components');
      },
    );
  }

  getEnergyHigh(projectId: string) {
    this.projectService.getEnergyHigh(projectId).subscribe(
      (high) => {
        this.energyHigh = high;
        this.toastService.showEmptyResponse('No high energy consumption components', high.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch energy consumption components');
      },
    );
  }

  refreshProject() {
    this.project = null;
    this.projectService.project = null;
    this.getProject(this.route.snapshot.paramMap.get('id'));
  }
}
