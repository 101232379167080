import { Device } from './../../models/Device';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfig } from 'src/app/configs/app.config';
import { Server } from 'src/app/models/Server';

@Injectable({
  providedIn: 'root',
})

export class HttpHelperService {
  private projectEndpoint = AppConfig.endpoints.projectEndpoint;
  private clientProjectEndpoint = AppConfig.endpoints.clientProjectEndpoint;
  private dataSensorEndpoint = AppConfig.endpoints.dataSensorEndpoint;
  private lampEndpoint = AppConfig.endpoints.lampEndpoint;
  private lampDbEndpoint = AppConfig.endpoints.lampDbEndpoint;
  private stationEndpoint = AppConfig.endpoints.stationEndpoint;
  private stationDbEndpoint = AppConfig.endpoints.stationDbEndpoint;
  private lcuEndpoint = AppConfig.endpoints.lcuEndpoint;
  private diEndpoint = AppConfig.endpoints.diEndpoint;
  private meterEndpoint = AppConfig.endpoints.meterEndpoint;
  private antiTheftEndpoint = AppConfig.endpoints.antiTheftEndpoint;
  private rtuEndpoint = AppConfig.endpoints.rtuEndpoint;
  private luxEndpoint = AppConfig.endpoints.luxEndpoint;
  private cameraEndpoint = AppConfig.endpoints.cameraEndpoint;
  private sensorEndpoint = AppConfig.endpoints.sensorEndpoint;
  private serverEndpoint = AppConfig.endpoints.serverEndpoint;
  private alarmEndpoint = AppConfig.endpoints.alarmEndpoint;
  private impactAlarmEndpoint = AppConfig.endpoints.impactAlarmEndpoint;
  private noteEndpoint = AppConfig.endpoints.notes;
  private reportEndpoint = AppConfig.endpoints.reportEndpoint;
  private lampReportEndpoint = AppConfig.endpoints.lampReportEndpoint;
  private dataReportEndpoint = AppConfig.endpoints.dataReportEndpoint;
  private meterReportEndpoint = AppConfig.endpoints.meterReportEndpoint;
  private poleEndpoint = AppConfig.endpoints.poleEndpoint;
  private lightingGraphEndpoint = AppConfig.endpoints.lightingGraphEndpoint;
  private environmentSensorEndpoint = AppConfig.endpoints.environmentSensorEndpoint;
  private monitoringSensorEndpoint = AppConfig.endpoints.monitoringSensorEndpoint;
  private energyPerMonth = AppConfig.endpoints.energyPerMonth;
  private energyPerYear = AppConfig.endpoints.energyPerYear;
  private energyLow = AppConfig.endpoints.energyLow;
  private energyHigh = AppConfig.endpoints.energyHigh;
  private mapItemEndpoint = AppConfig.endpoints.mapItem;
  private groupEndpoints = AppConfig.endpoints.group;
  private telstraMonitoringSensorEndpoint = AppConfig.endpoints.device;

  constructor(private http: HttpClient) {
  }

  // Project
  addProject(project) {
    return this.http.post(this.projectEndpoint, project);
  }

  getProjects() {
    return this.http.get(this.projectEndpoint);
  }

  getProject(id: string) {
    return this.http.get(`${this.projectEndpoint}/${id}`);
  }
  
  getClientProjects(clientName: string) {
    return this.http.get(`${this.clientProjectEndpoint}/${clientName}`);
  }

  syncProjects() {
    return this.http.get(`${this.projectEndpoint}/sync`);
  }

  syncProject(id: string) {
    return this.http.get(`${this.projectEndpoint}/sync/${id}`);
  }

  // Group Info
  getGroupLampItems(id: string) {
    return this.http.get(`${this.groupEndpoints}/lamp/${id}`);
  }

  getGroupBreakerItems(id: string) {
    return this.http.get(`${this.groupEndpoints}/breaker/${id}`);
  }


  // Map Items
  getStationMapItems(id: string) {
    return this.http.get(`${this.mapItemEndpoint}/station/${id}`);
  }

  getLampMapItems(id: string) {
    return this.http.get(`${this.mapItemEndpoint}/lamp/${id}`);
  }

  getCameraMapItems(id: string) {
    return this.http.get(`${this.mapItemEndpoint}/camera/${id}`);
  }

  getAntiTheftMapItems(id: string) {
    return this.http.get(`${this.mapItemEndpoint}/anti-theft/${id}`);
  }

  // Stations
  getStations(id: string) {
    return this.http.get(`${this.stationEndpoint}/${id}`);
  }
  
  getStationsDb(server: string, name: string) {
    if(server=== 'VIC Roads Server'){
      server = 'FondaStationsDetail_cams';
      return this.http.get(`${this.stationDbEndpoint}/${server}/${name}`);
    }
    if(server=== 'T2'){
      server = 'FondaStationsDetail_t2';
      return this.http.get(`${this.stationDbEndpoint}/${server}/${name}`);
    }
  }

  getStation(id: string, stationId: string) {
    return this.http.get(`${this.stationEndpoint}/${id}/${stationId}`);
  }

  getStationImages(id: string, stationId: string) {
    return this.http.get(`${this.stationEndpoint}/photos/${id}/${stationId}`);
  }

  // Lamps
  getLamp(id: string, lampId: string) {
    return this.http.get(`${this.lampEndpoint}/${id}/${lampId}`);
  }

  getLampImages(id: string, lampId: string) {
    return this.http.get(`${this.lampEndpoint}/photos/${id}/${lampId}`);
  }

  getLamps(id: string) {
    return this.http.get(`${this.lampEndpoint}/${id}`);
  }

  getLampsConnected(id: string) {
    return this.http.get(`${this.lampEndpoint}/connected/${id}`);
  }
  getLampsDbConnected(server: string, id: string) {
    if(server=== 'VIC Roads Server'){
      server = 'LampConnected_cams';
      return this.http.get(`${this.lampDbEndpoint}/${server}/${id}`);
    }
    if(server=== 'T2'){
      server = 'LampConnected_t2';
      return this.http.get(`${this.lampDbEndpoint}/${server}/${id}`);
    }
  }
  getLampsOnStation(id: string, stationId: string) {
    return this.http.get(`${this.lampEndpoint}/station/${id}/${stationId}`);
  }

  // LCU
  getLCU(id: string) {
    return this.http.get(`${this.lcuEndpoint}/${id}`);
  }

  getLCUsConnected(id: string) {
    return this.http.get(`${this.lcuEndpoint}/connected/${id}`);
  }

  getLCUsOnStation(id: string, stationId: string) {
    return this.http.get(`${this.lcuEndpoint}/station/${id}/${stationId}`);
  }

  // DI
  getDIs(id: string) {
    return this.http.get(`${this.diEndpoint}/${id}`);
  }

  getDIsConnected(id: string) {
    return this.http.get(`${this.diEndpoint}/connected/${id}`);
  }

  getDIsOnStation(id: string, stationId: string) {
    return this.http.get(`${this.diEndpoint}/station/${id}/${stationId}`);
  }

  // Meters
  getMeters(id: string) {
    return this.http.get(`${this.meterEndpoint}/${id}`);
  }

  getMetersConnected(id: string) {
    return this.http.get(`${this.meterEndpoint}/connected/${id}`);
  }

  getMeterDetails(id: string, stationId: string) {
    return this.http.get(`${this.meterEndpoint}/details/${id}/${stationId}`);
  }

  // Anti-Theft
  getAntiThefts(id: string) {
    return this.http.get(`${this.antiTheftEndpoint}/${id}`);
  }

  getAntiTheftConnected(id: string) {
    return this.http.get(`${this.antiTheftEndpoint}/connected/${id}`);
  }

  getAntiTheftsOnStation(id: string, stationId: string) {
    return this.http.get(`${this.antiTheftEndpoint}/station/${id}/${stationId}`);
  }

  // RTU
  getRTUs(id: string) {
    return this.http.get(`${this.rtuEndpoint}/${id}`);
  }

  getRTUConnected(id: string) {
    return this.http.get(`${this.rtuEndpoint}/connected/${id}`);
  }

  getRTUDetails(id: string, stationId: string) {
    return this.http.get(`${this.rtuEndpoint}/details/${id}/${stationId}`);
  }

  // Lux
  getLuxs(id: string) {
    return this.http.get(`${this.luxEndpoint}/${id}`);
  }

  getLuxConnected(id: string) {
    return this.http.get(`${this.luxEndpoint}/connected/${id}`);
  }

  getLuxDetails(id: string, stationId: string) {
    return this.http.get(`${this.luxEndpoint}/details/${id}/${stationId}`);
  }

  // Camera
  getCameras(id: string) {
    return this.http.get(`${this.cameraEndpoint}/${id}`);
  }

  getCameraConnected(id: string) {
    return this.http.get(`${this.cameraEndpoint}/connected/${id}`);
  }

  // Notes
  getNotes(id: string) {
    return this.http.get(`${this.noteEndpoint}/${id}`);
  }

  addNote(note) {
    return this.http.post(this.noteEndpoint, note);
  }

  deleteNote(id: string) {
    return this.http.delete(`${this.noteEndpoint}/${id}`);
  }

  // Server
  getServers() {
    return this.http.get(this.serverEndpoint);
  }

  getServer(id: string) {
    return this.http.get(`${this.serverEndpoint}/${id}`);
  }

  addServer(server: Server) {
    return this.http.post(this.serverEndpoint, server);
  }

  deleteServer(id: string) {
    return this.http.delete(`${this.serverEndpoint}/${id}`);
  }

  // Poles
  getPoles(projectId: string, stationId: string) {
    return this.http.get(`${this.poleEndpoint}/${projectId}/${stationId}`);
  }

  // Alarms
  getAlarms(id: string, history: boolean) {
    let url = this.alarmEndpoint;
    if (history) {
      url = `${this.alarmEndpoint}/history`;
    }

    return this.http.get(`${url}/${id}`);
  }

  getAlarmsWithPage(id: string, page: number, count: number, history: boolean) {
    let url = this.alarmEndpoint;
    if (history) {
      url = `${this.alarmEndpoint}/history`;
    }

    return this.http.get(`${url}/${id}/${page}/${count}`);
  }

  getImpactAlarmsWithPage(){
    return this.http.get(this.impactAlarmEndpoint);
  }

  getAlarmReport(id: string, page: number, count: number, from: string, to: string) {
    return this.http.get(`${this.alarmEndpoint}/report/${id}/${page}/${count}/${from}/${to}`);
  }

  // Energy
  getEnergyPerMonth(id: string) {
    return this.http.get(`${this.energyPerMonth}/${id}`);
  }

  getEnergyPerYear(id: string) {
    return this.http.get(`${this.energyPerYear}/${id}`);
  }

  getEnergyLow(id: string) {
    return this.http.get(`${this.energyLow}/${id}`);
  }

  getEnergyHigh(id: string) {
    return this.http.get(`${this.energyHigh}/${id}`);
  }

  // Dashboard
  getLightingSensorGraphs(id: string) {
    return this.http.get(`${this.lightingGraphEndpoint}/${id}`);
  }

  getEnvironmentSensors(id: string) {
    return this.http.get(`${this.environmentSensorEndpoint}/${id}`);
  }

  getTelstraMonitoringSensors(){
    return this.http.get(`${this.telstraMonitoringSensorEndpoint}`);
  }

  getImpactMonitorDevice(deviceId: string){
    return this.http.get<Device[]>(`${this.telstraMonitoringSensorEndpoint}/${deviceId}`);
  }

  getMonitoringSensors(id: string) {
    return this.http.get(`${this.monitoringSensorEndpoint}/${id}`);
  }

  // Sensor
  getAllSensors(id: string) {
    return this.http.get(`${this.sensorEndpoint}/${id}`);
  }

  // DataSensor
  getDataSensors(id: string) {
    return this.http.get(`${this.dataSensorEndpoint}/${id}`);
  }

  // Report
  getDataReportDevices(id: string) {
    return this.http.get(`${this.reportEndpoint}/data/${id}`);
  }

  getLampReportDevices(id: string, stationId: string) {
    return this.http.get(`${this.reportEndpoint}/lamp/${id}/${stationId}`);
  }

  getMeterReportDevices(id: string, stationId: string) {
    return this.http.get(`${this.reportEndpoint}/meter/${id}/${stationId}`);
  }

  getLampReport(projectId: string, lampId: string, fromDate: string, toDate: string) {
    return this.http.get(`${this.lampReportEndpoint}/${projectId}/${lampId}/${fromDate}/${toDate}`);
  }

  getDataReport(projectId: string, stationId: string, fromDate: string, toDate: string) {
    return this.http.get(`${this.dataReportEndpoint}/${projectId}/${stationId}/${fromDate}/${toDate}`);
  }

  getMeterReport(phase: string, projectId: string, meterId: string, fromDate: string, toDate: string) {
    return this.http.get(`${this.meterReportEndpoint}/${phase}/${projectId}/${meterId}/${fromDate}/${toDate}`);
  }
}
