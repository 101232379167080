import { Device } from './../../../../models/Device';
import { map } from 'rxjs/operators';
import { ImpactAlarm } from './../../../../models/ImpactAlarm';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { Alarm } from 'src/app/models/Alarm';
import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';
import { AlarmService } from './alarm.service';
import { DateService } from 'src/app/shared/services/date.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss'],
})

export class AlarmComponent implements OnInit {
  alarmList;
  allAlarms: Alarm[] = new Array();
  allImpactAlarms: ImpactAlarm[] = new Array();
  userFilter = '';
  project: Project;
  device: Observable<Device[]>;
  deviceFriendlyName = 'iMonitor one';

  constructor(
    private alarmService: AlarmService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    ) {
    this.alarmService.itemPerPage = 100;
  }

  ngOnInit() {
    this.getImpactAlarms();
    const projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(projectId);
    this.getAlarmsAndDetails(this.project.name);
   // this.getImpactAlarms();
  }

  getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  getAlarmsWithMeta(projectId: string) {
    this.alarmService.getAlarmsWithMeta(projectId).subscribe(
      (alarms) => {
        this.toastService.showEmptyResponse('No alarms', alarms.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch alarms');
      },
    );
  }

  getAlarmsWithPage(projectId: string) {
    this.alarmService.getAlarmsWithPage(projectId).subscribe(
      (alarms) => {
        this.allAlarms = alarms;
        this.refreshList();
        this.toastService.showEmptyResponse('No alarms', alarms.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch alarms');
      },
    );
  }

  getAlarm(value) {
    const projectId = this.route.snapshot.paramMap.get('id');
    this.getAlarmsWithPage(projectId);
  }

  filterAlarms(value) {
    this.userFilter = value.toLowerCase();
    this.refreshList();
  }

  getAlarmsAndDetails(projectName: string) {
    const projectId = this.route.snapshot.paramMap.get('id');
    if (projectName === 'iMonitor') {
       this.getImpactAlarms();
    } else {
      this.getAlarmsWithMeta(projectId);
      this.getAlarmsWithPage(projectId);
    }

  }

  getImpactAlarms(){
    this.alarmService.getImpactAlarmsWithPage().subscribe(
      (alarms) => {
        this.allImpactAlarms = alarms.results;
        for (const a of this.allImpactAlarms){
         a.createdAt = this.getDate(a.createdAt);
         a.sensorName = this.getMessage(a.sensorName);
         a.deviceFriendlyName = this.alarmService.getDeviceFriendlyName(a.deviceId);
         a.deleted = 'detection';
        }
        this.refreshList();
        this.toastService.showEmptyResponse('No Impact alarms', alarms.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch Impact alarms');
      },
    );
  }

  getDate(dateTimeString) {
    return new DateService().fullDateWithTime(dateTimeString);
  }
  getMessage(sensorName){
    if(sensorName === 'HighAccelerationCount') {
      return 'Impact Detected';
    }
    else {
      return sensorName;
    }
  }

  private refreshList() {
    if(this.allAlarms?.length >0 ) {
      this.alarmList = Array.from(this.allAlarms).filter((alarm) =>
        alarm.alarmType.toLowerCase().includes(this.userFilter) ||
        alarm.site.toLowerCase().includes(this.userFilter) ||
        alarm.message.toLowerCase().includes(this.userFilter) ||
        alarm.alarmLevel.toLowerCase().includes(this.userFilter)) as Alarm[];
    }
    if(this.project.name === 'iMonitor' ){
    this.alarmList = Array.from(this.allImpactAlarms).filter((impactAlarm) =>
    impactAlarm.sensorValue !== '0') as ImpactAlarm[];
  }
  }

}
