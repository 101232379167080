import { NgModule } from '@angular/core';

import { ProjectModule } from 'src/app/components/project/project.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { MapToolsComponent } from './components/map-tools/map-tools.component';
import { MapComponent } from './map.component';

@NgModule({
  imports: [
    SharedModule,
    ProjectModule,
  ],
  declarations: [
    MapComponent,
    MapToolsComponent,
  ],
})

export class MapModule {
}
