import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpHelperService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})

export class ClearsonicsService {
  clearsonicsDetails: Observable<any>;

  constructor(private httpService: HttpHelperService) {
  }
}
