import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TemperatureInfoComponent } from '../temperature-info/temperature-info.component';

@Component({
  selector: 'app-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss'],
})

export class TemperatureComponent implements OnInit {
  @Input() temperature: any;
  image = 'assets/images/sensors/temp.png';
  maxTemp = null;
  noTemp = true;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.temperature.details.maxTemp > 0) {
      const temp = Number(this.temperature.details.maxTemp).toFixed(2);
      this.maxTemp = `Max Temp: ${temp}°C`;
      this.noTemp = false;
    } else {
      this.maxTemp = 'No data available';
    }
  }

  onSensorClicked(sensor) {
    const dialogRef = this.dialog.open(
      TemperatureInfoComponent,
      {
        width: '100rem',
        data:
        {
          sensor: sensor.details.latestValue,
          sensorData: sensor.details.data,
          name: sensor.name,
        },
      });
  }
}
