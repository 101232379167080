import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';

@Injectable({
  providedIn: 'root',
})

export class LightingService {
  lightingDetails: Observable<any>;

  constructor(private httpService: HttpHelperService) {
  }

  getStationMapItems(projectId: string): Observable<any> {
    return this.httpService.getStationMapItems(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getStationMapItems', []);
        return throwError(err);
      }),
    );
  }

  getStations(projectId: string): Observable<any> {
    return this.httpService.getStations(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getStations', []);
        return throwError(err);
      }),
    );
  }

  getDbStations(serverName:string, projectName: string): Observable<any> {
    return this.httpService.getStationsDb(serverName, projectName)
    .pipe(
      catchError((err) => {
        LogService.handleError('getStations', []);
        return throwError(err);
      }),
    );
  }

  getStation(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getStation(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getStation', []);
        return throwError(err);
      }),
    );
  }

  getStationImages(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getStationImages(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getStationImages', []);
        return throwError(err);
      }),
    );
  }

  getLampMapItems(projectId: string): Observable<any> {
    return this.httpService.getLampMapItems(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLampMapItems', []);
        return throwError(err);
      }),
    );
  }

  getLamp(projectId: string, lampId: string): Observable<any> {
    return this.httpService.getLamp(projectId, lampId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLamp', []);
        return throwError(err);
      }),
    );
  }

  getLampImages(projectId: string, lampId: string): Observable<any> {
    return this.httpService.getLampImages(projectId, lampId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLampImages', []);
        return throwError(err);
      }),
    );
  }

  getLamps(projectId: string): Observable<any> {
    return this.httpService.getLamps(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLamps', []);
        return throwError(err);
      }),
    );
  }

  getLampsConnected(projectId: string): Observable<any> {
    return this.httpService.getLampsConnected(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLampsConnected', []);
        return throwError(err);
      }),
    );
  }

  getLampsDbConnected(serverName: string,projectId: string): Observable<any> {
    return this.httpService.getLampsDbConnected(serverName,projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLampsDbConnected', []);
        return throwError(err);
      }),
    );
  }

  getLampsOnStation(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getLampsOnStation(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLampsOnStation', []);
        return throwError(err);
      }),
    );
  }

  getLCUs(projectId: string): Observable<any> {
    return this.httpService.getLCU(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLCUs', []);
        return throwError(err);
      }),
    );
  }

  getLCUConnected(projectId: string): Observable<any> {
    return this.httpService.getLCUsConnected(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLCUConnected', []);
        return throwError(err);
      }),
    );
  }

  getLCUsOnStation(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getLCUsOnStation(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLCUsOnStation', []);
        return throwError(err);
      }),
    );
  }

  getDIs(projectId: string): Observable<any> {
    return this.httpService.getDIs(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getDIs', []);
        return throwError(err);
      }),
    );
  }

  getDIConnected(projectId: string): Observable<any> {
    return this.httpService.getDIsConnected(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getDIConnected', []);
        return throwError(err);
      }),
    );
  }

  getDIsOnStation(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getDIsOnStation(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getDIsOnStation', []);
        return throwError(err);
      }),
    );
  }

  getMeters(projectId: string): Observable<any> {
    return this.httpService.getMeters(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getMeters', []);
        return throwError(err);
      }),
    );
  }

  getMeterConnected(projectId: string): Observable<any> {
    return this.httpService.getMetersConnected(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getMeterConnected', []);
        return throwError(err);
      }),
    );
  }

  getMeterDetails(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getMeterDetails(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getMeterDetails', []);
        return throwError(err);
      }),
    );
  }

  getAntiTheftMapItems(projectId: string): Observable<any> {
    return this.httpService.getAntiTheftMapItems(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getAntiTheftMapItems', []);
        return throwError(err);
      }),
    );
  }

  getAntiThefts(projectId: string): Observable<any> {
    return this.httpService.getAntiThefts(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getAntiThefts', []);
        return throwError(err);
      }),
    );
  }

  getAntiTheftConnected(projectId: string): Observable<any> {
    return this.httpService.getAntiTheftConnected(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getAntiTheftConnected', []);
        return throwError(err);
      }),
    );
  }

  getAntiTheftsOnStation(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getAntiTheftsOnStation(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getAntiTheftsOnStation', []);
        return throwError(err);
      }),
    );
  }

  getRTUs(projectId: string): Observable<any> {
    return this.httpService.getRTUs(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getRTUs', []);
        return throwError(err);
      }),
    );
  }

  getRTUConnected(projectId: string): Observable<any> {
    return this.httpService.getRTUConnected(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getRTUConnected', []);
        return throwError(err);
      }),
    );
  }

  getRTUDetails(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getRTUDetails(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getRTUDetails', []);
        return throwError(err);
      }),
    );
  }

  getLuxs(projectId: string): Observable<any> {
    return this.httpService.getLuxs(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLuxs', []);
        return throwError(err);
      }),
    );
  }

  getLuxConnected(projectId: string): Observable<any> {
    return this.httpService.getLuxConnected(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLuxConnected', []);
        return throwError(err);
      }),
    );
  }

  getLuxDetails(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getLuxDetails(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLuxDetails', []);
        return throwError(err);
      }),
    );
  }
}
