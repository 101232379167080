import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

import { APP_CONFIG } from 'src/app/configs/app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {
  appConfig: any;
  menuItems: any[];
  subMenuItems: any[];

  constructor(@Inject(APP_CONFIG) appConfig: any, public auth: AuthService) {
    this.appConfig = appConfig;
  }

  ngOnInit() {
    this.loadMenus();
  }

  private loadMenus(): void {
    this.menuItems = this.appConfig.mainMenu;
    this.subMenuItems = this.appConfig.subMenu;
  }
}
