import { Component, Input, OnInit } from '@angular/core';

import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-manhole-sensor',
  templateUrl: './manhole-sensor.component.html',
  styleUrls: ['./manhole-sensor.component.scss'],
})

export class ManholeSensorComponent implements OnInit {
  @Input() sensor: any;
  image = '';

  dataSource = [];
  displayedColumns: string[] = ['time', 'open'];
  constructor(private monitoringService: MonitoringService, private dateService: DateService) {
  }

  ngOnInit() {
    this.setImage(this.sensor);
    this.setData(this.sensor.details.data);
  }

  setImage(sensor) {
    this.image = this.monitoringService.getImageType(sensor);
  }

  setData(sensorData) {
   this.dataSource = this.filterChangeOfStatus(
      sensorData.map((item) => ({
          open: item.payload.open,
          time: this.dateService.fullDateWithTime(item.time),
        })),
    );
  }

  filterChangeOfStatus(arrayToFilter) {
    if (arrayToFilter.length === 0) {
      return [];
    }
    const data = [arrayToFilter[0]];
    arrayToFilter.forEach((value, index) => {
      if (value.open === arrayToFilter[index].open) {
        data.pop();
      }
      data.push(value);
    });

    return data;
  }
}
