import { Component, Input, OnInit } from '@angular/core';

import { CameraConnected } from 'src/app/models/CameraConnected';

@Component({
  selector: 'app-camera-connected',
  templateUrl: './camera-connected.component.html',
})

export class CameraConnectedComponent implements OnInit {
  @Input() camera: CameraConnected;

  constructor() {
  }

  ngOnInit() {
  }
}
