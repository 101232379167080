import { Component, Input, OnInit } from '@angular/core';

import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
})

export class SensorComponent implements OnInit {
  @Input() sensor: any;

  image = '';

  constructor(private monitoringService: MonitoringService) {
  }

  ngOnInit() {
    if(this.sensor.deviceTypeId === '10') {
     //this.image ='assets/images/sensors/pressure.png';
     this.sensor.sensorType ='impact';
    }
    this.image = this.monitoringService.getImageType(this.sensor);
  }
}
