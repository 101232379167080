<app-page-title icon="fas fa-plus" title="Add new server"></app-page-title>

<div class="container">
  <form class="row new-server" [formGroup]="server" (ngSubmit)="createNew(server)">

    <div class="col-md-6">
      <h2>Server details</h2>

      <mat-form-field appearance="outline">
        <mat-label>Server name</mat-label>
        <input matInput placeholder="Server name" type="text" formControlName="serverName">
        <mat-hint>This will be the name of the server</mat-hint>
        <mat-error *ngIf="hasError('serverName', 'required')">Server name is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Server URL</mat-label>
        <input matInput placeholder="Server URL" type="text" formControlName="serverUrl">
        <mat-hint>This should be the url of the server</mat-hint>
        <mat-error *ngIf="hasError('serverUrl', 'required')">Server URL is required</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-6">
      <h2>Server credentials </h2>

      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput placeholder="username" type="text" formControlName="username">
        <mat-hint>This should be the username to authenticate this server</mat-hint>
        <mat-error *ngIf="hasError('username', 'required')">Username is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput placeholder="password" type="password" formControlName="password">
        <mat-hint>This should be the password to authenticate this server</mat-hint>
        <mat-error *ngIf="hasError('password', 'required')">Password is required</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <app-error-panel [errors]="errorList"></app-error-panel>
    </div>

    <app-button-panel class="submit-panel">
      <div buttons>
        <button mat-raised-button color="success" type="submit" [disabled]="!server.valid">Create</button>
      </div>
    </app-button-panel>
  </form>

  <app-button-panel class="submit-panel">
    <div buttons>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>


