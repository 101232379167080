import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})

export class SearchComponent {
  @Output() public searchFilter: EventEmitter<any> = new EventEmitter();
  @Input() heading: string;
  @Input() count: number;
  @Input() userFilterString: string;

  constructor() {
  }

  search(event: Event) {
    this.userFilterString = (event.target as HTMLInputElement).value;
    this.searchFilter.emit(this.userFilterString);
  }
}
