<app-page-title icon="fas fa-question" title="Frequently Asked Questions (FAQ)"></app-page-title>

<div class="container">
  <div class="row error-container">
    <div class="col-md-12">
      <div *ngFor="let question  of questions" class="question">
        <app-expand [title]="question.question" [data]="question.answer"></app-expand>
      </div>
    </div>

    <div class="col-md-12">
      <app-button-panel>
        <div buttons>
          <app-back-button></app-back-button>
        </div>
      </app-button-panel>
    </div>
  </div>
</div>
