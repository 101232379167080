<app-card [heading]="dataSensor.name" (click)="onSensorClicked(dataSensor)">
  <div card-body>
    <div class="row">
      <div class="col-md-12">
        <div class="details-container">
          <h5><i class="fas fa-thermometer-half"></i> {{dataSensor.temperature}}°C</h5>
          <h5><i class="fas fa-tachometer-alt"></i> {{dataSensor.pressure}}hPa</h5>
          <h5><i class="fas fa-tint"></i> {{dataSensor.humidity}}%rh</h5>
        </div>

        <app-toggle-text [text]="alarmMessage" [error]="showAlarmError"></app-toggle-text>
      </div>
    </div>
  </div>
</app-card>
