import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientProject } from 'src/app/models/ClientProject';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ClientService } from '../../client.service';



@Component({
  selector: 'app-client-project',
  templateUrl: './client-project.component.html',
  styleUrls: ['./client-project.component.scss']
})
export class ClientProjectComponent implements OnInit {
  projectList: ClientProject[] = null;
  constructor(public clientService: ClientService, private toastService: ToastService,private route: ActivatedRoute,) { }

  ngOnInit(): void {
    const clientName = this.route.snapshot.paramMap.get('clientName');
    this.getClientprojects(clientName);
  }
  
  getClientprojects(clientName : string) {
    this.clientService.getAllClientProjects(clientName).subscribe(
      (projectList) => {
        this.projectList = projectList;
        this.toastService.showEmptyResponse('No servers', projectList.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to get all projects.');
      },
    );
  }
}
