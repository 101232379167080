import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-temperature-widget',
  templateUrl: './temperature-widget.component.html',
  styleUrls: ['./temperature-widget.component.scss'],
})

export class TemperatureWidgetComponent {
  @Input() value: string;
  @Input() unit: string;
  @Input() cssClass: string;
  @Input() image: string;

  constructor() {
  }
}
