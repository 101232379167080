import { Fonda } from './Fonda';

export class Project {
  constructor(public id: string,
              public name: string,
              public city: string,
              public latitude: number,
              public longitude: number,
              public serverName: string,
              public type: string,
              public isFonda: boolean,
              public fonda: Fonda) {
  }
}
