import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class LogService {
  constructor() {
  }

  static log(operation: string, error: any): void {
    console.log(`${operation} failed: ${error.message}`);
  }

  static error(msg: string, obj = {}): void {
    console.error(msg, obj);
  }

  static handleError<T>(operation = 'operation', error: any) {
    this.error(error);
    this.log(operation, error);
  }
}
