import { NgModule } from '@angular/core';

import { ProjectModule } from 'src/app/components/project/project.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { DataSensorComponent } from 'src/app/components/project/components/environment/components/data-sensor/data-sensor.component';
import { DataSensorsComponent } from 'src/app/components/project/components/environment/components/data-sensors/data-sensors.component';
import {
  TemperatureGraphComponent,
} from 'src/app/components/project/components/environment/components/temperature-graph/temperature-graph.component';
import {
  TemperatureGraphsComponent,
} from 'src/app/components/project/components/environment/components/temperature-graphs/temperature-graphs.component';
import {
  TemperatureInfoComponent,
} from 'src/app/components/project/components/environment/components/temperature-info/temperature-info.component';
import {
  TemperatureWidgetComponent,
} from 'src/app/components/project/components/environment/components/temperature-widget/temperature-widget.component';
import {
  TemperatureWidgetsComponent,
} from 'src/app/components/project/components/environment/components/temperature-widgets/temperature-widgets.component';
import { TemperatureComponent } from 'src/app/components/project/components/environment/components/temperature/temperature.component';
import { TemperaturesComponent } from 'src/app/components/project/components/environment/components/temperatures/temperatures.component';
import { EnvironmentComponent } from 'src/app/components/project/components/environment/environment.component';

@NgModule({
  imports: [
    SharedModule,
    ProjectModule,
  ],
  declarations: [
    EnvironmentComponent,
    TemperaturesComponent,
    TemperatureComponent,
    TemperatureInfoComponent,
    TemperatureWidgetComponent,
    TemperatureWidgetsComponent,
    TemperatureGraphsComponent,
    TemperatureGraphComponent,
    DataSensorComponent,
    DataSensorsComponent,
  ],
})

export class EnvironmentModule {
}
