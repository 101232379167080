import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-recent-alarms',
  templateUrl: './recent-alarms.component.html',
})

export class RecentAlarmsComponent implements OnInit {
  @Input() alarmList;

  columnDetails = [];

  constructor() {
  }

  ngOnInit() {
    this.columnDetails = [
      {
        key: 'alarmLevel',
        value: '',
        type: 'alarmLevel',
      },
      {
        key: 'time',
        value: 'Date and Time',
        type: 'string',
      },
      {
        key: 'site',
        value: 'Site',
        type: 'string',
      },
      {
        key: 'alarmType',
        value: 'Type',
        type: 'string',
      },
      {
        key: 'message',
        value: 'Message',
        type: 'string',
      },
    ];
  }
}
