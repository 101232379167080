import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ServerService } from 'src/app/components/server/server.service';
import { Server } from 'src/app/models/Server';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-server-details',
  templateUrl: './server-details.component.html',
  styleUrls: ['./server-details.component.scss'],
})

export class ServerDetailsComponent implements OnInit {
  server: Server = null;

  constructor(
    public serverService: ServerService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.fetchServer();
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '500px',
        data:
        {
          heading: 'Delete server?',
          message: `Are you sure you want to delete ${this.server.serverName}?`,
          showWarning: true,
        },
      });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteServer();
      }
    });
  }

  fetchServer() {
    this.serverService.getServer(this.route.snapshot.paramMap.get('id')).subscribe(
      (server) => {
        this.server = server;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch server details.');
      },
    );
  }

  deleteServer() {
    this.serverService.deleteServer(this.server.id).subscribe((newServer) => {
      this.toastService.showToast('success', 'Success!', 'Deleted server');
      this._router.navigate(['/servers']);
    },
    (error) => {
      this.toastService.showToast('error', 'Error!', 'Failed to delete server.');
    });
  }
}
