<app-card class="col-md-12">
  <div card-body class="row">
    <div class="col-md-4">
      <app-card heading="Alarm details">
        <div card-body>
          <app-key-value key="Total alarms" [value]="alarmService.alarmCount" type="count"></app-key-value>
          <app-key-value key="Total pages" [value]="alarmService.getTotalPage()" type="count"></app-key-value>

          <div class="controls">
            <mat-form-field>
              <mat-label>Page</mat-label>
              <input matInput type="number" min="1"  (keyup)="setPage($event)" [value]="alarmService.currentPage" >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Items per page</mat-label>
              <input matInput type="number" min="1"  (keyup)="setItems($event)" [value]="alarmService.itemPerPage" >
            </mat-form-field>
          </div>

          <div class="controls">
            <app-button-small cssClass="fas fa-angle-double-left" type="primary" (outputClick)="firstPage()"  disabled="{{alarmService.currentPage === 1}}" toolTip="First page"></app-button-small>
            <app-button-small cssClass="fas fa-angle-left" type="success" (outputClick)="previousPage()" disabled="{{alarmService.currentPage === 1}}" toolTip="Previous page"></app-button-small>
            <app-button-small cssClass="fas fa-angle-right" type="success" (outputClick)="nextPage()" disabled="{{alarmService.currentPage === alarmService.getTotalPage()}}" toolTip="Next page"></app-button-small>
            <app-button-small cssClass="fas fa-angle-double-right" type="primary" (outputClick)="lastPage()" disabled="{{alarmService.currentPage === alarmService.getTotalPage()}}" toolTip="Last page"></app-button-small>
          </div>

          <div class="controls">
            <app-toggle text="Show historic alarm" [value]="alarmService.showHistory" (outputFilter)="setHistory($event)"></app-toggle>
            <button mat-raised-button color="success" [routerLink]="['/project/alarm/report', projectId]">Historic report</button>
          </div>
        </div>
      </app-card>
    </div>

    <div class="col-md-4">
      <app-card heading="Filter">
        <div card-body>
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="filterAlarm($event)" placeholder="Alarm filter" #input>
          </mat-form-field>
        </div>
      </app-card>
    </div>

    <div class="col-md-4">
      <app-card heading="Alarm level">
        <div card-body class="alarm-levels row">
          <div class="col-md-3 alarm-level level-reset" (click)="filterLevel('')">
            <span>Reset</span>
          </div>
          <div class="col-md-3 alarm-level level-low" (click)="filterLevel('low')">
            <span>Low</span>
          </div>
          <div class="col-md-3 alarm-level level-medium" (click)="filterLevel('medium')">
            <span>Medium</span>
          </div>
          <div class="col-md-3 alarm-level level-high" (click)="filterLevel('high')">
            <span>High</span>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</app-card>
