import { Component, Input } from '@angular/core';

import { Camera } from 'src/app/models/Camera';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
})

export class CameraComponent {
  @Input() camera: Camera;

  constructor() {
  }
}
