import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';
import { Note } from 'src/app/models/Note';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})

export class NotesService {
  appConfig: any;

  constructor(private httpService: HttpHelperService, private userService: UserService) {
  }

  showAddNote() {
    return this.userService.canAddNote();
  }

  showDeleteNote() {
    return this.userService.canDeleteNote();
  }

  isAdmin() {
    return this.userService.isAdmin();
  }

  getNotes(id: string): Observable<any> {
    return this.httpService.getNotes(id)
    .pipe(
      catchError((err) => {
        LogService.handleError('getNotes', []);
        return throwError(err);
      }),
    );
  }

  addNote(note: Note): Observable<any> {
    return this.httpService.addNote(note)
    .pipe(
      catchError((err) => {
        LogService.handleError('addNote', err);
        return throwError(err);
      }),
    );
  }

  deleteNote(id: string): Observable<any> {
    return this.httpService.deleteNote(id)
    .pipe(
      catchError((err) => {
        LogService.handleError('deleteNote', []);
        return throwError(err);
      }),
    );
  }
}
