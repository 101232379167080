<header>
    <nav>
        <button mat-raised-button [matMenuTriggerFor]="menu" class="menuButton">Project Menu</button>

        <div class="navWrapper">
            <div class="navContent">
                <button mat-raised-button [disabled]="item.disabled" *ngFor="let item of menuItems" routerLink="{{item.link}}" class="{{item.buttonClass}}">
                    <i class="{{item.icon}}"></i> {{item.name | uppercase}}
                </button>
            </div>

            <mat-menu #menu="matMenu" class="navMenu">
                <button mat-menu-item *ngFor="let item of menuItems" routerLink="{{item.link}}" [disabled]="item.disabled">
                    <i class="{{item.icon}}"></i> {{item.name | uppercase}}
                </button>
            </mat-menu>
            <div fxFlex class="text--right">

            </div>
        </div>
    </nav>
</header>
