import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})

export class ReportComponent implements OnInit {
  projectId;
  project: Project = null;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.refreshItem();
  }

  getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  refreshItem() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(this.projectId);
  }
}
