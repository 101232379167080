import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { AlarmService } from 'src/app/components/project/components/alarm/alarm.service';
import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Alarm } from 'src/app/models/Alarm';
import { ChartService } from 'src/app/shared/services/chart.service';

@Component({
  selector: 'app-alarm-report',
  templateUrl: './alarm-report.component.html',
  styleUrls: ['./alarm-report.component.scss'],
})

export class AlarmReportComponent implements OnInit {
  project: Project = null;
  alarms: Alarm[];
  loading = false;

  report = {
    alarmLevel: null,
    alarmType: null,
    alarmStation: null,
    alarmDate: null,
    alarmStationLevelDate: null,
    alarmLevelStationDate: null,
  };

  levelChartOptions = ChartService.redChart;
  typeChartOptions = ChartService.orangeChart;
  stationChartOptions = ChartService.blueChart;
  dateChartOptions = ChartService.greenChart;

  constructor(
    private projectService: ProjectService,
    private alarmService: AlarmService,
    private route: ActivatedRoute,
    private toastService: ToastService) {
  }

  ngOnInit() {
    const projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(projectId);
  }

  setFilter(value) {
    if (this.project?.id) {
      this.loading = true;
      this.getAlarmReport(this.project.id, value.page, value.count, value.fromDate, value.toDate);
    }
  }

  private getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  private getAlarmReport(projectId: string, page: number, count: number, from: string, to: string) {
    this.alarmService.getAlarmReport(projectId, page, count, from, to).subscribe(
      (alarms) => {
        this.loading = false;
        this.report.alarmLevel = alarms.alarmLevel;
        this.report.alarmType = alarms.alarmType;
        this.report.alarmStation = alarms.alarmStation;
        this.report.alarmDate = alarms.alarmDate;
        this.report.alarmLevelStationDate = alarms.alarmLevelStationDate;
        this.report.alarmStationLevelDate = alarms.alarmStationLevelDate;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch alarms.');
      },
    );
  }
}
