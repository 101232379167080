<app-project-header icon="fas fa-bell" heading="Alarm" [project]="project"></app-project-header>
<div class="container">
  <app-loading [check]="project" color="primary"></app-loading>
  <app-alarm-control class="control" (outputFilter)="setFilter($event)"></app-alarm-control>
  <app-loading [check]="!loading" color="primary"></app-loading>

  <div class="row" *ngIf="report.alarmDate">
    <app-alarm-counter class="col-md-6" [groupedData]="report.alarmLevel" heading="Level" graphLabel="Alarm level" [chartOptions]="levelChartOptions"></app-alarm-counter>
    <app-alarm-counter class="col-md-6" [groupedData]="report.alarmType" heading="Type" graphLabel="Alarm type" [chartOptions]="typeChartOptions"></app-alarm-counter>
    <app-alarm-counter class="col-md-6" [groupedData]="report.alarmStation" heading="Station" graphLabel="Station name" [chartOptions]="stationChartOptions"></app-alarm-counter>
    <app-alarm-counter class="col-md-6" [groupedData]="report.alarmDate" heading="Date" graphLabel="Date" [chartOptions]="dateChartOptions"></app-alarm-counter>

    <div class="col-md-12">
      <ngx-sunburst-chart [data]="report.alarmLevelStationDate" colorScheme="cool"></ngx-sunburst-chart>
    </div>

    <div class="col-md-12">
      <ngx-sunburst-chart [data]="report.alarmStationLevelDate" colorScheme="warm"></ngx-sunburst-chart>
    </div>
  </div>

  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>
