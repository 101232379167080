<app-project-header icon="fas fa-globe-asia" heading="Environment" [project]="project"></app-project-header>
<app-option-panel heading="Options" *ngIf="project.isFonda">
  <app-button-small cssClass="fas fa-home" type="primary" [routerLink]="['/project/home', project?.id]" toolTip="Home" options></app-button-small>
  <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="refreshItem()" toolTip="Refresh sensors" options></app-button-small>
  <app-back-button [round]="true" options></app-back-button>
</app-option-panel>

<div class="container" *ngIf="project.isFonda">
  <app-loading [check]="environmentDetails" color="primary"></app-loading>

  <div class="count-container">
    <app-count name="Data sensors" [count]="dataSensors?.length"></app-count>
    <app-count name="Temp Sensor" [count]="environmentDetails?.length"></app-count>
  </div>

  <app-data-sensors heading="Data sensors" [data]="dataSensors" [timestamp]="dataSensorTimeStamp" (outputFilter)="refreshDataSensor()"></app-data-sensors>
  <app-temperatures heading="Temperature sensors" [data]="environmentDetails" [timestamp]="envTimeStamp" (outputFilter)="refreshSensors()"></app-temperatures>

  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>
