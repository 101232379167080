import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})

export class FAQComponent {
  questions = [
    {
      question: 'What rock group has four men that don\'t sing?',
      answer: 'Mount Rushmore.',
    },
    {
      question: 'What do sprinters eat before a race?',
      answer: 'Nothing, they fast!',
    },
    {
      question: 'What concert costs just 45 cents?',
      answer: '50 Cent featuring Nickelback!',
    },
  ];

  constructor() {
  }
}
