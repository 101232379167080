import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-viewer',
  templateUrl: './list-viewer.component.html',
  styleUrls: ['./list-viewer.component.scss'],
})

export class ListViewerComponent implements OnInit{
  @Input() item;

  data = [];

  constructor() {
  }

  ngOnInit(): void {
    this.deconstruct(null, this.item);
  }

  private deconstruct(keyPrefix, element) {
    for (const ele in element) {
      if(ele) {
        if (typeof  element[ele] !== 'object' ) {
          let key = this.capitalize(ele);
          if (keyPrefix) {
            key = `${this.capitalize(keyPrefix)} - ${this.capitalize(ele)}`;
          }

          this.data.push({key, value: element[ele]});
        } else {
          this.deconstruct(ele, element[ele]);
        }
      }
    }
  }

  private capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substr(1);
  }
}
