import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
})

export class BarChartComponent implements OnChanges {
  @Input() heading: string;
  @Input() data: any[];
  @Input() labels: any[];
  @Input() options: any;

  chart = null;
  chartReady = false;

  constructor() {
  }

  ngOnChanges() {
    this.generateChart();
  }

  generateChart() {
    this.chart = {
      data: this.data,
      labels: this.labels,
      ...this.options,
    };
    if (this.data && this.labels) {
      this.chartReady = true;
    }
  }
}
