<div class="row">
  <div class="col-md-3 element">
    <img class="image" [src]="images[0]">
  </div>
  <div class="col-md-3 element">
    <img class="image" [src]="images[1]">
  </div>
  <div class="col-md-3 element">
    <img class="image" [src]="images[2]">
  </div>
  <div class="col-md-3 element">
    <img class="image" [src]="images[3]">
  </div>

  <div class="col-md-3 element">
    <img class="image" [src]="images[4]">
  </div>
  <div class="col-md-3 element">
    <img class="image" [src]="images[5]">
  </div>
  <div class="col-md-3 element">
    <img class="image" [src]="images[6]">
  </div>
  <div class="col-md-3 element">
    <img class="image" [src]="images[7]">
  </div>
</div>
