<app-card [heading]="camera.name">
  <div card-body>
    <div class="row">
      <div class="col-md-10">
        <div class="details-container">
          <app-key-value key="Port" [value]="camera.port"></app-key-value>
          <app-key-value key="Channel number" [value]="camera.channelNumber"></app-key-value>
        </div>

        <div class="details-container">
          <app-key-value key="SDK port" [value]="camera.sdkPort"></app-key-value>
          <app-key-value key="RTSP port" [value]="camera.rtspPort"></app-key-value>
        </div>

        <div class="vertical-container">
          <app-key-value key="Address" [value]="camera.address"></app-key-value>
          <app-key-value key="Time" [value]="camera.time"  type="date"></app-key-value>
        </div>
        <app-toggle-text *ngIf="camera.remark" text="Remark: {{camera.remark}}" error="true"></app-toggle-text>
      </div>

      <div class="col-md-2">
        <app-on-off [value]="camera.keyAlarm" matTooltip="Key alarm" class="default"></app-on-off>
      </div>
    </div>
  </div>
</app-card>
