import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { IMapMarker } from 'src/app/interfaces/IMapMarker';
import { Project } from 'src/app/models/Project';
import { MarkerService } from 'src/app/shared/services/marker.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { LightingService } from '../lighting/lighting.service';
import { MapService } from './map.service';
import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['map.component.scss'],
})

export class MapComponent implements OnInit {
  project: Project = null;
  sensors = new Array();
  markers: IMapMarker[] = new Array();
  allMarkers: IMapMarker[] = new Array();
  mainMarker: IMapMarker;
  userFilter = '';

  constructor(
    private projectService: ProjectService,
    private lightingService: LightingService,
    private mapService: MapService,
    private markerService: MarkerService,
    private monitoringService: MonitoringService,
    private route: ActivatedRoute,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.fetchProject();
    if(this.project.isFonda){
    this.fetchSensors();
    }
  }

  filterMarker(value) {
    this.userFilter = value.toLowerCase();
    this.refreshMarkers();
  }

  filterSensor(type) {
  }

  private fetchProject() {
    this.projectService.getProject(this.route.snapshot.paramMap.get('id')).subscribe(
      (project) => {
        this.project = project;
        if(this.project.isFonda){
        this.fetchStations(this.project.id);
         }
        if(this.project.name === 'iMonitor'){
          this.fetchImonitor();
        }
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

 private fetchImonitor(){
    this.monitoringService.getTelstraMonitoringSensors().subscribe(
     (iMonitor) => {
       this.generateMarkersImonitor(iMonitor);
     },
     (error) =>{
      this.toastService.showToast('error', 'Error!', 'Failed to iMonitor Sensor.');
     },
    );
 }

  private fetchStations(projectId: string) {
    this.lightingService.getStations(projectId).subscribe(
      (stations) => {
        this.toastService.showEmptyResponse('No stations', stations.length);
        this.generateMarkersLighting(stations);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }

  private fetchSensors() {
    this.mapService.getAllSensors(this.route.snapshot.paramMap.get('id')).subscribe(
      (sensors) => {
        this.sensors = sensors;
        this.generateMonitoringSensors(sensors);
        this.generateEnvironmentSensors(sensors);
        this.toastService.showEmptyResponse('No sensors', sensors.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch sensors.');
      },
    );
  }

  private generateMonitoringSensors(allSensors) {
    const monitoringSensors = allSensors.filter((sensor) => sensor.deviceType === 'monitoring');
    this.generateMarkersMonitoring(monitoringSensors);
  }

  private generateEnvironmentSensors(allSensors) {
    const envSensors = allSensors.filter((sensor) => sensor.deviceType === 'environment');
    this.generateMarkersEnvironment(envSensors);
  }

  private generateMarkersLighting(stations) {
    stations.map((station) => {
      station.sensorType = 'Station';
      return station;
    });
    const lightingMarkers =  this.markerService.generatePoleMarker(stations);
    lightingMarkers.map((marker) => {
      marker.link = `/project/lighting/${this.project.id}/station/${marker.item.id}`;
      return marker;
    });

    this.mainMarker = lightingMarkers[0];
    this.allMarkers = this.allMarkers.concat(lightingMarkers);
    this.refreshMarkers();
  }

  private generateMarkersImonitor(iMonitors){
    iMonitors.map((station) => {
      station.sensorType = 'iMonitor';
      return station;
    });
    const impactMarkers =  this.markerService.generateImonitorMarker(iMonitors);
   /*
    lightingMarkers.map((marker) => {
      marker.link = `/project/lighting/${this.project.id}/station/${marker.item.id}`;
      return marker;
    }); */

    this.mainMarker = impactMarkers[0];
    this.markers = impactMarkers;
    this.allMarkers = this.allMarkers.concat(impactMarkers);
    this.refreshMarkers();
  }


  private generateMarkersMonitoring(sensors) {
    this.allMarkers = this.allMarkers.concat(this.markerService.generateMonitoringMarker(sensors));
    this.refreshMarkers();
  }

  private generateMarkersEnvironment(sensors) {
    this.allMarkers = this.allMarkers.concat(this.markerService.generateEnvironmentMarker(sensors));
    this.refreshMarkers();
  }

  private refreshMarkers() {
    this.markers = Array.from(this.allMarkers).filter((marker) =>
      marker.name.toLowerCase().includes(this.userFilter) || marker.type.toLowerCase().includes(this.userFilter));
  }
}
