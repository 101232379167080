<app-page-title icon="fas fa-building" title="Servers"></app-page-title>

<div class="container">
  <app-option-panel heading="Server options">
    <app-button-small *ngIf="serverService.showAddServer()" cssClass="fas fa-plus" type="success" [routerLink]="['/server/new']" toolTip="Add new server" options></app-button-small>
  </app-option-panel>
  <div class="row">
    <app-loading [check]="serverList" color="primary"></app-loading>

    <div *ngFor="let server of serverList" class="col-md-4">
      <app-card [heading]="server.serverName" [routerLink]="['/server', server.id]">
      </app-card>
    </div>

    <app-no-result class="no-result" [list]="serverList"></app-no-result>
  </div>
</div>
