<div class="row sensor-list-container">
  <app-sub-section [heading]="heading" [length]="data?.length"></app-sub-section>
  <div class="row">
    <div *ngFor="let sensor  of data" class="col-md-4">
      <app-sensor [sensor]="sensor" (click)="onSensorClicked(sensor)"></app-sensor>
    </div>

    <app-no-result class="no-result" [list]="data"></app-no-result>
  </div>
</div>
