import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-device',
  templateUrl: './report-device.component.html',
  styleUrls: ['./report-device.component.scss'],
})

export class ReportDeviceComponent {
  @Input() heading: string;
  @Input() image: string;
  @Input() url: string;

  constructor() {
  }
}
