<div class="main-container">
  <app-header></app-header>

  <ng-template #isOffline>
    <div>
        <p class="offline-error">{{'offlineMessage'}}&nbsp;&nbsp;&nbsp;<span>:&nbsp;)</span></p>
    </div>
  </ng-template>
  <router-outlet *ngIf="isOnline;else isOffline"></router-outlet>
  <app-footer></app-footer>
  <div class="bottom-spacer-panel"></div>
<div>
<app-cookie></app-cookie>
