<app-page-title icon="fas fa-phone" title="Contact us"></app-page-title>

<div class="container">
  <div class="row error-container">

    <div class="col-md-offset-3 col-md-4">
      <form [formGroup]="contact" (ngSubmit)="sendMail(contact)">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" type="text" formControlName="name">
          <mat-hint>Your name</mat-hint>
          <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Contact number</mat-label>
          <input matInput placeholder="Contact number" type="number" formControlName="contact">
          <mat-hint>Your contact details</mat-hint>
          <mat-error *ngIf="hasError('contact', 'required')">Contact number is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input matInput placeholder="E-mail" type="email" formControlName="email">
          <mat-hint>Your email address</mat-hint>
          <mat-error *ngIf="hasError('email', 'required')">E-mail is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Message</mat-label>
          <input matInput placeholder="Message" type="text" formControlName="message">
          <mat-hint>Your message for us</mat-hint>
          <mat-error *ngIf="hasError('message', 'required')">Message is required</mat-error>
        </mat-form-field>
      </form>
    </div>

    <div class="col-md-5">
      <app-expand open="true" title="Personal Information Collection Statement" [data]="data"></app-expand>
    </div>

    <div class="col-md-12">
      <app-button-panel>
        <div buttons>
          <button mat-raised-button color="success" type="submit" [disabled]="!contact.valid">Submit</button>
          <app-back-button></app-back-button>
        </div>
      </app-button-panel>
    </div>
  </div>
</div>
