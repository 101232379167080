<mat-card class="card">
  <mat-card-header>
    <div *ngIf="image" mat-card-avatar class="header-image" [ngStyle]="{ 'background-image': 'url(' + image + ')'}"></div>
    <mat-card-title id="card-heading">{{heading}}</mat-card-title>
    <mat-card-subtitle>{{subHeading}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ng-content select="[card-body]"></ng-content>
  </mat-card-content>
</mat-card>
