
<div class="map-info">
  <h4>{{marker.name}}</h4>
  <mat-divider></mat-divider>
  <div class="row" *ngIf="showInfo===true">
    <div class="col-md-3">
      <img [src]="icon" alt="sensor"/>
    </div>
    <div class="col-md-9">
      <app-list-viewer [item]="marker.item"></app-list-viewer>
    </div>
  </div>
  <div *ngIf="marker.link" class="view-button">
    <button mat-raised-button [routerLink]="[marker.link]" color="success">View</button>
  </div>
</div>
