import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MonitoringService } from './monitoring.service';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
})

export class MonitoringComponent implements OnInit {
  projectId;
  project: Project;
  monitoringDetails = {
    impactSensors: [],
    parkingSensors: [],
    binSensors: [],
    gpsSensors: [],
    tiltSensors: [],
    manholeSensors: [],
    accelSensors: [],
  };

  cameraConnected = [];
  cameraTimeStamp;

  constructor(
    private monitoringService: MonitoringService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(this.projectId);
    this.refreshItem();
  }

  refreshItem() {
    this.monitoringDetails = {
      impactSensors: [],
      parkingSensors: [],
      binSensors: [],
      gpsSensors: [],
      tiltSensors: [],
      manholeSensors: [],
      accelSensors: [],
    };

    this.fetchSensors(this.projectId);
    this.refreshCameras(this.projectId);
    this.fetchTelstraSensors();
  }

  refreshCameras(projectId: string) {
    this.cameraConnected = [];
    this.cameraTimeStamp = new Date();
    this.fetchCameraConnected(projectId);
  }

  private getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  private fetchSensors(projectId: string) {
    this.monitoringService.getMonitoringSensors(projectId).subscribe(
      (allSensors) => {
        this.monitoringDetails = allSensors;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to update monitoring sensors.');
      },
    );
  }

  private fetchTelstraSensors() {
    this.monitoringService.getTelstraMonitoringSensors().subscribe(
      (allTelstraSensors) => {
        this.monitoringDetails.impactSensors = allTelstraSensors;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to update Telstra monitoring sensors.');
      },
    );
  }

  private fetchCameraConnected(projectId: string) {
    this.monitoringService.getCameraConnected(projectId).subscribe(
      (cameras) => {
        this.cameraConnected = cameras;
        this.toastService.showEmptyResponse('No cameras', cameras.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch connected cameras.');
      },
    );
  }
}
