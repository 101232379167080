<app-card>
  <div card-body class="row">
    <div class="col-md-4">
      <app-card heading="Filter">
        <div card-body>
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="filterMarker($event)" placeholder="Map filter" #input>
          </mat-form-field>
        </div>
      </app-card>
    </div>

    <div class="col-md-8">
      <app-card heading="Types">
        <div card-body class="marker-levels row">
          <div class="col-md-3 marker-level marker-reset" (click)="filterSensor('')">
            <span>Reset</span>
          </div>
          <div class="col-md-3 marker-level marker-lighting" (click)="filterSensor('Station')">
            <span>Lighting</span>
          </div>
          <div class="col-md-3 marker-level marker-environment" (click)="filterSensor('Environment')">
            <span>Environment</span>
          </div>
          <div class="col-md-3 marker-level marker-monitoring" (click)="filterSensor('Monitoring')">
            <span>Monitoring</span>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</app-card>
