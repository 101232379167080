import { Component, Input } from '@angular/core';

import { Project } from 'src/app/models/Project';

@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss'],
})

export class ProjectHeaderComponent {
  @Input() icon: string;
  @Input() heading: string;
  @Input() project: Project;

  constructor() {
  }
}
