<app-project-header icon="far fa-chart-bar" heading="Reports" [project]="project"></app-project-header>
<app-option-panel heading="Options">
  <app-button-small cssClass="fas fa-home" type="primary" [routerLink]="['/project/home', project?.id]" toolTip="Home" options></app-button-small>
  <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="refreshItem()" toolTip="Refresh sensors" options></app-button-small>
  <app-back-button [round]="true" options></app-back-button>
</app-option-panel>

<div class="container">
  <div class="row">
    <div class="col-md-4">
      <app-report-device heading="Lamp" image="assets/images/marker/lamp_on.png" url="{{'project/report/lamp/' + projectId}}"></app-report-device>
    </div>

    <div class="col-md-4">
      <app-report-device heading="Meter" image="assets/images/marker/station.png" url="{{'project/report/meter/' + projectId}}"></app-report-device>
    </div>

    <div class="col-md-4">
      <app-report-device heading="Weather sensor" image="assets/images/sensors/temp.png" url="{{'project/report/data/' + projectId}}"></app-report-device>
    </div>
  </div>

  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>
