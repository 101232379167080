<div class="sensor-container">
  <h5>Parking Sensor</h5>
  <div class="row">
    <div class="col-md-4">
      <img [src]="image" alt="sensor"/>
    </div>
    <div class="col-md-8" *ngIf="sensor.details.latestValue">
      <h5>Battery level : {{sensor.details.latestValue.batteryLevel}}</h5>
      <h5>Occupied : {{sensor.details.latestValue.occupied}}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 sensorData" *ngIf="dataSource.length > 0">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="time">
          <mat-header-cell *matHeaderCellDef> Time </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.time}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="available">
          <mat-header-cell *matHeaderCellDef> Available </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="status status-green" *ngIf="element.available"></div>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="occupied">
          <mat-header-cell *matHeaderCellDef> Occupied </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="status status-orange"*ngIf="element.occupied"></div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expired">
          <mat-header-cell *matHeaderCellDef> Expired </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="status status-red" *ngIf="element.expired"></div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
