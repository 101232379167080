<div class="sensor-container">
  <h5>Impact Sensor</h5>
  <div class="row">
    <div class="col-md-4">
      <img [src]="image" alt="sensor"/>
    </div>
    <div class="col-md-8">
      <h5>Battery level : {{sensor.batteryLevel}}</h5>
      <h5>Temperature : {{sensor.temperature}}</h5>
      <h5>Updated : {{sensor.updated}}</h5>
      <h5>Latitude : {{sensor.latitude}}</h5>
      <h5>Longitude : {{sensor.longitude}}</h5>
    </div>
  </div>
</div>


