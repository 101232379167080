<div class="sensor-container">
  <h5>Tilt Sensor</h5>
  <div class="row">
    <div class="col-md-8">
      <img [src]="image" alt="sensor"/>
    </div>
    <div class="col-md-4" *ngIf="sensor.details.latestValue">
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 sensorData" *ngIf="dataSource.length > 0">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="time">
          <mat-header-cell *matHeaderCellDef> Time </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.time}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="accel">
          <mat-header-cell *matHeaderCellDef> Accel </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.accel}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="gyro">
          <mat-header-cell *matHeaderCellDef> Gyro </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.gyro}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="mag">
          <mat-header-cell *matHeaderCellDef> Mag </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.mag}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
