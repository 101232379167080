import { Component, OnInit } from '@angular/core';

import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProjectService } from './project.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})

export class ProjectComponent implements OnInit {
  projectList: Project[] = [];
  groupedProjectList: any = null;

  constructor(public projectService: ProjectService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.fetchProjects();
  }

  refreshProjects() {
    this.projectService.allProjects = null;
    this.projectList = [];
    this.groupedProjectList = null;
    this.fetchProjects();
  }

  syncProjects() {
    this.projectService.syncProjects().subscribe(
      (projectList) => {
        this.arrangeProjects(projectList);
        this.toastService.showToast('success', 'Success!', 'Synced projects.');
        this.toastService.showEmptyResponse('No projects to show', projectList.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to sync all projects.');
      },
    );
  }

  private fetchProjects() {
    this.projectService.getAllProjects().subscribe(
      (projectList) => {
        this.arrangeProjects(projectList);
        this.toastService.showEmptyResponse('No projects to show', projectList.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch all projects.');
      },
    );
  }

  private arrangeProjects(projectList) {
    this.projectList = projectList;
    this.groupedProjectList = this.groupProjects(this.projectList);
  }

  private groupProjects(array) {
    return array.reduce((result, currentValue) => {
      (result[currentValue['serverName']] = result[currentValue['serverName']] || []).push(
        currentValue,
      );
      return result;
    }, {});
  }
}
