import { Component, Input, OnChanges } from '@angular/core';
import { ChartService } from 'src/app/shared/services/chart.service';

@Component({
  selector: 'app-alarm-counter',
  templateUrl: './alarm-counter.component.html',
  styleUrls: ['./alarm-counter.component.scss'],
})

export class AlarmCounterComponent implements OnChanges {
  @Input() heading: string;
  @Input() groupedData;
  @Input() graphLabel;
  @Input() chartOptions;

  labels;
  values;

  barOptions = ChartService.barChartOptions;

  constructor() {
  }

  ngOnChanges(): void {
    if (this.groupedData) {
      this.getLabels();
      this.getCountValues();
    }
  }

  private getLabels() {
    this.labels = this.groupedData.map((data) => data.key);
  }

  private getCountValues() {
    this.values = [{
      data: this.groupedData.map((data) => data.count),
      label: this.graphLabel,
      ...this.chartOptions,
    }];
  }
}
