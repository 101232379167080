<app-project-header icon="fas fa-home" heading="Home" [project]="project"></app-project-header>

<div class="container">
  <app-loading [check]="project" color="primary"></app-loading>
  <div class="row" *ngIf="project">
    <app-option-panel heading="Project options">
      <app-button-small cssClass="fas fa-home" type="primary" [routerLink]="['/project/home', project?.id]" toolTip="Home" options></app-button-small>
      <app-button-small *ngIf="projectService.showAddProject()" cssClass="fas fa-plus" type="success" [routerLink]="['/project/new']" toolTip="Add new project" options></app-button-small>
      <app-button-small cssClass="fas fa-redo-alt" type="danger" (outputClick)="refreshProject()" toolTip="Refresh project" options></app-button-small>
      <app-back-button [round]="true" options></app-back-button>
    </app-option-panel>

    <div class="col-md-6 count-container" *ngIf="project.isFonda">
      <app-count class="col-md-3" name="Stations" [count]="project.fonda.stationsCount"></app-count>
      <app-count class="col-md-3" name="RTUs" [count]="project.fonda.rtuCount"></app-count>
      <app-count class="col-md-3" name="Lamps" [count]="project.fonda.lampsCount"></app-count>
      <app-count class="col-md-3" name="Poles" [count]="project.fonda.polesCount"></app-count>
      <app-count class="col-md-3" name="LCUs" [count]="project.fonda.lcuCount"></app-count>
      <app-count class="col-md-3" name="Breakers" [count]="project.fonda.breakerCount"></app-count>
      <app-count class="col-md-3" name="Ammeters" [count]="project.fonda.ammeterCount"></app-count>
      <app-count class="col-md-3" name="Anti-thefts" [count]="project.fonda.antiTheftCount"></app-count>
    </div>

    <div class="col-md-6 count-container" *ngIf="project.name === 'iMonitor'">
      <app-count class="col-md-3" name="iMonitor" [count]="3"></app-count>
    </div>



    <div class="col-md-6">
      <app-project-info [project]="project"></app-project-info>
    </div>
  </div>

  <div class="row" *ngIf="project?.isFonda">
    <div class="col-md-6">
      <app-project-group heading="Lamp group" [items]="lampGroups"></app-project-group>
    </div>

    <div class="col-md-6">
      <app-project-group heading="Breaker group" [items]="breakerGroups"></app-project-group>
    </div>
  </div>

  <div class="row" *ngIf="project?.isFonda">
    <div class="col-md-6">
      <app-current-stat heading="Lowest energy consumption" [items]="energyLow" [projectId]="project.id"></app-current-stat>
    </div>

    <div class="col-md-6">
      <app-current-stat heading="Highest energy consumption" [items]="energyHigh" [projectId]="project.id"></app-current-stat>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12" *ngIf="energyUsagePerMonth">
      <div class="row">
        <div class="col-md-4">
          <app-card [heading]= "energyUsagePerMonth.yesterday/1000 + ' kWh'" subHeading="Energy usage for yesterday"></app-card>
        </div>
        <div class="col-md-4">
          <app-card [heading]= "energyUsagePerMonth.year/1000 + ' kWh'" subHeading="Energy usage for this year"></app-card>
        </div>
        <div class="col-md-4">
          <app-card [heading]= "energyUsagePerMonth.month/1000 + ' kWh'" subHeading="Energy usage for this month"></app-card>
        </div>
      </div>
    </div>

    <div class="col-md-6" *ngIf="energyUsagePerYear">
      <app-bar-chart heading="Energy Usage for this year (kW)" [data]="energyUsagePerYearData" [labels]="energyUsagePerYearLabel" [options]="barOptions"></app-bar-chart>
    </div>

    <div class="col-md-6" *ngIf="energyUsagePerMonth">
      <app-bar-chart heading="Energy Usage for this month (kWh)" [data]="energyUsagePerMonthData" [labels]="energyUsagePerMonthLabel" [options]="barOptions"></app-bar-chart>
    </div>

    <div class="col-md-12" *ngIf="alarmList">
      <app-recent-alarms [alarmList]="alarmList"></app-recent-alarms>
    </div>
  </div>

  <div class="row" *ngIf="lightingGraphDetails">
    <div class="col-md-6" *ngIf="alarmCountPerMonth">
      <app-bar-chart heading="Alarms this month" [data]="alarmCountPerMonthData" [labels]="alarmCountPerMonthLabel" [options]="barOptions"></app-bar-chart>
    </div>

    <div class="col-md-6" *ngIf="alarmCountPerDay">
      <app-bar-chart heading="Alarms today" [data]="alarmCountPerDayData" [labels]="alarmCountPerDayLabel" [options]="barOptions"></app-bar-chart>
    </div>

    <div class="col-md-6" *ngIf="alarmCountPerHour">
      <app-bar-chart heading="Alarms this year" [data]="alarmCountPerHourData" [labels]="alarmCountPerHourLabel" [options]="barOptions"></app-bar-chart>
    </div>
  </div>

  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>

      <button *ngIf="projectService.showAddProject()" mat-raised-button color="success" aria-label="Add new project" [routerLink]="['/project/new']" options>
        Add new project
      </button>
    </div>
  </app-button-panel>
</div>
