<app-project-header icon="fas fa-camera" heading="Clearsonics" [project]="project"></app-project-header>
<app-option-panel heading="Options">
  <app-button-small cssClass="fas fa-home" type="primary" [routerLink]="['/project/home', project?.id]" toolTip="Home" options></app-button-small>
  <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="refreshItem()" toolTip="Refresh items" options></app-button-small>
</app-option-panel>
<div class="container">
  <h2>Clearsonics</h2>
  <app-loading [check]="clearsonicsDetails" color="primary"></app-loading>

  <div class="row" *ngIf="clearsonicsDetails">

  </div>

  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>
