import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expand',
  templateUrl: './expand.component.html',
  styleUrls: ['./expand.component.scss'],
})

export class ExpandComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() data: string;

  constructor() {
  }
}
