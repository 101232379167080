<app-page-title icon="fas fa-exclamation-triangle" title="Oops, something went wrong."></app-page-title>

<div class="container">
  <div class="row error-container">
    <div class="col-md-4">
      <h1 class="error-sign">
        <i class="fas fa-exclamation-triangle"></i>
      </h1>
    </div>
    <div class="col-md-8">
      <p class="title">We apologize. <small class="sub-title">Here are some useful links for you.</small></p>

      <div class="row">
        <div class="col-md-6">
          <app-card heading="Looking for this?">
            <div card-body>
                <app-link name="View all projects" url="/projects/"></app-link>
                <app-link name="View all servers" url="/servers/"></app-link>
            </div>
          </app-card>
        </div>

        <div class="col-md-6">
          <app-card heading="Other useful links">
            <div card-body>
              <app-link name="Go home" url="/"></app-link>
            </div>
          </app-card>
        </div>
      </div>
    </div>
  </div>
</div>
