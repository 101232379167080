import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-temperature-graphs',
  templateUrl: './temperature-graphs.component.html',
  styleUrls: ['./temperature-graphs.component.scss'],
})

export class TemperatureGraphsComponent implements OnInit {
  @Input() data: any;

  temperatureData: any[];
  humidityData: any[];
  noiseData: any[];
  pressureData: any[];
  rainData: any[];
  windData: any[];
  pm25Data: any[];
  pm10Data: any[];

  hasTemperatureData = false;
  hasHumidityData = false;
  hasNoiseData = false;
  hasPressureData = false;
  hasRainData = false;
  hasWindData = false;
  hasPm25Data = false;
  hasPm10Data = false;

  constructor() {
  }

  ngOnInit() {
    this.processData();
  }

  processData() {
    this.processTemperature(this.data);
    this.processHumidity(this.data);
    this.processNoise(this.data);
    this.processPressure(this.data);
    this.processRain(this.data);
    this.processWind(this.data);
    this.processPM25(this.data);
    this.processPM10(this.data);
  }

  processTemperature(data: any) {
    this.temperatureData = data.map((a) =>  ({key : a.temperature, time: a.time } ));
    this.hasTemperatureData = this.temperatureData.filter((x) => x.key > 0).length > 0;
  }

  processHumidity(data: any) {
    this.humidityData = this.data.map((a) =>  ({key : a.humidity, time: a.time } ));
    this.hasHumidityData = this.humidityData.filter((x) => x.key > 0).length > 0;
  }

  processNoise(data: any) {
    this.noiseData = this.data.map((a) =>  ({key : a.noise, time: a.time } ));
    this.hasNoiseData = this.noiseData.filter((x) => x.key > 0).length > 0;
  }

  processPressure(data: any) {
    this.pressureData = this.data.map((a) =>  ({key : a.pressure, time: a.time } ));
    this.hasPressureData = this.pressureData.filter((x) => x.key > 0).length > 0;
  }

  processRain(data: any) {
    this.rainData = this.data.map((a) =>  ({key : a.rain, time: a.time } ));
    this.hasRainData = this.rainData.filter((x) => x.key > 0).length > 0;
  }

  processWind(data: any) {
    this.windData = this.data.map((a) =>  ({key : a.wind, time: a.time } ));
    this.hasWindData = this.windData.filter((x) => x.key > 0).length > 0;
  }

  processPM25(data: any) {
    this.pm25Data = this.data.map((a) =>  ({key : a.pM2_5, time: a.time } ));
    this.hasPm25Data = this.pm25Data.filter((x) => x.key > 0).length > 0;
  }

  processPM10(data: any) {
    this.pm10Data = this.data.map((a) =>  ({key : a.pM10, time: a.time } ));
    this.hasPm10Data = this.pm10Data.filter((x) => x.key > 0).length > 0;
  }
}
