import { Component, Input } from '@angular/core';

import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-key-value',
  templateUrl: './key-value.component.html',
  styleUrls: ['./key-value.component.scss'],
})

export class KeyValueComponent {
  @Input() key: string;
  @Input() value: any;
  @Input() type = 'string';

  constructor() {
  }

  renderValue(value) {
    let stringValue: string;

    if(!value && value !== 0){
      return '-';
    }

    switch (this.type) {
      case 'string': {
        stringValue = value;
        break;
      }
      case 'count': {
        stringValue = this.renderCount(value);
        break;
      }
      case 'boolean': {
        stringValue = this.renderBoolean(value);
        break;
      }
      case 'date': {
        stringValue = this.getShortDate(value);
        break;
      }
      default: {
        stringValue = value;
        break;
      }
    }

    return stringValue;
  }

  private renderCount(value) {
    return parseFloat(value).toLocaleString();
  }

  private renderBoolean(value) {
    if (value === true) {
      return '<i class="fas fa-check"></i>';
    } else {
      return '<i class="fas fa-times"></i>';
    }
  }

  private getShortDate(unixDate) {
    return new DateService().dateWithTime(unixDate);
  }
}
