<div class="row">
  <app-temperature-graph class="col-md-4" *ngIf="hasTemperatureData" [value]="temperatureData" heading="48 hour temperature data" subheading="Temperature"></app-temperature-graph>
  <app-temperature-graph class="col-md-4" *ngIf="hasHumidityData" [value]="humidityData" heading="48 hour humidity data" subheading="Humidity"></app-temperature-graph>
  <app-temperature-graph class="col-md-4" *ngIf="hasPressureData" [value]="pressureData" heading="48 hour pressure data" subheading="Pressure"></app-temperature-graph>
  <app-temperature-graph class="col-md-4" *ngIf="hasRainData" [value]="rainData" heading="48 hour rain data" subheading="Rain"></app-temperature-graph>
  <app-temperature-graph class="col-md-4" *ngIf="hasWindData" [value]="windData" heading="48 hour wind data" subheading="Wind"></app-temperature-graph>
  <app-temperature-graph class="col-md-4" *ngIf="hasNoiseData" [value]="noiseData" heading="48 hour noise data" subheading="Noise"></app-temperature-graph>
  <app-temperature-graph class="col-md-4" *ngIf="hasPm25Data" [value]="pm25Data" heading="48 hour pollution 2.5 data" subheading="Pollution 2.5"></app-temperature-graph>
  <app-temperature-graph class="col-md-4" *ngIf="hasPm10Data" [value]="pm10Data" heading="48 hour pollution 10 data" subheading="Pollution 10"></app-temperature-graph>
</div>
