<app-page-title icon="fab fa-creative-commons-by" title="{{auth.user.name}} Projects" *ngIf="auth.loggedIn"></app-page-title>
   
<div class="row" style="column-span: 4; height: 100px;">
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;"></div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;"></div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;"></div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;"></div>
</div>

<div class="row">
    <!--app-card   [routerLink]="['/clientProject/DoT Victoria']" *ngIf="user.canReadVdot()">
        <div card-body>
            <div class="details">
            <div class="detail green" matTooltip="TST Logo"><img src="/assets/images/marker/Transport Victoria.png"/></div>
            </div>
        </div>
    </app-card>
    <app-card   [routerLink]="['/clientProject/Gold Coast City Council']" *ngIf="user.canReadGccc()">
        <div card-body>
            <div class="details">
            <div class="detail green" matTooltip="TST Logo"><img src="/assets/images/marker/CityOfGoldCoast.png"/></div>
            </div>
        </div>
    </app-card>
    <app-card   [routerLink]="['/clientProject/City of Salisbury']" *ngIf="user.canReadCos()">
        <div card-body>
            <div class="details">
            <div class="detail green" matTooltip="TST Logo"><img src="/assets/images/marker/CoS LOGO.png"/></div>
            </div>
        </div>
    </app-card>
    <app-card   [routerLink]="['/clientProject/Department for Infrastructure and Transport - SA']" *ngIf="user.canReadSadfit()">
        <div card-body>
            <div class="details">
            <div class="detail green" matTooltip="TST Logo"><img src="/assets/images/marker/DotSA.png"/></div>
            </div>
        </div>
    </app-card -->
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadVdot()">
        <a [routerLink]="['/clientProject/DoT Victoria']">
           <img src="/assets/images/marker/Transport Victoria.png"/>
        </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadGccc()">
        <a [routerLink]="['/clientProject/Gold Coast City Council']">
            <img src="/assets/images/marker/CityOfGoldCoast.png"/>
         </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadCos()">
        <a [routerLink]="['/clientProject/City of Salisbury']">
            <img src="/assets/images/marker/CoS LOGO.png"/>
         </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadSadfit()">
        <a [routerLink]="['/clientProject/Department for Infrastructure and Transport - SA']">
            <img src="/assets/images/marker/DotSA.png"/>
         </a>
    </div>
</div>

<div class="row">
    
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadCot()">
        <a [routerLink]="['/clientProject/Townsville City Council']">
            <img src="/assets/images/marker/Townsville City Council.png"/>
         </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadCom()">
        <a [routerLink]="['/clientProject/Monash City Council']">
            <img src="/assets/images/marker/Monash City Council.png"/>
         </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadCol()">
        <a [routerLink]="['/clientProject/City of Logan Council']">
            <img src="/assets/images/marker/City of Logon.png"/>
         </a>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadGdc()">
        <a [routerLink]="['/clientProject/Danendong City Council']">
            <img src="/assets/images/marker/Danendong City Council.png"/>
         </a>
    </div>
  </div>

  <div class="row">
    
    <div class="col-lg-3 col-md-3 col-sm-3" style="border-color: black;" *ngIf="user.canReadChb()">
        <a [routerLink]="['/clientProject/Coates Hire']">
            <img src="/assets/images/marker/Coates Hire Logo.png"/>
         </a>
    </div>

    <!--app-card   [routerLink]="['/clientProject/Coates Hire']" *ngIf="user.canReadChb()">
        <div card-body>
            <div class="details">
            <div class="detail green" matTooltip="TST Logo"><img src="/assets/images/marker/Coates Hire Logo.png"/></div>
            </div>
        </div>
    </app-card-->

  </div>