<div class="carousel-container" *ngIf="slideImage">
  <div class="image-container">
    <button class="arrow" (click)="previous()" *ngIf="slideLength > 0">
      <i class="fas fa-chevron-left"></i>
    </button>

    <div class="carousel-image">
      <img [src]="slideImage.path" [alt]="slideImage.text" class="slide" (click)="onImageClicked()"/>
    </div>

    <button class="arrow right" (click)="next()" *ngIf="slideLength > 0">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>

  <div class="image-details" *ngIf="slideLength > 0">
    <div *ngFor="let image of slides; let i = index">
      <span class="dot" (click)="dotClick(i)" [ngClass]="{'dot-selected': slideIndex === i}"></span>
    </div>
  </div>
</div>
