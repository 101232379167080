
<app-loading [check]="project" color="primary"></app-loading>

<div class="row" *ngIf="project">
  <app-option-panel heading="Project options">
    <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="refreshProject()" toolTip="Refresh items" options></app-button-small>
    <app-back-button [round]="true" options></app-back-button>
  </app-option-panel>

  <div class="col-md-12 map-container">
    <app-gmap [mainMarker]="mainMarker" [markers]="allMarkers" showInfo="true"></app-gmap>
  </div>
</div>

