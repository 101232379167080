import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ModelError } from 'src/app/models/ModelError';
import { ContactService } from 'src/app/shared/services/contact.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})

export class ContactComponent implements OnInit {
  contact: FormGroup;
  errorList: ModelError[];

  data = `Your personal information will be disclosed to our admin team so that they can contact you about your enquiry.
          We may also contact you to get your feedback on our products and services.
          Our Privacy policy explains how we collect, use and disclose personal information and how you may access, correct or
          complain about the handling of personal information. By submitting this enquiry, you acknowledge that you are making a
          genuine enquiry about this product. Your use or access of this product is subject to our website terms of use.
          We reserves the right to take action in accordance with its Website terms of use to prevent any misuse of this feature.`;

  constructor(private contactService: ContactService,
              private toastService: ToastService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.initializeFromData();
  }

  initializeFromData() {
    this.contact = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      contact: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });
  }

  hasError = (controlName: string, errorName: string) => this.contact.controls[controlName].hasError(errorName);

  sendMail(contact) {
    this.errorList = new Array();
    this.contactService.sendContact(contact.value).subscribe((_) => {
      this.toastService.showToast('success', 'Success!', 'We will get back to you shortly');
    },
    (error) => {
      this.toastService.showToast('error', 'Error!', 'Failed to create new contact.');
      this.errorList = ErrorService.parseError(error);
    });
  }
}
