<agm-map  [usePanning]="true" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [styles]="myStyles" [restriction]="countryRestriction" [clickableIcons]="false" [disableDoubleClickZoom]="false" (mapClick)="mapClick($event)" [scrollwheel]="true" [gestureHandling]="'cooperative'">
  <div *ngIf="markersToDisplay.length > 0">
    <div *ngFor="let marker  of markersToDisplay">
      <agm-marker [latitude]="marker.latitude" [longitude]="marker.longitude" [iconUrl]="marker.icon" (markerClick)="markerClicked(marker, infoWindow)">
        <agm-info-window #infoWindow>
          <app-gmap-info [marker]="marker" [showInfo]="showInfo"></app-gmap-info>
        </agm-info-window>
      </agm-marker>
    </div>
  </div>
</agm-map>
