import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/Project';
import { ProjectService } from 'src/app/components/project/project.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { IMapMarker } from 'src/app/interfaces/IMapMarker';

@Component({
  selector: 'app-cctv',
  templateUrl: './cctv.component.html',
  styleUrls: ['./cctv.component.scss']
})
export class CCTVComponent implements OnInit { 
  project: Project = null;
  markers: IMapMarker[] = new Array();
  mainMarker= {} as IMapMarker;
  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private toastService: ToastService
    ) { }

  ngOnInit(): void {
    this.fetchProject();
    this.setMarkers();
  }
  
  private fetchProject() {
    this.projectService.getProject(this.route.snapshot.paramMap.get('id')).subscribe(
      (project) => {
        this.project = project;
        if(this.project.isFonda){
        //this.fetchStations(this.project.id);
        //this.fetchLampConnected(this.project.id);
        //this.fetchLamps(this.project.id);
        console.log(this.project.serverName);
        console.log(this.project.name);
        
         }
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  private setMarkers(){
    this.mainMarker.name ='CCTV Camera';
    this.mainMarker.icon = 'assets/images/marker/cctv.png';
    this.mainMarker.latitude= -37.716935123240916;
    this.mainMarker.longitude= 145.14126954011778;
    this.mainMarker.type='CCTV';
    this.mainMarker.link='http://54.79.193.241/video/video.html';
    this.mainMarker.item=  { name : 'CCTV',latitude: -37.716935123240916,longitude: 145.14126954011778,type:'CCTV'};
    this.markers[0] = this.mainMarker;
  }
}
