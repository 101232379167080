import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { AppConfig } from './configs/app.config';

@Injectable()
export class GuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  waitForHandleAuthCallbackToComplete(): Observable<boolean> {
    return this.auth.handleAuthCallbackComplete$.pipe(
      filter((complete) => complete),
      take(1),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.waitForHandleAuthCallbackToComplete().pipe(
      switchMap(() =>
        this.auth.isAuthenticated$.pipe(
          tap((loggedIn) => {
            if (!loggedIn) {
              this.auth.login(state.url);
            }
            if (this.auth.user) {
              if (!this.auth.user.hasPermission(route.data.permission)) {
                this.router.navigate([AppConfig.routes.error401]);
                return false;
              }
              return true;
            }
          }),
        ),
      ),
    );
  }
}
