import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';

@Injectable({
  providedIn: 'root',
})

export class MonitoringService {
  constructor(private httpService: HttpHelperService) {
  }

  getTelstraMonitoringSensors(): Observable<any> {
    return this.httpService.getTelstraMonitoringSensors()
    .pipe(
      catchError((err) => {
        LogService.handleError('getTelstraMonitoringSensors', []);
        return throwError(err);
      }),
    );
  }


  getMonitoringSensors(projectId: string): Observable<any> {
    return this.httpService.getMonitoringSensors(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getMonitoringSensors', []);
        return throwError(err);
      }),
    );
  }

  getCameraConnected(projectId: string): Observable<any> {
    return this.httpService.getCameraConnected(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getCameraConnected', []);
        return throwError(err);
      }),
    );
  }

  getCameraMapItems(projectId: string): Observable<any> {
    return this.httpService.getCameraMapItems(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getCameraMapItems', []);
        return throwError(err);
      }),
    );
  }

  getCameras(projectId: string): Observable<any> {
    return this.httpService.getCameras(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getCameras', []);
        return throwError(err);
      }),
    );
  }

  getImageType(sensor: any): string {
    let formattedType = '';

    switch (sensor.sensorType) {
      case 'parking': {
        if (sensor.details.latestValue != null) {
          formattedType = `${sensor.sensorType}-${sensor.details.latestValue.occupied}`;
        } else {
          formattedType = `${sensor.sensorType}-true`;
        }
        break;
      }
      case 'bin': {
        formattedType = `${sensor.sensorType}`;
        break;
      }
      case 'gps': {
        formattedType = `${sensor.sensorType}`;
        break;
      }
      case 'tilt': {
        formattedType = `${sensor.sensorType}`;
        break;
      }
      case 'manhole': {
        if (sensor.details.latestValue != null) {
          formattedType = `${sensor.sensorType}-${sensor.details.latestValue.open}`;
        } else {
          formattedType = `${sensor.sensorType}-true`;
        }
        break;
      }
      case 'accel': {
        formattedType = `${sensor.sensorType}`;
        break;
      }
      case 'impact' : {
        formattedType = `${sensor.sensorType}`;
        break;
      }
      default: {
        formattedType = `${sensor.sensorType}`;
        break;
      }
    }
    return this.getIcon(formattedType);
  }

  getIcon(type: string): string {
    let image = 'assets/images/logo.png';
    const imageLocation = 'assets/images/sensors';

    switch (type) {
      case 'parking': {
         image = `${imageLocation}/parking.png`;
         break;
      }
      case 'parking-false': {
        image = `${imageLocation}/parking.png`;
        break;
     }
      case 'parking-true': {
        image = `${imageLocation}/parking-occupied.png`;
        break;
     }
      case 'bin': {
        image = `${imageLocation}/bin.png`;
        break;
      }
      case 'gps': {
        image = `${imageLocation}/gps.png`;
        break;
      }
      case 'tilt': {
        image = `${imageLocation}/tilt.png`;
        break;
      }
      case 'manhole': {
        image = `${imageLocation}/manhole.png`;
        break;
      }
      case 'manhole-true': {
        image = `${imageLocation}/manhole-open.png`;
        break;
      }
      case 'manhole-false': {
        image = `${imageLocation}/manhole.png`;
        break;
      }
      case 'accel': {
        image = `${imageLocation}/accel.png`;
        break;
      }
      case 'impact': {
        image = `${imageLocation}/iImpact.png`;
        break;
      }
      default: {
        break;
      }
    }
    return image;
  }
}
