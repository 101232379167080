import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-report-control',
  templateUrl: './report-control.component.html',
  styleUrls: ['./report-control.component.scss'],
})

export class ReportControlComponent implements OnInit {
  @Input() showPhase = true;
  @Output() public outputFilter: EventEmitter<any> = new EventEmitter();
  report: any;
  reportDetails;

  constructor(private formBuilder: FormBuilder, private router: Router) {
    this.setDataFromRouter();
  }

  ngOnInit(): void {
    this.initializeFrom();
  }

  hasError = (controlName: string, errorName: string) => this.report.controls[controlName].hasError(errorName);

  setReport(report) {
    report.fromDate = new DateService().dateWithFormat(report.fromDate, 'MM-dd-yyyy');
    report.toDate = new DateService().dateWithFormat(report.toDate, 'MM-dd-yyyy');
    this.outputFilter.emit(report);
  }

  private setDataFromRouter() {
    const data = this.router.getCurrentNavigation();

    if (data != null && data.extras.state != null) {
      this.reportDetails = data.extras.state.currentDetails;

      const fromDate = new Date(this.reportDetails.fromDate);
      const toDate = new Date(this.reportDetails.toDate);
      const phase = this.reportDetails.phase;

      this.setFromData(fromDate, toDate, phase);
    }
  }

  private initializeFrom() {
    const fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
    const toDate = new Date();
    const phase = 'a';
    this.setFromData(fromDate, toDate, phase);
  }

  private setFromData(from: Date, to: Date, phase: string) {
    this.report = this.formBuilder.group({
      fromDate: new FormControl(from, [Validators.required]),
      toDate: new FormControl(to, [Validators.required]),
      phase: new FormControl(phase, [Validators.required]),
    });

    this.setReport(this.report.value);
  }
}
