import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'src/app/components/project/project.service';
import { Project } from 'src/app/models/Project';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ClearsonicsService } from './clearsonics.service';

@Component({
  selector: 'app-clearsonics',
  templateUrl: './clearsonics.component.html',
  styleUrls: ['./clearsonics.component.scss'],
})

export class ClearsonicsComponent implements OnInit {
  project: Project = null;
  clearsonicsDetails = null;

  constructor(
    private projectService: ProjectService,
    private clearsonicsService: ClearsonicsService,
    private route: ActivatedRoute,
    private toastService: ToastService) {
  }

  ngOnInit() {
    const projectId = this.route.snapshot.paramMap.get('id');
    this.getProject(projectId);
    this.fetchSensors(projectId);
  }

  getProject(projectId: string) {
    this.projectService.getProject(projectId).subscribe(
      (project) => {
        this.project = project;
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch project details.');
      },
    );
  }

  refreshItem() {
    this.clearsonicsDetails = null;
    this.fetchSensors(this.project.id);
  }

  fetchSensors(projectId: string) {
    return null;
  }
}
