import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpHelperService } from 'src/app/shared/services/http.service';
import { LogService } from 'src/app/shared/services/log.service';

@Injectable({
  providedIn: 'root',
})

export class ReportService {
  appConfig: any;

  constructor(private httpService: HttpHelperService) {
  }

  getDataReportDevices(projectId: string): Observable<any> {
    return this.httpService.getDataReportDevices(projectId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getDataReportDevices', []);
        return throwError(err);
      }),
    );
  }

  getLampReportDevices(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getLampReportDevices(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLampReportDevices', []);
        return throwError(err);
      }),
    );
  }

  getMeterReportDevices(projectId: string, stationId: string): Observable<any> {
    return this.httpService.getMeterReportDevices(projectId, stationId)
    .pipe(
      catchError((err) => {
        LogService.handleError('getMeterReportDevices', []);
        return throwError(err);
      }),
    );
  }

  getLampReport(projectId: string, lampId: string, fromDate: string, toDate: string): Observable<any> {
    return this.httpService.getLampReport(projectId, lampId, fromDate, toDate)
    .pipe(
      catchError((err) => {
        LogService.handleError('getLampReport', []);
        return throwError(err);
      }),
    );
  }

  getDataReport(projectId: string, stationId: string, fromDate: string, toDate: string): Observable<any> {
    return this.httpService.getDataReport(projectId, stationId, fromDate, toDate)
    .pipe(
      catchError((err) => {
        LogService.handleError('getDataReport', []);
        return throwError(err);
      }),
    );
  }

  getMeterReport( phase: string, projectId: string, meterId: string, fromDate: string, toDate: string): Observable<any> {
    return this.httpService.getMeterReport(phase, projectId, meterId, fromDate, toDate)
    .pipe(
      catchError((err) => {
        LogService.handleError('getMeterReport', []);
        return throwError(err);
      }),
    );
  }
}
