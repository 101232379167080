<app-card *ngIf="errors">
  <div card-body class="errors">
    <h2>Errors:</h2>
    <mat-list>
      <mat-list-item *ngFor="let error of errors" class="errors">
        <mat-icon mat-list-icon>error</mat-icon>
        <div mat-line>{{error.field}} - {{error.error}} </div>
      </mat-list-item>
    </mat-list>
  </div>
</app-card>
