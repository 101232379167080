import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-group',
  templateUrl: './project-group.component.html',
})

export class ProjectGroupComponent implements OnInit {
  @Input() heading;
  @Input() items;
  columnDetails = [];

  constructor() {
  }

  ngOnInit() {
    this.columnDetails = [
      {
        key: 'name',
        value: 'Name',
        type: 'string',
      },
      {
        key: 'sync',
        value: 'Sync',
        type: 'boolean',
      },
      {
        key: 'stations',
        value: 'Stations',
        type: 'string',
      },
      {
        key: 'devices',
        value: 'Devices',
        type: 'string',
      },
    ];
  }
}
