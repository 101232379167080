import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-small',
  templateUrl: './button-small.component.html',
  styleUrls: ['./button-small.component.scss'],
})

export class ButtonSmallComponent {
  @Output() public outputClick: EventEmitter<any> = new EventEmitter();

  @Input() disabled = 'false';
  @Input() toolTip: string;
  @Input() cssClass: string;
  @Input() routerLink: string | any[];
  @Input() type: 'primary' | 'success' | 'danger' |  'warning' = 'primary';

  constructor() {
  }

  onClick() {
    this.outputClick.emit();
  }
}
