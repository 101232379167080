<div class="alarm-container">
  <h3>{{impactAlarm.sensorValue}} | {{impactAlarm.sensorName}}
    <small class="alarm-date">
    {{getDate(impactAlarm.createdAt)}}
    <span class="close" (click)="onOkClick()">X</span>
    </small>
  </h3>

  <!-- <mat-divider></mat-divider>
  <p> Category: {{getCategory(alarm.categoryId)}} | Hardware: {{alarm.hardware}} | Site: {{alarm.site}} </p>
  <p> Message: {{alarm.message}}
    <span *ngIf="alarm.note" class="note"> Note: {{alarm.note}} </span>
  </p> -->

  <!-- class="map-container" -->
  <div class="map-container">
    <app-gmap [markers]="markers" [mainMarker]="markers[0]" [zoom]=4></app-gmap>
  </div>
</div>
