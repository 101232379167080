import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { NotesService } from '../../notes.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ProjectService } from 'src/app/components/project/project.service';

@Component({
  selector: 'app-note-add',
  templateUrl: './note-add.component.html',
  styleUrls: ['./note-add.component.scss'],
})

export class NoteAddComponent {
  note;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<NoteAddComponent>,
    public projectService: ProjectService,
    public notesService: NotesService,
    private toastService: ToastService,
    private formBuilder: FormBuilder) {
      this.initializeFromData();
  }

  dismiss() {
    this.bottomSheetRef.dismiss();
  }

  initializeFromData() {
    this.note = this.formBuilder.group({
      message: new FormControl('', [Validators.required]),
      isAdmin: false,
      projectId: ''
    });
  }

  hasError = (controlName: string, errorName: string) => this.note.controls[controlName].hasError(errorName);

  setIsAdmin(value) {
    if(this.notesService.isAdmin()) {
      this.note.value.isAdmin = value;
    } else {
      this.note.value.isAdmin = false;
    }
  }

  createNew() {
    this.note.value.projectId = this.projectService.currentProject.id;
    this.notesService.addNote(this.note.value).subscribe(
      (newNote) => {
        this.toastService.showToast('success', 'Success', 'New note added.');
        this.dismiss();
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to add new note.');
      },
    );
  }
}
