import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-count',
  templateUrl: './count.component.html',
  styleUrls: ['./count.component.scss'],
})

export class CountComponent {
  @Input() name: string;
  @Input() count: number;
  @Input() showZero = false;

  constructor() {
  }
}
