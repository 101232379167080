import { Component } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})

export class TermsComponent {
  terms = [
    {
      heading: 'Term 1',
      details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in massa ut neque accumsan placerat.
                Vestibulum vel nunc nisi. Maecenas gravida arcu in leo tempor laoreet. Nullam placerat lacus nec orci lobortis
                ullamcorper ac eget neque. Praesent dignissim feugiat neque in vehicula. Aliquam fringilla, tellus at venenatis
                lobortis, purus mauris volutpat augue, ut sagittis dui elit dictum orci. Nunc sed sem at tortor hendrerit
                sollicitudin. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                Duis elementum sem in ante dapibus, ornare rhoncus enim ultricies. Cras eget porta tellus. Ut at sollicitudin libero.
                Quisque eget consectetur nibh. Morbi et fermentum eros.`,
    },
    {
      heading: 'Condition 1',
      details: `Nulla ut tortor ullamcorper, dapibus diam in, tempus sem. Maecenas varius, odio a fermentum placerat, ipsum odio semper
                neque, non pulvinar turpis arcu et est. Nam lobortis dolor vitae ante vestibulum commodo. Pellentesque posuere diam quis
                feugiat aliquam. Aenean scelerisque non turpis non aliquam. Phasellus eu venenatis ipsum. Nunc bibendum laoreet orci a
                porttitor. Suspendisse venenatis, turpis non commodo finibus, dui magna vehicula dui, in iaculis metus massa in nulla.
                Sed in sagittis purus. Suspendisse potenti. Nulla porta felis nec dolor maximus convallis. Suspendisse potenti.
                Donec ut leo risus. Suspendisse id luctus sapien.`,
    },
    {
      heading: 'Condition 2',
      details: `Donec non ultricies sem. Fusce eu egestas neque, eget viverra sapien. Vivamus mollis pulvinar iaculis.
                Sed feugiat elit neque. Curabitur fermentum dui eget enim pharetra, ac posuere ante ornare. Vestibulum porta augue non
                scelerisque vestibulum. Fusce eu porttitor augue. Cras at ornare nisl. Ut id quam eros. Fusce eu semper mi. Mauris luctus,
                tortor quis vestibulum faucibus, justo magna faucibus velit, eget suscipit risus sem a est. Donec eget pharetra justo.`,
    },
  ];

  constructor() {
  }
}
