import { NgModule } from '@angular/core';

import { ProjectModule } from 'src/app/components/project/project.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { NotesComponent } from './notes.component';
import { NoteComponent } from './components/note/note.component';
import { NoteAddComponent } from './components/note-add/note-add.component';

@NgModule({
  imports: [
    SharedModule,
    ProjectModule,
  ],
  declarations: [
    NotesComponent,
    NoteComponent,
    NoteAddComponent,
  ],
})

export class NotesModule {
}
