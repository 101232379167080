import { NgModule } from '@angular/core';

import { ProjectModule } from 'src/app/components/project/project.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { ClearsonicsComponent } from './clearsonics.component';

@NgModule({
  imports: [
    SharedModule,
    ProjectModule,
  ],
  declarations: [
    ClearsonicsComponent,
  ],
})

export class ClearsonicsModule {
}
