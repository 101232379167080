import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ServerService } from 'src/app/components/server/server.service';
import { ModelError } from 'src/app/models/ModelError';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-server-add',
  templateUrl: './server-add.component.html',
  styleUrls: ['./server-add.component.scss'],
})

export class ServerAddComponent implements OnInit {
  server: FormGroup;
  errorList: ModelError[];

  constructor(
    private serverService: ServerService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.initializeFromData();
  }

  initializeFromData() {
    this.server = this.formBuilder.group({
      serverName: new FormControl('', [Validators.required]),
      serverUrl: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  hasError = (controlName: string, errorName: string) => this.server.controls[controlName].hasError(errorName);

  createNew(server) {
    this.errorList = new Array();
    this.serverService.addServer(server.value).subscribe((newServer) => {
      this.toastService.showToast('success', 'Success!', 'New server created');
      this._router.navigate(['/server', newServer.id]);
    },
    (error) => {
      this.toastService.showToast('error', 'Error!', 'Failed to create new server.');
      this.errorList = ErrorService.parseError(error);
    });
  }
}
