import { Component,Input, OnInit } from '@angular/core';

import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';


@Component({
  selector: 'app-impact-sensor',
  templateUrl: './impact-sensor.component.html',
  styleUrls: ['./impact-sensor.component.scss']
})
export class ImpactSensorComponent implements OnInit {
  @Input() sensor: any;
  image = '';

  dataSource = [];
  displayedColumns: string[] = ['time', 'latitude', 'longitude'];
  constructor(private monitoringService: MonitoringService) {
  }

  ngOnInit() {
    this.setImage(this.sensor);
    this.setData(this.sensor.details.data);
  }

  setImage(sensor) {
    this.image = this.monitoringService.getImageType(sensor);
  }

  setData(sensorData) {
  }

}
