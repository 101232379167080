<mat-card class="option-card">
  <div class="button-wrapper" (click)="collapse()" [matTooltip]="toolTip">
    <i [ngClass]="cssClass"></i>
  </div>

  <div *ngIf="!collapsed">
    <mat-card-header>
      <mat-card-subtitle>{{heading}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="options">
      <ng-content ng-class="option" select="[options]"></ng-content>
    </mat-card-content>
  </div>
</mat-card>
