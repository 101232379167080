
<app-project-header icon="fas fa-map-marked" heading="Map" [project]="project"></app-project-header>

<app-loading [check]="project" color="primary"></app-loading>

<app-option-panel heading="Options">
  <app-button-small cssClass="fas fa-home" type="primary" [routerLink]="['/project/home', project?.id]" toolTip="Home" options></app-button-small>
  <app-back-button [round]="true" options></app-back-button>
</app-option-panel>

<div class="container">
  <app-map-tools (outputMarkerFilter)="filterMarker($event)" (outputSensorFilter)="filterSensor($event)"></app-map-tools>
</div>

<div class="row" *ngIf="project" >
  <div class="map-container">
    <app-gmap [markers]="markers" [mainMarker]="mainMarker" [zoom]=4></app-gmap>
  </div>
</div>
