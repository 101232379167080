import { Component, Input, OnInit } from '@angular/core';

import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-tilt-sensor',
  templateUrl: './tilt-sensor.component.html',
  styleUrls: ['./tilt-sensor.component.scss'],
})

export class TiltSensorComponent implements OnInit {
  @Input() sensor: any;
  image = '';

  dataSource = [];
  displayedColumns: string[] = ['time', 'accel', 'gyro', 'mag'];
  constructor(private monitoringService: MonitoringService, private dateService: DateService) {
  }

  ngOnInit() {
    this.setImage(this.sensor);
    this.setData(this.sensor.details.data);
  }

  setImage(sensor) {
    this.image = this.monitoringService.getImageType(sensor);
  }

  setData(sensorData) {
  }
}
