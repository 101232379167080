import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-images',
  templateUrl: './home-images.component.html',
  styleUrls: ['./home-images.component.scss'],
})

export class HomeImagesComponent implements OnInit {
  images = [];
  allImages = [
    'assets/images/sensors/temp.png',
    'assets/images/sensors/bin.png',
    'assets/images/sensors/gps.png',
    'assets/images/sensors/pressure.png',
    'assets/images/sensors/humidity.png',
    'assets/images/sensors/rain.png',
    'assets/images/sensors/sound.png',
    'assets/images/sensors/tilt.png',
  ];

  constructor() {
  }

  ngOnInit() {
    this.images = this.allImages.sort(this.random);
  }

  private random() {
    return 0.5 -  Math.random();
  }
}
