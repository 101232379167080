<app-page-title icon="fas fa-building" title="Server : {{server?.serverName}}"></app-page-title>

<div class="container">
  <app-loading [check]="server" color="primary"></app-loading>
  <div class="row" *ngIf="server">
    <app-option-panel heading="Server options">
      <app-button-small *ngIf="serverService.showAddServer()" cssClass="fas fa-plus" type="success" [routerLink]="['/server/new']" toolTip="Add new server" options></app-button-small>
      <app-button-small cssClass="fas fa-redo-alt" type="info" (outputClick)="fetchServer()" toolTip="Refresh server" options></app-button-small>
      <app-button-small *ngIf="serverService.showDeleteServer()" cssClass="fas fa-trash" type="danger" (outputClick)="openDeleteDialog()" toolTip="Delete server" options></app-button-small>
      <app-back-button [round]="true" options></app-back-button>
    </app-option-panel>

    <div class="col-md-6">
      <app-card [heading]="server.serverName">
        <div card-body>
          <h5 class="server-details">URL: <span><a href="{{server.serverUrl}}" target="_blank">{{server.serverUrl}}</a></span></h5>
          <h5 class="server-details">Username: <span>{{server.username}}</span></h5>
        </div>
      </app-card>
    </div>
  </div>

  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>
      <button *ngIf="serverService.showAddServer()" mat-raised-button color="success" aria-label="Add new server" [routerLink]="['/server/new']">
        Add new server
      </button>
    </div>
  </app-button-panel>
</div>
