<div class="alarm-container">
  <h3>{{alarm.alarmLevel}} | {{alarm.alarmType}}
    <small class="alarm-date">
    {{getDate(alarm.alarmDate)}}
    <span class="close" (click)="onOkClick()">X</span>
    </small>
  </h3>

  <mat-divider></mat-divider>
  <p> Category: {{getCategory(alarm.categoryId)}} | Hardware: {{alarm.hardware}} | Site: {{alarm.site}} </p>
  <p> Message: {{alarm.message}}
    <span *ngIf="alarm.note" class="note"> Note: {{alarm.note}} </span>
  </p>

  <div class="map-container">
    <app-gmap [markers]="markers" [mainMarker]="mainMarker"></app-gmap>
  </div>
</div>
