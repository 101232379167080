import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sensor-info',
  templateUrl: './sensor-info.component.html',
  styleUrls: ['./sensor-info.component.scss'],
})

export class SensorInfoComponent {
  sensor;

  constructor(
    public dialogRef: MatDialogRef<SensorInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.sensor = data.sensor;
    }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }
}
