import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-alarm-control',
  templateUrl: './alarm-control.component.html',
  styleUrls: ['./alarm-control.component.scss'],
})

export class AlarmControlComponent implements OnInit {
  @Output() public outputFilter: EventEmitter<any> = new EventEmitter();
  report: any;
  reportDetails;

  constructor(private formBuilder: FormBuilder, private router: Router) {
  }

  ngOnInit(): void {
    this.initializeFrom();
  }

  hasError = (controlName: string, errorName: string) => this.report.controls[controlName].hasError(errorName);

  setReport(report) {
    report.fromDate = new DateService().dateWithFormat(report.fromDate, 'MM-dd-yyyy');
    report.toDate = new DateService().dateWithFormat(report.toDate, 'MM-dd-yyyy');
    this.outputFilter.emit(report);
  }

  private initializeFrom() {
    const fromDate = new Date(new Date().setDate(new Date().getDate() - 120));
    const toDate = new Date();
    this.setFromData(1, 500, fromDate, toDate);
  }

  private setFromData(page: number, count: number, from: Date, to: Date) {
    this.report = this.formBuilder.group({
      fromDate: new FormControl(from, [Validators.required]),
      toDate: new FormControl(to, [Validators.required]),
      page: new FormControl(page, [Validators.required]),
      count: new FormControl(count, [Validators.required]),
    });

    this.setReport(this.report.value);
  }
}
