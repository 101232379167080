import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-temperature-info',
  templateUrl: './temperature-info.component.html',
  styleUrls: ['./temperature-info.component.scss'],
})

export class TemperatureInfoComponent {
  sensor;
  sensorData;
  name;
  dateTime;
  dateService =  new DateService();

  constructor(
    public dialogRef: MatDialogRef<TemperatureInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.sensor = data.sensor;
      this.sensorData = data.sensorData;
      this.name = data.name;
      if (this.sensor) {
        this.dateTime = this.dateService.fullDateWithTime(this.sensor.time);
      }
    }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }
}
