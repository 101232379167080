import { Component } from '@angular/core';

@Component({
  selector: 'app-button-panel',
  templateUrl: './button-panel.component.html',
  styleUrls: ['./button-panel.component.scss'],
})

export class ButtonPanelComponent {
  constructor() {
  }
}
