/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from './models/User';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn: boolean = null;
  user: User;

  private userProfileSubject$ = new BehaviorSubject<any>(null);
  private handleAuthCallbackCompleteSubject$ = new BehaviorSubject<boolean>(false);
  handleAuthCallbackComplete$ = this.handleAuthCallbackCompleteSubject$.asObservable();

  constructor(private router: Router) {
    this.localAuthSetup();
    this.handleAuthCallback();
  }

  auth0Client$ = (from(
    createAuth0Client({
      domain: `${environment.auth0Domain}`,
      client_id: `${environment.auth0Client}`,
      redirect_uri: `${window.location.origin}`,
      audience: `${environment.bffAudience}`,
    }),
  ) as Observable<Auth0Client>).pipe(
    catchError((err) => throwError(err)),
  );

  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap((res) => this.loggedIn = res),
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback())),
  );

  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options))),
    );
  }

  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap((user) => this.userProfileSubject$.next(user)),
    );
  }

  login(redirectPath: string = '/') {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}`,
        appState: { target: redirectPath },
      });
    });
  }

  logout() {
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.logout({
        client_id: `${environment.auth0Client}`,
        returnTo: `${window.location.origin}`,
      });
    });
  }

  private setUser() {
    this.getUser$().subscribe((userObject) => this.assignUser(userObject));
  }

  private assignUser(userObject) {
    this.user = new User(userObject.email, userObject.email_verified, userObject.name,
      userObject.nickname, userObject.picture, null, []);
    this.setOtherUserDetailsFromToken();
  }

  private setOtherUserDetailsFromToken() {
    this.getTokenSilently$().subscribe((token) => {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      this.user.meta = decodedToken['https://tstmax'];
      this.user.permissions = decodedToken.permissions;
    });
  }

  private localAuthSetup() {
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          this.setUser();
          return this.getUser$();
        }
        return of(loggedIn);
      }),
    );
    checkAuth$.subscribe();
  }

  private handleAuthCallback() {
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string;
      const authComplete$ = this.handleRedirectCallback$.pipe(
        tap((cbRes) => {
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => combineLatest([
            this.getUser$(),
            this.isAuthenticated$,
          ])),
      );

      authComplete$.subscribe(([user, loggedIn]) => {
        this.handleAuthCallbackCompleteSubject$.next(true);
        this.assignUser(user);
        this.router.navigate([targetRoute]);
      });
    } else {
      this.handleAuthCallbackCompleteSubject$.next(true);
    }
  }
}
