import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';

export const APP_CONFIG = new InjectionToken('app.config');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AppConfig: any = {
  routes: {
    home: '',
    contact: 'contact',
    faq: 'faq',
    terms: 'terms-and-conditions',
    privacy: 'privacy',
    cookie: 'cookies-policy',
    clients: 'clients',
    clientProject: 'clientProject/:clientName',
    projects: 'projects',
    projectNew: 'project/new',
    projectEdit: 'project/edit/:id',
    projectHome: 'project/home/:id',
    projectLighting: 'project/lighting/:id',
    projectEnergy: 'project/energy/:id',
    projectLights: 'project/lights/:id',
    projectLightingStation: 'project/lighting/:id/station/:stationId',
    projectLightingLamp: 'project/lighting/:id/lamp/:lampId',
    projectLightingMap: 'project/lighting/:id/map',
    projectEnvironment: 'project/environment/:id',
    projectMonitoring: 'project/monitoring/:id',
    monitoringCameraList: 'project/monitoring/:id/cameras',
    projectClearsonics: 'project/clearsonics/:id',
    projectVas: 'project/vas/:id',
    projectTtms: 'project/ttms/:id',
    projectCctv: 'project/cctv/:id',
    projectImonitor: 'project/imonitor/:id',
    projectMap: 'project/map/:id',
    projectAlarm: 'project/alarm/:id',
    projectAlarmReport: 'project/alarm/report/:id',
    projectReport: 'project/report/:id',
    projectLampReport: 'project/report/lamp/:id',
    projectMeterReport: 'project/report/meter/:id',
    projectDataReport: 'project/report/data/:id',
    projectNotes: 'project/notes/:id',
    servers: 'servers',
    serversNew: 'server/new',
    serversView: 'server/:id',
    lightingAvailable: 'project/lighting/available/:id',
    lightingStationList: 'project/lighting/:id/stations',
    lightingRTUList: 'project/lighting/:id/rtus',
    lightingMeterList: 'project/lighting/:id/meters',
    lightingLuxList: 'project/lighting/:id/luxs',
    lightingLampList: 'project/lighting/:id/lamps',
    lightingLCUList: 'project/lighting/:id/lcus',
    lightingDIList: 'project/lighting/:id/dis',
    lightingAntiTheftList: 'project/lighting/:id/anti-thefts',
    error404: 'not-found',
    error401: 'unauthorized',
  },
  mainMenu: [
    { link: '/', name: 'Home', icon: 'fas fa-home' },
    { link: '/clients', name: 'Projects', icon: 'fas fa-file' },
   // { link: '/projects', name: 'Projects', icon: 'fas fa-file' },
    { link: '/servers', name: 'Servers', icon: 'fas fa-building' },
  ],
  subMenu: [
    { link: '/contact', name: 'Contact Us', icon: 'fas fa-phone' },
    { link: '/faq', name: 'FAQ', icon: 'fas fa-question' },
    { link: '/terms-and-conditions', name: 'T&Cs', icon: 'fas fa-exclamation' },
    { link: '/privacy', name: 'Privacy', icon: 'fas fa-key' },
    { link: '/cookies-policy', name: 'Cookies policy', icon: 'fas fa-cookie-bite' },
  ],
  projectMenu: [
    { link: '/project/home/', name: 'Home', icon: 'fas fa-home', disabled: true },
    { link: '/project/energy/', name: 'Energy', icon: 'fas fa-plug' },
    { link: '/project/lights/', name: 'Lights', icon: 'fas fa-lightbulb' },
    { link: '/project/environment/', name: 'Environment', icon: 'fas fa-globe-asia'},
    { link: '/project/imonitor/', name: 'Imonitor', icon: 'fas fa-car-crash'},
    //{ link: '/project/monitoring/', name: 'Monitoring', icon: 'fas fa-camera' },
    { link: '/project/vas/', name: 'Vas', icon: 'fas fa-tachometer-alt' },
    { link: '/project/clearsonics/', name: 'Clearsonics', icon: 'fas fa-phone', disabled: true },
    { link: '/project/ttms/', name: 'TTMS', icon: 'fas fa-traffic-light' },
    //{ link: '/project/map/', name: 'Map', icon: 'fas fa-map-marked' },
    { link: '/project/alarm/', name: 'Alarm', icon: 'fas fa-bell', disabled: true },
    { link: '/project/cctv/', name: 'CCTV', icon: 'fas fa-video' },
    //{ link: '/project/report/', name: 'Report', icon: 'far fa-chart-bar' },
    { link: '/project/notes/', name: 'Notes', icon: 'fas fa-comments' },
  ],
  endpoints: {
    projectEndpoint: `${environment.bffURL}/project`,
    clientProjectEndpoint: `${environment.bffURL}/client`,
    dataSensorEndpoint: `${environment.bffURL}/datasensor`,
    lampEndpoint: `${environment.bffURL}/lamp`,
    lampDbEndpoint: `${environment.bffURL}/lampDb`, 
    stationEndpoint: `${environment.bffURL}/station`,
    stationDbEndpoint: `${environment.bffURL}/stationDb`,
    lcuEndpoint: `${environment.bffURL}/lcu`,
    diEndpoint: `${environment.bffURL}/di`,
    meterEndpoint: `${environment.bffURL}/meter`,
    antiTheftEndpoint: `${environment.bffURL}/antiTheft`,
    rtuEndpoint: `${environment.bffURL}/rtu`,
    luxEndpoint: `${environment.bffURL}/lux`,
    cameraEndpoint: `${environment.bffURL}/camera`,
    sensorEndpoint: `${environment.bffURL}/sensor`,
    serverEndpoint: `${environment.bffURL}/server`,
    alarmEndpoint: `${environment.bffURL}/alarm`,
    impactAlarmEndpoint: `${environment.bffURL}/DeviceSensorHistory`,
    reportEndpoint: `${environment.bffURL}/report`,
    lampReportEndpoint: `${environment.bffURL}/report/lamp`,
    meterReportEndpoint: `${environment.bffURL}/report/meter`,
    dataReportEndpoint: `${environment.bffURL}/report/data`,
    poleEndpoint: `${environment.bffURL}/pole`,
    lightingGraphEndpoint: `${environment.bffURL}/dashboard/lighting`,
    environmentSensorEndpoint: `${environment.bffURL}/dashboard/environment`,
    monitoringSensorEndpoint: `${environment.bffURL}/dashboard/monitoring`,
    energyPerMonth: `${environment.bffURL}/energy/month`,
    energyPerYear: `${environment.bffURL}/energy/year`,
    energyLow: `${environment.bffURL}/energy/low`,
    energyHigh: `${environment.bffURL}/energy/high`,
    mapItem: `${environment.bffURL}/mapitem`,
    group: `${environment.bffURL}/group`,
    notes: `${environment.bffURL}/note`,
    device: `${environment.bffURL}/device`,
  },
  repositoryURL: 'https://github.com/Trafficltd/TSTMaxUI',
};
