import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class DateService {
  datePipe: DatePipe = new DatePipe('en');

  fullDateWithTime(dateString: string) {
    let date = this.dateFromString(dateString);

    if (!this.isValid(date)) {
      date = this.dateFromUnixDate(dateString);
    }

    if (!this.isValid(date)) {
      return '';
    } else {
      return this.datePipe.transform(new Date(date), 'dd-MM-yyyy hh:mm');
    }
  }

  shortDateWithTime(date: string) {
    return this.datePipe.transform(new Date(date), 'dd-MM hh:mm');
  }

  dateFromString(date: string) {
    return new Date(date);
  }

  dateWithTime(unixDate) {
    return this.datePipe.transform(this.dateFromUnixDate(unixDate), 'dd-MM-yyyy hh:mm a');
  }

  shortDateFromUnixDate(unixDate) {
    return this.datePipe.transform(this.dateFromUnixDate(unixDate), 'dd-MMM');
  }

  dateWithFormat(date: string, format: string) {
    return this.datePipe.transform(new Date(date), format);
  }

  isValid(date: Date) {
    return !isNaN(date.getTime());
  }

  private dateFromUnixDate(unixDate) {
    return new Date(parseInt(unixDate, 10));
  }
}
