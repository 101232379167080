export class Camera {
  constructor(public modelId: string,
              public projectId: string,
              public model: string,
              public isDefault: boolean,
              public remark: string,
              public code: string,
              public protocol: string) {
  }
}
