<div class="lighting-list-container">
  <app-sub-section [heading]="heading" [length]="data?.length" [timestamp]="timestamp" (outputRefresh)="refresh()"></app-sub-section>

  <cdk-virtual-scroll-viewport [itemSize]="1" class="viewport-30" *ngIf="data.length > 0">
    <div class="row">
      <ng-container *cdkVirtualFor="let camera  of data">
        <div class="col-md-3">
          <app-camera-connected [camera]="camera"></app-camera-connected>
        </div>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
