import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-stat',
  templateUrl: './current-stat.component.html',
})

export class CurrentStatComponent implements OnInit {
  @Input() heading;
  @Input() items;
  @Input() projectId;
  columnDetails = [];

  constructor() {
  }

  ngOnInit() {
    this.columnDetails = [
      {
        key: 'time',
        value: 'Time',
        type: 'string',
      },
      {
        key: 'currentA',
        value: 'Phase A',
        type: 'string',
      },
      {
        key: 'currentB',
        value: 'Phase B',
        type: 'string',
      },
      {
        key: 'currentC',
        value: 'Phase C',
        type: 'string',
      },
      {
        key: 'stationId',
        value: 'Station',
        type: 'button',
        label: 'View station',
        link: `/project/lighting/${this.projectId}/station`
      },
    ];
  }
}
