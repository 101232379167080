import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Camera } from 'src/app/models/Camera';

@Component({
  selector: 'app-cameras',
  templateUrl: './cameras.component.html',
})

export class CamerasComponent {
  @Output() public outputFilter: EventEmitter<any> = new EventEmitter();

  @Input() heading: string;
  @Input() data: Camera[];
  @Input() timestamp: Date;

  constructor() {
  }

  refresh() {
    this.outputFilter.emit();
  }
}
