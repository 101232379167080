<app-page-title icon="fas fa-cookie-bite" title="Cookies policy"></app-page-title>

<div class="container">
  <div class="row error-container">
    <div class="col-md-12">
      <h2>Cookies Policy</h2>
      <p>By agreeing to the statement found in the cookies agreement popup at the bottom of our website, you agree to the use of cookies by us when using our website.</p>

      <h2>Cookies - What Are They:</h2>
      <p>Cookies are small text files that are dynamically created and placed on the device you use when you view our website.
      These text files have a small amount of data in them, the data is specific to a device and visited website.
      The files can be accessed by the web server or user / visitors device.
      Cookies are used in a multitude of ways, as an example: a cookie can be used to store user behaviour or collect browser data.</p>

      <h2>Cookies Explained:</h2>
      <mat-list>
        <mat-list-item> <span class="bold-text">Session cookies: </span>&nbsp;a.k.a "transient", this cookie type is deleted when the browser is closed.</mat-list-item>
        <mat-list-item><span class="bold-text">Persistent cookies: </span>&nbsp;a.k.a "permanent" or "stored", this cookie type is stored on the users device until it expires or is manually deleted.</mat-list-item>
        <mat-list-item><span class="bold-text">First party cookies: </span>&nbsp;This cookie type is created by the website being visited.</mat-list-item>
        <mat-list-item><span class="bold-text">Third party cookies: </span>&nbsp;This cookie type is created by external websites not belonging to the visited site.</mat-list-item>
      </mat-list>
      <p>TSTMax uses only <span class="bold-text">Third party cookies</span></p>

      <h2>Third Party Services:</h2>
      <p>As of writing this statement, the third party services featured in this website are from:</p>
      <mat-list>
        <mat-list-item>
          <span class="bold-text">Google</span>
          &nbsp;
          <a target="_blank" rel="nofollow noopener" href="https://policies.google.com/technologies/cookies?hl=en-US">Read more</a>
        </mat-list-item>
        <mat-list-item>
          <span class="bold-text">Auth0</span>
          &nbsp;
          <a target="_blank" rel="nofollow noopener" href="https://auth0.com/privacy">Read more</a>
        </mat-list-item>
      </mat-list>
      <p>TSTMax does not have access to nor can we manage or control cookies created by external services.</p>


      <h2>How To Disable Cookies in Your Browser</h2>
      <p>If you don't wish to store cookies on any of your devices you can stop websites from doing so, by simply changing your browser settings in each of your devices.
        Please click on the appropriate link below to read the support guide for your specific browser of choice:
      </p>

      <mat-list>
        <mat-list-item><a target="_blank" rel="nofollow noopener" href="//support.mozilla.org/en-US/kb/block-websites-storing-site-preferences">Firefox</a></mat-list-item>
        <mat-list-item><a target="_blank" rel="nofollow noopener" href="//help.opera.com/en/latest/web-preferences/#cookies">Opera</a></mat-list-item>
        <mat-list-item><a target="_blank" rel="nofollow noopener" href="//support.apple.com/kb/PH17191">Safari</a></mat-list-item>
        <mat-list-item><a target="_blank" rel="nofollow noopener" href="//support.google.com/chrome/answer/95647">Chrome</a></mat-list-item>
        <mat-list-item><a target="_blank" rel="nofollow noopener" href="//support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">IE</a></mat-list-item>
        <mat-list-item><a target="_blank" rel="nofollow noopener" href="//privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">Edge</a></mat-list-item>
      </mat-list>
      <h2>Important Note</h2>
      <p>If you set your browser to not accept cookies, it might cause our website to not function or be displayed incorrectly.</p>
    </div>

    <div class="col-md-12">
      <app-button-panel>
        <div buttons>
          <app-back-button></app-back-button>
        </div>
      </app-button-panel>
    </div>
  </div>
</div>
