<div class="row">
  <div *ngFor="let value  of values" class="col-md-2 widget">
    <app-temperature-widget
    [value]="value.value"
    [unit]="value.unit"
    [image]="value.image"
    [cssClass]="value.cssClass">
  </app-temperature-widget>
  </div>
</div>
