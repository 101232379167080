<app-card heading="Filter reports">
  <div card-body>
    <form [formGroup]="report" (ngSubmit)="setReport(report.value)" class="form-body">
      <mat-form-field color="accent" appearance="fill">
        <mat-label>From</mat-label>
        <input matInput [matDatepicker]="fromDate" formControlName="fromDate">
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker #fromDate color="primary"></mat-datepicker>
        <mat-error *ngIf="hasError('fromDate', 'required')">From date is required</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label>To</mat-label>
        <input matInput [matDatepicker]="toDate" formControlName="toDate">
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate color="primary"></mat-datepicker>
        <mat-error *ngIf="hasError('toDate', 'required')">To date is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="showPhase === true">
        <mat-select formControlName="phase">
          <mat-option value="a">Phase I</mat-option>
          <mat-option value="b">Phase II</mat-option>
          <mat-option value="c">Phase III</mat-option>
        </mat-select>
        <mat-label><i class="fas fa-bolt"></i> <b> Select</b> <i> phase</i></mat-label>
        <mat-error *ngIf="hasError('phase', 'required')">Phase is required</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit" [disabled]="!report.valid">Apply</button>
    </form>
  </div>
</app-card>
