import { Component, Input } from '@angular/core';

import { ModelError } from 'src/app/models/ModelError';

@Component({
  selector: 'app-error-panel',
  templateUrl: './error-panel.component.html',
  styleUrls: ['./error-panel.component.scss'],
})

export class ErrorPanelComponent {
  @Input() errors: ModelError[];

  constructor() {
  }
}
