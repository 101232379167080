import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ReportDevice } from 'src/app/models/ReportDevice';
import { Station } from 'src/app/models/Station';

@Component({
  selector: 'app-report-devices-select',
  templateUrl: './report-devices-select.component.html',
  styleUrls: ['./report-devices-select.component.scss'],
})

export class ReportDevicesSelectComponent {
  @Input() heading: string;
  @Input() ready: boolean;
  @Input() stations: Station[];
  @Input() devices: ReportDevice[];
  @Output() public outputStationFilter: EventEmitter<any> = new EventEmitter();
  @Output() public outputDeviceFilter: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  setDevice(device) {
    this.outputDeviceFilter.emit(device);
  }

  setStation(device) {
    this.outputStationFilter.emit(device);
  }
}
