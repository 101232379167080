import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Label } from 'ng2-charts';

import { Station } from 'src/app/models/Station';
import { LampReport } from 'src/app/models/LampReport';
import { ReportDevice } from 'src/app/models/ReportDevice';
import { ChartService } from 'src/app/shared/services/chart.service';
import { DateService } from 'src/app/shared/services/date.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { LightingService } from 'src/app/components/project/components/lighting/lighting.service';
import { ReportService } from 'src/app/components/project/components/report/report.service';

@Component({
  selector: 'app-report-lamp',
  templateUrl: './report-lamp.component.html',
  styleUrls: ['./report-lamp.component.scss'],
})

export class ReportLampComponent implements OnInit {
  fetchingReport = false;
  reportDetails = null;
  reportDevice: ReportDevice;
  reportDevices: ReportDevice[] = [];
  station: Station;
  stations: Station[] = [];
  reports: LampReport[] = [];
  deviceReady = false;

  multiBarOption = ChartService.multibarChartOptions;
  barOption = ChartService.barChartOptions;
  labels: Label[] = [];
  energyData;
  powerData;
  powerFacteData;

  columnDetails = [
    {
      key: 'voltage',
      value: 'Voltage',
      type: 'string',
    },
    {
      key: 'current',
      value: 'Current',
      type: 'string',
    },
    {
      key: 'powerFacte',
      value: 'Power facte',
      type: 'string',
    },
    {
      key: 'energy',
      value: 'Energy',
      type: 'string',
    },
    {
      key: 'power',
      value: 'Power',
      type: 'string',
    },
    {
      key: 'time',
      value: 'Time',
      type: 'shortDate',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private lightingService: LightingService,
    private reportService: ReportService,
    private toastService: ToastService) {

  }

  ngOnInit(): void {
    this.fetchStations(this.route.snapshot.paramMap.get('id'));
  }

  setDevice(device) {
    this.reportDevice = device;
    this.showReport();
  }

  setStation(station) {
    this.station = station;
    this.deviceReady = false;
    this.toastService.showToast('info', 'Information!', 'Please select a device.');
    this.fetchReportDevices(this.route.snapshot.paramMap.get('id'), station.stationId);
  }

  setFilter(value) {
    this.reportDetails = value;
    this.showReport();
  }

  resetDevice() {
    this.reportDevice = null;
    this.station = null;
    this.reportDevices = [];
    this.reports = [];
  }

  showReport() {
    this.reports = [];
    const projectId = this.route.snapshot.paramMap.get('id');

    if (this.station ==  null) {
      this.toastService.showToast('info', 'Information!', 'Please select a station.');
    }

    if (this.station != null && this.reportDevice ==  null) {
      this.toastService.showToast('info', 'Information!', 'Please select a device.');
    }

    if (this.reportDetails ==  null) {
      this.toastService.showToast('info', 'Information!', 'Please select filter from above.');
    }

    if (this.reportDevice != null && this.reportDetails != null) {
      const fromDate = new DateService().dateWithFormat(this.reportDetails.fromDate, 'dd-MM-yyyy');
      const toDate = new DateService().dateWithFormat(this.reportDetails.toDate, 'dd-MM-yyyy');
      this.fetchReport(projectId, this.reportDevice.deviceId, fromDate, toDate);
    }
  }

  private fetchReport(projectId: string, lampId: string, fromDate: string, toDate: string) {
    this.fetchingReport = true;
    this.reportService.getLampReport(projectId, lampId, fromDate, toDate).subscribe(
      (reports) => {
        this.fetchingReport = false;
        this.reports = this.refactorReportsData(reports);
        this.generateGraphData(this.reports);
        this.toastService.showEmptyResponse('No data', reports.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch report.');
      },
    );
  }

  private fetchStations(projectId: string){
    this.lightingService.getStations(projectId).subscribe(
      (devices) => {
        this.stations = devices;
        this.deviceReady = true;
        this.toastService.showEmptyResponse('No stations', devices.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch stations.');
      },
    );
  }

  private fetchReportDevices(projectId: string, stationId: string) {
    this.reportService.getLampReportDevices(projectId, stationId).subscribe(
      (devices) => {
        this.reportDevices = devices;
        this.deviceReady = true;
        this.toastService.showEmptyResponse('No devices', devices.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch devices.');
      },
    );
  }

  private generateGraphData(reportData) {
    this.labels = reportData.map((report: LampReport) => report.time);
    this.energyData = [
      {data: reportData.map((report: LampReport) => report.voltage) as number[], label: 'Voltage(V)', ...ChartService.greenChart},
      {data: reportData.map((report: LampReport) => report.current), label: 'Current(A)', yAxisID: 'y-axis-1', ...ChartService.oliveChart},
    ];

    this.powerData = [
      {data: reportData.map((report: LampReport) => report.energy), label: 'Energy(kWh)', ...ChartService.blueChart},
      {data: reportData.map((report: LampReport) => report.power), label: 'Power(W)', yAxisID: 'y-axis-1', ...ChartService.redChart},
    ];

    this.powerFacteData = [
      {data: reportData.map((report: LampReport) => report.powerFacte), label: 'Power facte', ...ChartService.blueStaleChart},
    ];
  }

  private refactorReportsData(reports: any[]): LampReport[] {
    return reports.map((report) =>  {
      report.power = report.power * 100;
      report.energy = report.energy / 1000;
      return report;
   });
  }
}
