import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { ProjectService } from 'src/app/components/project/project.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.scss'],
})

export class ProjectAddComponent {
  project: any;

  constructor(
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private router: Router) {
    this.initializeFromData();
  }

  initializeFromData() {
    this.project = this.formBuilder.group({
      projectName: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      latitude: new FormControl('', [Validators.required]),
      longitude: new FormControl('', [Validators.required]),
    });
  }

  hasError = (controlName: string, errorName: string) => this.project.controls[controlName].hasError(errorName);

  createNew(project) {
    this.projectService.newProject(project).subscribe(
      (newProject) => {
        this.router.navigateByUrl(`/project/home/${newProject.id}`);
        this.toastService.showToast('success', 'Success', 'New project added.');
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to add new project.');
      },
    );
  }
}
