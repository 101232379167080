import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { APP_CONFIG } from 'src/app/configs/app.config';

@Component({
  selector: 'app-project-menu',
  templateUrl: './project-menu.component.html',
  styleUrls: ['./project-menu.component.scss'],
})

export class ProjectMenuComponent implements OnInit {
  @Input() id: string;

  appConfig: any;
  menuItems: any[];
  component = '';
  buttonClassActive = 'menu-button-active mat-button-base';
  buttonClassInActive = 'mat-button-base';

  constructor(@Inject(APP_CONFIG) appConfig: any, private route: ActivatedRoute) {
    this.appConfig = appConfig;
    this.route.data.subscribe((data) => this.component = data.component);
  }

  ngOnInit() {
    this.loadMenus();
  }

  private loadMenus(): void {
    this.menuItems = this.appConfig.projectMenu.map( (item) => {
      let buttonClass = this.buttonClassInActive;

      if (item.name.toLowerCase() === this.component) {
        buttonClass = this.buttonClassActive;
      }

      return {
        ...item,
        link : item.link + this.id,
        buttonClass,
      };
    });
  }
}
