<div class="container">
  <app-page-title image="assets/images/sensors/temp.png" title="Data Reports"></app-page-title>

  <app-option-panel heading="Options">
    <app-back-button [round]="true" options></app-back-button>
  </app-option-panel>

  <div class="row">
    <div class="col-md-8">
      <app-report-control (outputFilter)="setFilter($event)" heading="Filter report" showPhase="false"></app-report-control>
    </div>

    <div class="col-md-4 device-container" *ngIf="reportDevice">
      <app-card heading="{{reportDevice.name}} ({{reportDevice.type}})" [subHeading]="reportDevice.stationName">
        <div card-body>
          <app-loading [check]="!fetchingReport" color="primary"></app-loading>
          <button mat-raised-button color="primary" (click)="showReport()">Show report</button>
          <button mat-raised-button color="accent" (click)="resetDevice()">Clear</button>
        </div>
      </app-card>
    </div>

    <div class="col-md-4" *ngIf="!reportDevice">
      <app-report-devices-select (outputDeviceFilter)="setDevice($event)" [devices]="reportDevices" [ready]="deviceReady"></app-report-devices-select>
    </div>
  </div>

  <div class="row" *ngIf="reports.length>0">
    <mat-tab-group color="accent" backgroundColor="primary">
      <mat-tab label="Graph">
        <div class="row">
          <div class="col-md-6">
            <app-bar-chart heading="Temperature" [data]="tempData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="Pressure" [data]="pressureData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="Humidity" [data]="humidityData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="Noise" [data]="noiseData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="UV" [data]="uvData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="Rain" [data]="rainData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="Lux" [data]="luxData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="Radiation" [data]="radiationData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="Wind speed" [data]="windSpeedData" [labels]="labels" [options]="barOption"></app-bar-chart>
          </div>

          <div class="col-md-6">
            <app-bar-chart heading="Particulate matter" [data]="pmData" [labels]="labels" [options]="multiBarOption"></app-bar-chart>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Grid">
        <app-table [items]="reports" [columnDetails]="columnDetails"></app-table>
      </mat-tab>
    </mat-tab-group>
  </div>

  <app-button-panel>
    <div buttons>
      <app-back-button></app-back-button>
    </div>
  </app-button-panel>
</div>
