import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AlarmService } from 'src/app/components/project/components/alarm/alarm.service';
import { ProjectService } from 'src/app/components/project/project.service';
import { IMapMarker } from 'src/app/interfaces/IMapMarker';
import { Alarm } from 'src/app/models/Alarm';
import { Project } from 'src/app/models/Project';
import { DateService } from 'src/app/shared/services/date.service';
import { MarkerService } from 'src/app/shared/services/marker.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-alarm-info',
  templateUrl: './alarm-info.component.html',
  styleUrls: ['./alarm-info.component.scss'],
})

export class AlarmInfoComponent implements OnInit {
  alarm: Alarm;
  project: Project;
  markers: IMapMarker[];
  mainMarker: IMapMarker;

  constructor(
    private alarmService: AlarmService,
    private projectService: ProjectService,
    private toastService: ToastService,
    private markerService: MarkerService,
    public dialogRef: MatDialogRef<AlarmInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.alarm = data.alarm;
      this.project = this.projectService.currentProject;
    }

  ngOnInit() {
    this.fetchPoles();
  }

  onOkClick(): void {
    this.dialogRef.close(false);
  }

  getDate(unixDate) {
    return new DateService().dateWithTime(unixDate);
  }

  getCategory(id) {
    return this.alarmService.getAlarmCategory(id);
  }

  generateMarkers(poles) {
    this.markers = this.markerService.generatePoleMarker(poles);
    this.mainMarker = this.markers.find((marker) => marker.name === this.alarm.hardware);
    if (this.mainMarker != null) {
      this.mainMarker.icon = this.markerService.blueIcon;
      this.markers.splice(this.markers.findIndex((item) => item.name === this.alarm.hardware), 1);
    } else {
      this.mainMarker = this.markers[0];
      this.markers.splice(0, 1);
    }
  }

  fetchPoles() {
    this.alarmService.getPoles(this.project.id, this.alarm.stationId).subscribe(
      (poles) => {
        this.generateMarkers(poles);
        this.toastService.showEmptyResponse('No poles to show', poles.length);
      },
      (error) => {
        this.toastService.showToast('error', 'Error!', 'Failed to fetch poles');
      },
    );
  }
}
