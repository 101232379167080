<app-page-title icon="fas fa-file" title="Projects"></app-page-title>
<div id="header">
  <div class="row" style="height: 20px;"></div>
    <div class="container">
    <div class="row">
        <app-loading [check]="projectList" color="primary"></app-loading>

        <div *ngFor="let project of projectList" class="col-md-6" >
        <app-card  [heading]="project.projectName" [routerLink]="['/project/home', project?.projectId]">
            <div card-body>
                <div class="details">
                <div class="detail green" matTooltip="TST Logo"><img src="/assets/images/marker/logo_1.png"/></div>
                </div>
            </div>
        </app-card>
        </div>

        <app-no-result class="no-result" [list]="projectList"></app-no-result>
    </div>
    </div>
</div>