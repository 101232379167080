import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';
import { SensorInfoComponent } from '../sensor-info/sensor-info.component';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.scss'],
})

export class SensorListComponent implements OnInit {
  @Input() heading: string;
  @Input() type: string;
  @Input() data: any;
  panelOpenState: boolean;
  image = '';


  constructor(private monitoringService: MonitoringService, private dialog: MatDialog) {
  }

  ngOnInit() {
    let defaultType = this.type;
    if (this.data.length > 0) {
      defaultType = this.data[0].sensorType;
    }

    this.image = this.monitoringService.getIcon(defaultType);
  }

  onSensorClicked(sensor) {
    const dialogRef = this.dialog.open(
      SensorInfoComponent,
      {
        width: '50rem',
        data:
        {
          sensor,
        },
      });
  }
}
