import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-text',
  templateUrl: './toggle-text.component.html',
  styleUrls: ['./toggle-text.component.scss'],
})

export class ToggleTextComponent {
  @Input() text: string;
  @Input() error: boolean;

  constructor() {
  }
}
