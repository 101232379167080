<div class="lighting-list-container">
  <app-sub-section [heading]="heading" [length]="data?.length" [timestamp]="timestamp" (outputRefresh)="refresh()"></app-sub-section>
  <div class="row">
    <div *ngFor="let camera  of data" class="col-md-4">
      <app-camera [camera]="camera"></app-camera>
    </div>

    <app-no-result class="no-result" [list]="data"></app-no-result>
  </div>
</div>
