import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
})

export class CookieComponent implements OnInit {
  accepted = false;

  constructor() {
  }

  ngOnInit() {
    this.accepted = localStorage.getItem('cookiePolicyAccepted') === 'true' ? true : false;
  }

  dismiss() {
    this.accepted = true;
    this.setPolicy();
  }

  private setPolicy() {
    localStorage.setItem('cookiePolicyAccepted', this.accepted.toString());
  }
}
