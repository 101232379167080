<app-card heading="Select a device">
  <div card-body>
    <app-loading [check]="ready" color="primary"></app-loading>

    <mat-form-field appearance="fill" *ngIf="stations && stations.length > 0">
      <mat-select>
        <mat-option *ngFor="let device  of stations" (click)="setStation(device)" [value]="device.name">{{device.name}}</mat-option>
      </mat-select>
      <mat-label><b> Select</b> <i> station</i></mat-label>
    </mat-form-field>


    <mat-form-field appearance="fill" *ngIf="devices && devices.length > 0">
      <mat-select>
        <mat-option *ngFor="let device  of devices" (click)="setDevice(device)"  [value]="device.name">{{device.name}} <span *ngIf="device.stationName">- ({{device.stationName}})</span> </mat-option>
      </mat-select>
      <mat-label><b> Select</b> <i> device</i></mat-label>
    </mat-form-field>
  </div>
</app-card>
