import { Component, Inject } from '@angular/core';

import { APP_CONFIG } from 'src/app/configs/app.config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent {
  date = (new Date()).getFullYear();
  appConfig: any;

  constructor(@Inject(APP_CONFIG) appConfig: any) {
    this.appConfig = appConfig;
  }
}
