declare const require;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const bowser = require('bowser');

export const scrollToElement = (element) => {
  if (element) {
    const distance = window.pageYOffset - Math.abs(element.getBoundingClientRect().y);

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.getBoundingClientRect().top + window.scrollY - 150,
    });

    setTimeout(() => {
      element.focus();
      element.blur(); // Trigger error messages
      element.focus();
    }, distance);
  }
};

export const isBrowserValid = () => {
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.satisfies({
    windows: {
      'internet explorer': '>10',
    },
    macos: {
      safari: '>10.1',
    },
    chrome: '>20.1.1432',
    firefox: '>31',
    opera: '>22',
  });
};
