<app-card [heading]="camera.model">
  <div card-body>
    <div class="row">
      <div class="col-md-10">
        <div class="vertical-container">
          <app-key-value key="Protocol" [value]="camera.protocol"></app-key-value>
          <app-key-value key="Code" [value]="camera.code"></app-key-value>
        </div>
        <app-toggle-text *ngIf="camera.remark" text="Remark: {{camera.remark}}" error="true"></app-toggle-text>
      </div>

      <div class="col-md-2">
        <span *ngIf="camera.isDefault" class="default" matTooltip="Default camera">
          <i class="fas fa-check"></i>
        </span>
      </div>
    </div>
  </div>
</app-card>
