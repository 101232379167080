import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: [ './video-player.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerComponent implements OnInit {
  video: any;

  constructor() {
    this.video =
      {
        name: 'Sample video',
        description: 'This is a sample video',
        src: 'http://static.videogular.com/assets/videos/videogular.mp4',
        type: 'video/mp4'
      };
    }

  ngOnInit() {
  }
}
