import { Component, Input, OnInit } from '@angular/core';

import { MonitoringService } from 'src/app/components/project/components/monitoring/monitoring.service';
import { IMapMarker } from 'src/app/interfaces/IMapMarker';

@Component({
  selector: 'app-gmap-info',
  templateUrl: 'gmap-info.component.html',
  styleUrls: ['gmap-info.component.scss'],
})

export class GMapInfoComponent implements OnInit {
  @Input() marker: IMapMarker;
  @Input() showInfo = true;

  icon: string;
  constructor(private monitoringService: MonitoringService) {
  }

  ngOnInit() {
    this.icon = this.monitoringService.getIcon(this.marker.item['sensorType']);
    console.log(this.marker.name);
  }
}
